import type { AccountsMetadata } from '@/shared/graphql/scalars';
import type { BreakoutsPlatformSettings } from '@/shared/graphql/scalars';
import type { ChannelEvent } from '@/shared/graphql/scalars';
import type { ChannelUserStatusPlatformStates } from '@/shared/graphql/scalars';
import type { ChannelsMute } from '@/shared/graphql/scalars';
import type { ClipboardPrompt } from '@/shared/graphql/scalars';
import type { ClubsDisplaySettings } from '@/shared/graphql/scalars';
import type { ClubsOnboarding } from '@/shared/graphql/scalars';
import type { CompetitionsMeta } from '@/shared/graphql/scalars';
import type { CompetitionsPrizeMeta } from '@/shared/graphql/scalars';
import type { FeedType } from '@/shared/graphql/scalars';
import type { FeedbackSource } from '@/shared/graphql/scalars';
import type { NotificationType } from '@/shared/graphql/scalars';
import type { OtpsData } from '@/shared/graphql/scalars';
import type { ProjectsImages } from '@/shared/graphql/scalars';
import type { CompetitionsPrize } from '@/shared/graphql/scalars';
import type { RoomsHostUserIds } from '@/shared/graphql/scalars';
import type { SchedulesSchedule } from '@/shared/graphql/scalars';
import type { UserAction } from '@/shared/graphql/scalars';
import type { UserPresencePlatform } from '@/shared/graphql/scalars';
import type { UsersMeta } from '@/shared/graphql/scalars';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  accounts_metadata: { input: AccountsMetadata; output: AccountsMetadata; }
  bigint: { input: number; output: number; }
  breakouts_platform_settings: { input: BreakoutsPlatformSettings; output: BreakoutsPlatformSettings; }
  channel_event: { input: ChannelEvent; output: ChannelEvent; }
  channel_user_status_platform_states: { input: ChannelUserStatusPlatformStates; output: ChannelUserStatusPlatformStates; }
  channels_mute: { input: ChannelsMute; output: ChannelsMute; }
  clipboard_prompt: { input: ClipboardPrompt; output: ClipboardPrompt; }
  clubs_display_settings: { input: ClubsDisplaySettings; output: ClubsDisplaySettings; }
  clubs_onboarding: { input: ClubsOnboarding; output: ClubsOnboarding; }
  competitions_meta: { input: CompetitionsMeta; output: CompetitionsMeta; }
  competitions_prize_meta: { input: CompetitionsPrizeMeta; output: CompetitionsPrizeMeta; }
  date: { input: string; output: string; }
  feed_type: { input: FeedType; output: FeedType; }
  feedback_source: { input: FeedbackSource; output: FeedbackSource; }
  jsonb: { input: unknown; output: unknown; }
  notification_type: { input: NotificationType; output: NotificationType; }
  numeric: { input: number; output: number; }
  otps_data: { input: OtpsData; output: OtpsData; }
  projects_images: { input: ProjectsImages; output: ProjectsImages; }
  projects_prize: { input: CompetitionsPrize; output: CompetitionsPrize; }
  rooms_host_user_ids: { input: RoomsHostUserIds; output: RoomsHostUserIds; }
  schedules_schedule: { input: SchedulesSchedule; output: SchedulesSchedule; }
  timestamptz: { input: string; output: string; }
  user_action: { input: UserAction; output: UserAction; }
  user_presence_platform: { input: UserPresencePlatform; output: UserPresencePlatform; }
  users_meta: { input: UsersMeta; output: UsersMeta; }
  uuid: { input: string; output: string; }
};

export type AcceptTeamInviteOutput = {
  readonly __typename?: 'AcceptTeamInviteOutput';
  readonly accepted: Scalars['Boolean']['output'];
};

export type BanChannelUserOutput = {
  readonly __typename?: 'BanChannelUserOutput';
  readonly banned: Scalars['Boolean']['output'];
};

export type BookOnboardingOutput = {
  readonly __typename?: 'BookOnboardingOutput';
  readonly roomId?: Maybe<Scalars['uuid']['output']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _gt?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _gte?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['Boolean']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lte?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['Boolean']['input']>>;
};

export type FeedItem = {
  readonly __typename?: 'FeedItem';
  readonly association_id: Scalars['String']['output'];
  readonly author: Scalars['String']['output'];
  readonly comments: ReadonlyArray<FeedItemComments>;
  readonly comments_aggregate: FeedItemAggregate;
  readonly created_at: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly payload: Scalars['jsonb']['output'];
  readonly type: Scalars['feed_type']['output'];
  readonly user_vote: ReadonlyArray<FeedItemUserVote>;
  readonly votes_aggregate: FeedItemAggregate;
};

export type FeedItemAggregate = {
  readonly __typename?: 'FeedItemAggregate';
  readonly aggregate?: Maybe<FeedItemAggregateCount>;
};

export type FeedItemAggregateCount = {
  readonly __typename?: 'FeedItemAggregateCount';
  readonly count: Scalars['Int']['output'];
};

export type FeedItemCommentUser = {
  readonly __typename?: 'FeedItemCommentUser';
  readonly first_name?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly last_name?: Maybe<Scalars['String']['output']>;
  readonly meta: Scalars['users_meta']['output'];
  readonly photo: Scalars['String']['output'];
  readonly username?: Maybe<Scalars['String']['output']>;
};

export type FeedItemComments = {
  readonly __typename?: 'FeedItemComments';
  readonly content: Scalars['String']['output'];
  readonly created_at: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly user: FeedItemCommentUser;
  readonly user_vote: ReadonlyArray<FeedItemUserVote>;
  readonly votes_aggregate: FeedItemAggregate;
};

export type FeedItemUserVote = {
  readonly __typename?: 'FeedItemUserVote';
  readonly id: Scalars['String']['output'];
};

export type GetAllAvatarsOutput = {
  readonly __typename?: 'GetAllAvatarsOutput';
  readonly avatars?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['Int']['input']>;
  readonly _gt?: InputMaybe<Scalars['Int']['input']>;
  readonly _gte?: InputMaybe<Scalars['Int']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['Int']['input']>;
  readonly _lte?: InputMaybe<Scalars['Int']['input']>;
  readonly _neq?: InputMaybe<Scalars['Int']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
};

export type JoinBreakoutOutput = {
  readonly __typename?: 'JoinBreakoutOutput';
  readonly callAccessToken?: Maybe<Scalars['String']['output']>;
};

export type JoinCompetitionOutput = {
  readonly __typename?: 'JoinCompetitionOutput';
  readonly channel_id: Scalars['String']['output'];
  readonly match_username?: Maybe<Scalars['String']['output']>;
  readonly matched: Scalars['Boolean']['output'];
};

export type LeaveCompetitionOutput = {
  readonly __typename?: 'LeaveCompetitionOutput';
  readonly affected_rows: Scalars['Int']['output'];
};

export type PublishToMinecraftOutput = {
  readonly __typename?: 'PublishToMinecraftOutput';
  readonly success: Scalars['Boolean']['output'];
};

export type SendFriendRequestOutput = {
  readonly __typename?: 'SendFriendRequestOutput';
  readonly friendship_id: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  readonly _contained_in?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** does the array contain the given value */
  readonly _contains?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _eq?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _gt?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _gte?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _in?: InputMaybe<ReadonlyArray<ReadonlyArray<Scalars['String']['input']>>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _lte?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _neq?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _nin?: InputMaybe<ReadonlyArray<ReadonlyArray<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['String']['input']>;
  readonly _gt?: InputMaybe<Scalars['String']['input']>;
  readonly _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  readonly _ilike?: InputMaybe<Scalars['String']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  readonly _iregex?: InputMaybe<Scalars['String']['input']>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  readonly _like?: InputMaybe<Scalars['String']['input']>;
  readonly _lt?: InputMaybe<Scalars['String']['input']>;
  readonly _lte?: InputMaybe<Scalars['String']['input']>;
  readonly _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  readonly _nilike?: InputMaybe<Scalars['String']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  readonly _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  readonly _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  readonly _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  readonly _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  readonly _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  readonly _similar?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitChatMessageOutput = {
  readonly __typename?: 'SubmitChatMessageOutput';
  readonly createdAt?: Maybe<Scalars['String']['output']>;
  readonly error?: Maybe<Scalars['String']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
};

export type ToggleRoomHostIdOutput = {
  readonly __typename?: 'ToggleRoomHostIdOutput';
  readonly room_host_user_ids?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly success: Scalars['Boolean']['output'];
};

export type Transaction = {
  readonly kind?: InputMaybe<Transaction_Kind>;
};

export type UpdateChannelMuteEnabledInput = {
  readonly channelId?: InputMaybe<Scalars['uuid']['input']>;
  readonly cooldown?: InputMaybe<Scalars['Int']['input']>;
  readonly muteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateChannelMuteEnabledOutput = {
  readonly __typename?: 'UpdateChannelMuteEnabledOutput';
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly mute?: Maybe<Scalars['jsonb']['output']>;
  readonly success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateChannelUserPermissionsOutput = {
  readonly __typename?: 'UpdateChannelUserPermissionsOutput';
  readonly updated: Scalars['Boolean']['output'];
};

export type UpdateChannelsUserForceMutedOutput = {
  readonly __typename?: 'UpdateChannelsUserForceMutedOutput';
  readonly success: Scalars['Boolean']['output'];
};

export type UpdateHandRaisedOutput = {
  readonly __typename?: 'UpdateHandRaisedOutput';
  readonly updated: Scalars['Boolean']['output'];
};

export type UpdatePasswordOutput = {
  readonly __typename?: 'UpdatePasswordOutput';
  readonly id: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
};

export type UpdateUserClubPreferencesOutput = {
  readonly __typename?: 'UpdateUserClubPreferencesOutput';
  readonly updated: Scalars['Boolean']['output'];
};

export type UpsertClubOutput = {
  readonly __typename?: 'UpsertClubOutput';
  readonly errors?: Maybe<UpsertClubOutputErrors>;
  readonly success: Scalars['Boolean']['output'];
};

export type UpsertClubOutputErrors = {
  readonly __typename?: 'UpsertClubOutputErrors';
  readonly backgroundImage?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly navigationColor?: Maybe<Scalars['String']['output']>;
  readonly navigationIcon?: Maybe<Scalars['String']['output']>;
  readonly navigationImage?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
};

export type UpsertRoomErrors = {
  readonly __typename?: 'UpsertRoomErrors';
  readonly clipboardPrompt?: Maybe<Scalars['String']['output']>;
  readonly clubId?: Maybe<Scalars['String']['output']>;
  readonly feedbackQuestion?: Maybe<Scalars['String']['output']>;
  readonly iconUrl?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly lockTime?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly unlockTime?: Maybe<Scalars['String']['output']>;
};

export type UpsertRoomOutput = {
  readonly __typename?: 'UpsertRoomOutput';
  readonly errors?: Maybe<UpsertRoomErrors>;
  readonly message: Scalars['String']['output'];
};

export type UpsertScheduleOutput = {
  readonly __typename?: 'UpsertScheduleOutput';
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly success: Scalars['Boolean']['output'];
};

/** columns and relationships of "accounts" */
export type Accounts = {
  readonly __typename?: 'accounts';
  readonly avatar_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly club: Clubs;
  readonly club_id: Scalars['uuid']['output'];
  readonly id: Scalars['String']['output'];
  readonly metadata: Scalars['accounts_metadata']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly username: Scalars['String']['output'];
};


/** columns and relationships of "accounts" */
export type AccountsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "accounts" */
export type Accounts_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Accounts_Max_Order_By>;
  readonly min?: InputMaybe<Accounts_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Accounts_Bool_Exp>>;
  readonly _not?: InputMaybe<Accounts_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Accounts_Bool_Exp>>;
  readonly avatar_url?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly username?: InputMaybe<String_Comparison_Exp>;
};

export type Accounts_Insert_Input = {
  readonly metadata?: InputMaybe<Scalars['accounts_metadata']['input']>;
};

/** order by max() on columns of table "accounts" */
export type Accounts_Max_Order_By = {
  readonly avatar_url?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "accounts" */
export type Accounts_Min_Order_By = {
  readonly avatar_url?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  readonly __typename?: 'accounts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Accounts>;
};

/** Ordering options when selecting data from "accounts". */
export type Accounts_Order_By = {
  readonly avatar_url?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly metadata?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
};

/** select columns of table "accounts" */
export enum Accounts_Select_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username'
}

export type Accounts_Set_Input = {
  readonly metadata?: InputMaybe<Scalars['accounts_metadata']['input']>;
};

/** Streaming cursor of the table "accounts" */
export type Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accounts_Stream_Cursor_Value_Input = {
  readonly avatar_url?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "avatars" */
export type Avatars = {
  readonly __typename?: 'avatars';
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly url: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "avatars". All fields are combined with a logical 'AND'. */
export type Avatars_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Avatars_Bool_Exp>>;
  readonly _not?: InputMaybe<Avatars_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Avatars_Bool_Exp>>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly url?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "avatars". */
export type Avatars_Order_By = {
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly url?: InputMaybe<Order_By>;
};

/** select columns of table "avatars" */
export enum Avatars_Select_Column {
  /** column name */
  Url = 'url'
}

/** Streaming cursor of the table "avatars" */
export type Avatars_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Avatars_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Avatars_Stream_Cursor_Value_Input = {
  readonly url?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "badges" */
export type Badges = {
  readonly __typename?: 'badges';
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly image: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly priority: Scalars['Int']['output'];
  readonly slug: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
};

/** order by aggregate values of table "badges" */
export type Badges_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Badges_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Badges_Max_Order_By>;
  readonly min?: InputMaybe<Badges_Min_Order_By>;
  readonly stddev?: InputMaybe<Badges_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Badges_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Badges_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Badges_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Badges_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Badges_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Badges_Variance_Order_By>;
};

/** order by avg() on columns of table "badges" */
export type Badges_Avg_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "badges". All fields are combined with a logical 'AND'. */
export type Badges_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Badges_Bool_Exp>>;
  readonly _not?: InputMaybe<Badges_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Badges_Bool_Exp>>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly image?: InputMaybe<String_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly priority?: InputMaybe<Int_Comparison_Exp>;
  readonly slug?: InputMaybe<String_Comparison_Exp>;
  readonly type?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "badges" */
export type Badges_Max_Order_By = {
  readonly image?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly priority?: InputMaybe<Order_By>;
  readonly slug?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "badges" */
export type Badges_Min_Order_By = {
  readonly image?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly priority?: InputMaybe<Order_By>;
  readonly slug?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "badges". */
export type Badges_Order_By = {
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly priority?: InputMaybe<Order_By>;
  readonly slug?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** select columns of table "badges" */
export enum Badges_Select_Column {
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type'
}

/** order by stddev() on columns of table "badges" */
export type Badges_Stddev_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "badges" */
export type Badges_Stddev_Pop_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "badges" */
export type Badges_Stddev_Samp_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "badges" */
export type Badges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Badges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Badges_Stream_Cursor_Value_Input = {
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly priority?: InputMaybe<Scalars['Int']['input']>;
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "badges" */
export type Badges_Sum_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "badges" */
export type Badges_Var_Pop_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "badges" */
export type Badges_Var_Samp_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "badges" */
export type Badges_Variance_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['bigint']['input']>;
  readonly _gt?: InputMaybe<Scalars['bigint']['input']>;
  readonly _gte?: InputMaybe<Scalars['bigint']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['bigint']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['bigint']['input']>;
  readonly _lte?: InputMaybe<Scalars['bigint']['input']>;
  readonly _neq?: InputMaybe<Scalars['bigint']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['bigint']['input']>>;
};

export enum Breakout_Type {
  Private = 'private',
  Public = 'public'
}

export type Breakout_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Breakout_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Breakout_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Breakout_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Breakout_Type>>;
};

/** columns and relationships of "breakouts" */
export type Breakouts = {
  readonly __typename?: 'breakouts';
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly channel?: Maybe<Channels>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  readonly channels_users?: Maybe<Channels_User>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly delete_if_inactive?: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly name: Scalars['String']['output'];
  readonly platform_settings?: Maybe<Scalars['breakouts_platform_settings']['output']>;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly room_id: Scalars['uuid']['output'];
  readonly sort_order: Scalars['String']['output'];
  readonly type: Breakout_Type;
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly valid_until?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "breakouts" */
export type BreakoutsPlatform_SettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "breakouts" */
export type Breakouts_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Breakouts_Max_Order_By>;
  readonly min?: InputMaybe<Breakouts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Breakouts_Append_Input = {
  readonly platform_settings?: InputMaybe<Scalars['breakouts_platform_settings']['input']>;
};

/** input type for inserting array relation for remote table "breakouts" */
export type Breakouts_Arr_Rel_Insert_Input = {
  readonly data?: InputMaybe<ReadonlyArray<Breakouts_Insert_Input>>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Breakouts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "breakouts". All fields are combined with a logical 'AND'. */
export type Breakouts_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Breakouts_Bool_Exp>>;
  readonly _not?: InputMaybe<Breakouts_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Breakouts_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly channels_users?: InputMaybe<Channels_User_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly delete_if_inactive?: InputMaybe<Boolean_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly platform_settings?: InputMaybe<Breakouts_Platform_Settings_Bool_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly room_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly sort_order?: InputMaybe<String_Comparison_Exp>;
  readonly type?: InputMaybe<Breakout_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly valid_until?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "breakouts" */
export enum Breakouts_Constraint {
  /** unique or primary key constraint on columns "id" */
  BreakoutsPkey = 'breakouts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Breakouts_Delete_At_Path_Input = {
  readonly platform_settings?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Breakouts_Delete_Elem_Input = {
  readonly platform_settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Breakouts_Delete_Key_Input = {
  readonly platform_settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "breakouts" */
export type Breakouts_Insert_Input = {
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly channels_users?: InputMaybe<Channels_User_Obj_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly delete_if_inactive?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly platform_settings?: InputMaybe<Scalars['breakouts_platform_settings']['input']>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Breakout_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by max() on columns of table "breakouts" */
export type Breakouts_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly valid_until?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "breakouts" */
export type Breakouts_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly valid_until?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "breakouts" */
export type Breakouts_Mutation_Response = {
  readonly __typename?: 'breakouts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  readonly returning?: Maybe<ReadonlyArray<Breakouts>>;
};

/** input type for inserting object relation for remote table "breakouts" */
export type Breakouts_Obj_Rel_Insert_Input = {
  readonly data?: InputMaybe<Breakouts_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Breakouts_On_Conflict>;
};

/** on_conflict condition type for table "breakouts" */
export type Breakouts_On_Conflict = {
  readonly constraint?: InputMaybe<Breakouts_Constraint>;
  readonly update_columns?: InputMaybe<ReadonlyArray<Breakouts_Update_Column>>;
  readonly where?: InputMaybe<Breakouts_Bool_Exp>;
};

/** Ordering options when selecting data from "breakouts". */
export type Breakouts_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly channels_users?: InputMaybe<Channels_User_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly delete_if_inactive?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly platform_settings?: InputMaybe<Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly valid_until?: InputMaybe<Order_By>;
};

/** primary key columns input for table: breakouts */
export type Breakouts_Pk_Columns_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
};

export type Breakouts_Platform_Settings_Bool_Exp = {
  readonly _eq?: InputMaybe<Scalars['breakouts_platform_settings']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['breakouts_platform_settings']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['breakouts_platform_settings']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['breakouts_platform_settings']['input']>>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Breakouts_Prepend_Input = {
  readonly platform_settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "breakouts" */
export enum Breakouts_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeleteIfInactive = 'delete_if_inactive',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlatformSettings = 'platform_settings',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidUntil = 'valid_until'
}

/** input type for updating data in table "breakouts" */
export type Breakouts_Set_Input = {
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly delete_if_inactive?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly platform_settings?: InputMaybe<Scalars['breakouts_platform_settings']['input']>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Breakout_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "breakouts" */
export type Breakouts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Breakouts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Breakouts_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly delete_if_inactive?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly platform_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "breakouts" */
export enum Breakouts_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeleteIfInactive = 'delete_if_inactive',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlatformSettings = 'platform_settings',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidUntil = 'valid_until'
}

export type Breakouts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Breakouts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Breakouts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Breakouts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Breakouts_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Breakouts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Breakouts_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where?: InputMaybe<Breakouts_Bool_Exp>;
};

export enum Channel_Type {
  Breakout = 'breakout',
  Competition = 'competition',
  LiveEvent = 'live_event',
  OrientationRoom = 'orientation_room',
  Room = 'room',
  Team = 'team'
}

export type Channel_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Channel_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Channel_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Channel_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Channel_Type>>;
};

/** columns and relationships of "channels" */
export type Channels = {
  readonly __typename?: 'channels';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly breakout?: Maybe<Breakouts>;
  /** An array relationship */
  readonly channels_users: ReadonlyArray<Channels_User>;
  /** An aggregate relationship */
  readonly channels_users_aggregate: Channels_User_Aggregate;
  /** An array relationship */
  readonly chats: ReadonlyArray<Chats>;
  /** An aggregate relationship */
  readonly chats_aggregate: Chats_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "channels_division" */
  readonly division: User_Division;
  readonly frozen: Scalars['Boolean']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly matchmaking: Scalars['Boolean']['output'];
  readonly mute: Scalars['channels_mute']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly project?: Maybe<Projects>;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly sticky: Scalars['Boolean']['output'];
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly type: Channel_Type;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly user_invites: ReadonlyArray<User_Invites>;
  readonly user_status_platform_states?: Maybe<Scalars['channel_user_status_platform_states']['output']>;
};


/** columns and relationships of "channels" */
export type ChannelsChannels_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_User_Order_By>>;
  where?: InputMaybe<Channels_User_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChannels_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_User_Order_By>>;
  where?: InputMaybe<Channels_User_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChats_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsMuteArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "channels" */
export type ChannelsUser_InvitesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsUser_Status_Platform_StatesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "channels" */
export type Channels_Aggregate = {
  readonly __typename?: 'channels_aggregate';
  readonly aggregate?: Maybe<Channels_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Channels>;
};

/** aggregate fields of "channels" */
export type Channels_Aggregate_Fields = {
  readonly __typename?: 'channels_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Channels_Max_Fields>;
  readonly min?: Maybe<Channels_Min_Fields>;
};


/** aggregate fields of "channels" */
export type Channels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
export type Channels_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Channels_Bool_Exp>>;
  readonly _not?: InputMaybe<Channels_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Channels_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly breakout?: InputMaybe<Breakouts_Bool_Exp>;
  readonly channels_users?: InputMaybe<Channels_User_Bool_Exp>;
  readonly channels_users_aggregate?: InputMaybe<Channels_User_Aggregate_Bool_Exp>;
  readonly chats?: InputMaybe<Chats_Bool_Exp>;
  readonly chats_aggregate?: InputMaybe<Chats_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly division?: InputMaybe<User_Division_Bool_Exp>;
  readonly frozen?: InputMaybe<Boolean_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly matchmaking?: InputMaybe<Boolean_Comparison_Exp>;
  readonly mute?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly sticky?: InputMaybe<Boolean_Comparison_Exp>;
  readonly tagline?: InputMaybe<String_Comparison_Exp>;
  readonly type?: InputMaybe<Channel_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_invites?: InputMaybe<User_Invites_Bool_Exp>;
  readonly user_status_platform_states?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** columns and relationships of "channels_events" */
export type Channels_Events = {
  readonly __typename?: 'channels_events';
  /** An object relationship */
  readonly channel: Channels;
  readonly channel_id: Scalars['uuid']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly data: Scalars['channel_event']['output'];
  readonly fallback_message?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['uuid']['output'];
};


/** columns and relationships of "channels_events" */
export type Channels_EventsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "channels_events". All fields are combined with a logical 'AND'. */
export type Channels_Events_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Channels_Events_Bool_Exp>>;
  readonly _not?: InputMaybe<Channels_Events_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Channels_Events_Bool_Exp>>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly data?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly fallback_message?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "channels_events" */
export enum Channels_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChannelsEventsPkey = 'channels_events_pkey'
}

/** input type for inserting data into table "channels_events" */
export type Channels_Events_Insert_Input = {
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly data: Scalars['channel_event']['input'];
  readonly fallback_message?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "channels_events" */
export type Channels_Events_Mutation_Response = {
  readonly __typename?: 'channels_events_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Channels_Events>;
};

/** on_conflict condition type for table "channels_events" */
export type Channels_Events_On_Conflict = {
  readonly constraint: Channels_Events_Constraint;
  readonly update_columns?: ReadonlyArray<Channels_Events_Update_Column>;
  readonly where?: InputMaybe<Channels_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "channels_events". */
export type Channels_Events_Order_By = {
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly data?: InputMaybe<Order_By>;
  readonly fallback_message?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
};

/** select columns of table "channels_events" */
export enum Channels_Events_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  FallbackMessage = 'fallback_message',
  /** column name */
  Id = 'id'
}

export type Channels_Events_Set_Input = {
  readonly data: Scalars['channel_event']['input'];
};

/** Streaming cursor of the table "channels_events" */
export type Channels_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Channels_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Events_Stream_Cursor_Value_Input = {
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
  readonly fallback_message?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "channels_events" (current role has no relevant permissions) */
export enum Channels_Events_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

export type Channels_Insert_Input = {
  readonly division?: InputMaybe<User_Division>;
  readonly mute?: InputMaybe<Scalars['channels_mute']['input']>;
  readonly type?: InputMaybe<Channel_Type>;
};

/** aggregate max on columns */
export type Channels_Max_Fields = {
  readonly __typename?: 'channels_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "channels_division" */
  readonly division?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Channels_Min_Fields = {
  readonly __typename?: 'channels_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "channels_division" */
  readonly division?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "channels" */
export type Channels_Mutation_Response = {
  readonly __typename?: 'channels_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Channels>;
};

/** Ordering options when selecting data from "channels". */
export type Channels_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly breakout?: InputMaybe<Breakouts_Order_By>;
  readonly channels_users_aggregate?: InputMaybe<Channels_User_Aggregate_Order_By>;
  readonly chats_aggregate?: InputMaybe<Chats_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly division?: InputMaybe<Order_By>;
  readonly frozen?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly matchmaking?: InputMaybe<Order_By>;
  readonly mute?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly sticky?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_invites_aggregate?: InputMaybe<User_Invites_Aggregate_Order_By>;
  readonly user_status_platform_states?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channels */
export type Channels_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "channels" */
export enum Channels_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Frozen = 'frozen',
  /** column name */
  Id = 'id',
  /** column name */
  Matchmaking = 'matchmaking',
  /** column name */
  Mute = 'mute',
  /** column name */
  Name = 'name',
  /** column name */
  Sticky = 'sticky',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserStatusPlatformStates = 'user_status_platform_states'
}

/** input type for updating data in table "channels" */
export type Channels_Set_Input = {
  readonly division?: InputMaybe<User_Division>;
  readonly matchmaking?: InputMaybe<Scalars['Boolean']['input']>;
  readonly mute?: InputMaybe<Scalars['channels_mute']['input']>;
  readonly type?: InputMaybe<Channel_Type>;
};

/** Streaming cursor of the table "channels" */
export type Channels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Channels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly frozen?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly matchmaking?: InputMaybe<Scalars['Boolean']['input']>;
  readonly mute?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly sticky?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_status_platform_states?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Channels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Channels_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Channels_Bool_Exp;
};

/** columns and relationships of "channels_user" */
export type Channels_User = {
  readonly __typename?: 'channels_user';
  /** An object relationship */
  readonly active_channel?: Maybe<Channels>;
  readonly active_channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly active_channel_updated_at: Scalars['timestamptz']['output'];
  readonly banned: Scalars['Boolean']['output'];
  /** An object relationship */
  readonly breakout?: Maybe<Breakouts>;
  readonly connection: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly force_muted: Scalars['Boolean']['output'];
  readonly hand_raised: Scalars['Boolean']['output'];
  readonly hand_raised_updated_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly microphone_permitted?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  readonly public_user?: Maybe<Public_Users>;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly screen_share_permitted?: Maybe<Scalars['Boolean']['output']>;
  readonly speaking: Scalars['Boolean']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  readonly volume: Scalars['Int']['output'];
  readonly web_cam_permitted?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "channels_user" */
export type Channels_User_Aggregate = {
  readonly __typename?: 'channels_user_aggregate';
  readonly aggregate?: Maybe<Channels_User_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Channels_User>;
};

export type Channels_User_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Channels_User_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Channels_User_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Channels_User_Aggregate_Bool_Exp_Count>;
};

export type Channels_User_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Channels_User_Select_Column_Channels_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Channels_User_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Channels_User_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Channels_User_Select_Column_Channels_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Channels_User_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Channels_User_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Channels_User_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Channels_User_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channels_user" */
export type Channels_User_Aggregate_Fields = {
  readonly __typename?: 'channels_user_aggregate_fields';
  readonly avg?: Maybe<Channels_User_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Channels_User_Max_Fields>;
  readonly min?: Maybe<Channels_User_Min_Fields>;
  readonly stddev?: Maybe<Channels_User_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Channels_User_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Channels_User_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Channels_User_Sum_Fields>;
  readonly var_pop?: Maybe<Channels_User_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Channels_User_Var_Samp_Fields>;
  readonly variance?: Maybe<Channels_User_Variance_Fields>;
};


/** aggregate fields of "channels_user" */
export type Channels_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Channels_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "channels_user" */
export type Channels_User_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Channels_User_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Channels_User_Max_Order_By>;
  readonly min?: InputMaybe<Channels_User_Min_Order_By>;
  readonly stddev?: InputMaybe<Channels_User_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Channels_User_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Channels_User_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Channels_User_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Channels_User_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Channels_User_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Channels_User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "channels_user" */
export type Channels_User_Arr_Rel_Insert_Input = {
  readonly data?: InputMaybe<ReadonlyArray<Channels_User_Insert_Input>>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Channels_User_On_Conflict>;
};

/** aggregate avg on columns */
export type Channels_User_Avg_Fields = {
  readonly __typename?: 'channels_user_avg_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "channels_user" */
export type Channels_User_Avg_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "channels_user". All fields are combined with a logical 'AND'. */
export type Channels_User_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Channels_User_Bool_Exp>>;
  readonly _not?: InputMaybe<Channels_User_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Channels_User_Bool_Exp>>;
  readonly active_channel?: InputMaybe<Channels_Bool_Exp>;
  readonly active_channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly active_channel_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly banned?: InputMaybe<Boolean_Comparison_Exp>;
  readonly breakout?: InputMaybe<Breakouts_Bool_Exp>;
  readonly connection?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly force_muted?: InputMaybe<Boolean_Comparison_Exp>;
  readonly hand_raised?: InputMaybe<Boolean_Comparison_Exp>;
  readonly hand_raised_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly microphone_permitted?: InputMaybe<Boolean_Comparison_Exp>;
  readonly public_user?: InputMaybe<Public_Users_Bool_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly screen_share_permitted?: InputMaybe<Boolean_Comparison_Exp>;
  readonly speaking?: InputMaybe<Boolean_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly volume?: InputMaybe<Int_Comparison_Exp>;
  readonly web_cam_permitted?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "channels_user" */
export enum Channels_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChannelsUserPkey = 'channels_user_pkey',
  /** unique or primary key constraint on columns "user_id" */
  ChannelsUserUserIdKey = 'channels_user_user_id_key'
}

/** input type for incrementing numeric columns in table "channels_user" */
export type Channels_User_Inc_Input = {
  readonly volume?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "channels_user" */
export type Channels_User_Insert_Input = {
  readonly breakout?: InputMaybe<Breakouts_Obj_Rel_Insert_Input>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Channels_User_Max_Fields = {
  readonly __typename?: 'channels_user_max_fields';
  readonly active_channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly active_channel_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly connection?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly hand_raised_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly volume?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "channels_user" */
export type Channels_User_Max_Order_By = {
  readonly active_channel_id?: InputMaybe<Order_By>;
  readonly active_channel_updated_at?: InputMaybe<Order_By>;
  readonly connection?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly hand_raised_updated_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly volume?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channels_User_Min_Fields = {
  readonly __typename?: 'channels_user_min_fields';
  readonly active_channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly active_channel_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly connection?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly hand_raised_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly volume?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "channels_user" */
export type Channels_User_Min_Order_By = {
  readonly active_channel_id?: InputMaybe<Order_By>;
  readonly active_channel_updated_at?: InputMaybe<Order_By>;
  readonly connection?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly hand_raised_updated_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly volume?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channels_user" */
export type Channels_User_Mutation_Response = {
  readonly __typename?: 'channels_user_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Channels_User>;
};

/** input type for inserting object relation for remote table "channels_user" */
export type Channels_User_Obj_Rel_Insert_Input = {
  readonly data?: InputMaybe<Channels_User_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Channels_User_On_Conflict>;
};

/** on_conflict condition type for table "channels_user" */
export type Channels_User_On_Conflict = {
  readonly constraint: Channels_User_Constraint;
  readonly update_columns?: ReadonlyArray<Channels_User_Update_Column>;
  readonly where?: InputMaybe<Channels_User_Bool_Exp>;
};

/** Ordering options when selecting data from "channels_user". */
export type Channels_User_Order_By = {
  readonly active_channel?: InputMaybe<Channels_Order_By>;
  readonly active_channel_id?: InputMaybe<Order_By>;
  readonly active_channel_updated_at?: InputMaybe<Order_By>;
  readonly banned?: InputMaybe<Order_By>;
  readonly breakout?: InputMaybe<Breakouts_Order_By>;
  readonly connection?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly force_muted?: InputMaybe<Order_By>;
  readonly hand_raised?: InputMaybe<Order_By>;
  readonly hand_raised_updated_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly microphone_permitted?: InputMaybe<Order_By>;
  readonly public_user?: InputMaybe<Public_Users_Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly screen_share_permitted?: InputMaybe<Order_By>;
  readonly speaking?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly volume?: InputMaybe<Order_By>;
  readonly web_cam_permitted?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channels_user */
export type Channels_User_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "channels_user" */
export enum Channels_User_Select_Column {
  /** column name */
  ActiveChannelId = 'active_channel_id',
  /** column name */
  ActiveChannelUpdatedAt = 'active_channel_updated_at',
  /** column name */
  Banned = 'banned',
  /** column name */
  Connection = 'connection',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForceMuted = 'force_muted',
  /** column name */
  HandRaised = 'hand_raised',
  /** column name */
  HandRaisedUpdatedAt = 'hand_raised_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  MicrophonePermitted = 'microphone_permitted',
  /** column name */
  ScreenSharePermitted = 'screen_share_permitted',
  /** column name */
  Speaking = 'speaking',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Volume = 'volume',
  /** column name */
  WebCamPermitted = 'web_cam_permitted'
}

/** select "channels_user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "channels_user" */
export enum Channels_User_Select_Column_Channels_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Banned = 'banned',
  /** column name */
  ForceMuted = 'force_muted',
  /** column name */
  HandRaised = 'hand_raised',
  /** column name */
  MicrophonePermitted = 'microphone_permitted',
  /** column name */
  ScreenSharePermitted = 'screen_share_permitted',
  /** column name */
  Speaking = 'speaking',
  /** column name */
  WebCamPermitted = 'web_cam_permitted'
}

/** select "channels_user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "channels_user" */
export enum Channels_User_Select_Column_Channels_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Banned = 'banned',
  /** column name */
  ForceMuted = 'force_muted',
  /** column name */
  HandRaised = 'hand_raised',
  /** column name */
  MicrophonePermitted = 'microphone_permitted',
  /** column name */
  ScreenSharePermitted = 'screen_share_permitted',
  /** column name */
  Speaking = 'speaking',
  /** column name */
  WebCamPermitted = 'web_cam_permitted'
}

/** input type for updating data in table "channels_user" */
export type Channels_User_Set_Input = {
  readonly active_channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly active_channel_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly banned?: InputMaybe<Scalars['Boolean']['input']>;
  readonly connection?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly force_muted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly hand_raised?: InputMaybe<Scalars['Boolean']['input']>;
  readonly hand_raised_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly microphone_permitted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly screen_share_permitted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly speaking?: InputMaybe<Scalars['Boolean']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly volume?: InputMaybe<Scalars['Int']['input']>;
  readonly web_cam_permitted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type Channels_User_Stddev_Fields = {
  readonly __typename?: 'channels_user_stddev_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "channels_user" */
export type Channels_User_Stddev_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Channels_User_Stddev_Pop_Fields = {
  readonly __typename?: 'channels_user_stddev_pop_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "channels_user" */
export type Channels_User_Stddev_Pop_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Channels_User_Stddev_Samp_Fields = {
  readonly __typename?: 'channels_user_stddev_samp_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "channels_user" */
export type Channels_User_Stddev_Samp_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "channels_user" */
export type Channels_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Channels_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_User_Stream_Cursor_Value_Input = {
  readonly active_channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly active_channel_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly banned?: InputMaybe<Scalars['Boolean']['input']>;
  readonly connection?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly force_muted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly hand_raised?: InputMaybe<Scalars['Boolean']['input']>;
  readonly hand_raised_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly microphone_permitted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly screen_share_permitted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly speaking?: InputMaybe<Scalars['Boolean']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly volume?: InputMaybe<Scalars['Int']['input']>;
  readonly web_cam_permitted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Channels_User_Sum_Fields = {
  readonly __typename?: 'channels_user_sum_fields';
  readonly volume?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "channels_user" */
export type Channels_User_Sum_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** update columns of table "channels_user" */
export enum Channels_User_Update_Column {
  /** column name */
  ActiveChannelId = 'active_channel_id',
  /** column name */
  ActiveChannelUpdatedAt = 'active_channel_updated_at',
  /** column name */
  Banned = 'banned',
  /** column name */
  Connection = 'connection',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForceMuted = 'force_muted',
  /** column name */
  HandRaised = 'hand_raised',
  /** column name */
  HandRaisedUpdatedAt = 'hand_raised_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  MicrophonePermitted = 'microphone_permitted',
  /** column name */
  ScreenSharePermitted = 'screen_share_permitted',
  /** column name */
  Speaking = 'speaking',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Volume = 'volume',
  /** column name */
  WebCamPermitted = 'web_cam_permitted'
}

export type Channels_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Channels_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Channels_User_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Channels_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Channels_User_Var_Pop_Fields = {
  readonly __typename?: 'channels_user_var_pop_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "channels_user" */
export type Channels_User_Var_Pop_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Channels_User_Var_Samp_Fields = {
  readonly __typename?: 'channels_user_var_samp_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "channels_user" */
export type Channels_User_Var_Samp_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Channels_User_Variance_Fields = {
  readonly __typename?: 'channels_user_variance_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "channels_user" */
export type Channels_User_Variance_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** columns and relationships of "chats" */
export type Chats = {
  readonly __typename?: 'chats';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly breakout?: Maybe<Breakouts>;
  /** An object relationship */
  readonly channel: Channels;
  readonly channel_id: Scalars['uuid']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly message: Scalars['String']['output'];
  /** An object relationship */
  readonly project?: Maybe<Projects>;
  /** An object relationship */
  readonly public_sender?: Maybe<Public_Users>;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  /** An object relationship */
  readonly sender: Users;
  readonly sender_id: Scalars['String']['output'];
  readonly source: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "chats" */
export type Chats_Aggregate = {
  readonly __typename?: 'chats_aggregate';
  readonly aggregate?: Maybe<Chats_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Chats>;
};

export type Chats_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Chats_Aggregate_Bool_Exp_Count>;
};

export type Chats_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Chats_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chats" */
export type Chats_Aggregate_Fields = {
  readonly __typename?: 'chats_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Chats_Max_Fields>;
  readonly min?: Maybe<Chats_Min_Fields>;
};


/** aggregate fields of "chats" */
export type Chats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chats" */
export type Chats_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Chats_Max_Order_By>;
  readonly min?: InputMaybe<Chats_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "chats". All fields are combined with a logical 'AND'. */
export type Chats_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Chats_Bool_Exp>>;
  readonly _not?: InputMaybe<Chats_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Chats_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly breakout?: InputMaybe<Breakouts_Bool_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly message?: InputMaybe<String_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly public_sender?: InputMaybe<Public_Users_Bool_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly sender?: InputMaybe<Users_Bool_Exp>;
  readonly sender_id?: InputMaybe<String_Comparison_Exp>;
  readonly source?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Chats_Max_Fields = {
  readonly __typename?: 'chats_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly source?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "chats" */
export type Chats_Max_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chats_Min_Fields = {
  readonly __typename?: 'chats_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly source?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "chats" */
export type Chats_Min_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chats" */
export type Chats_Mutation_Response = {
  readonly __typename?: 'chats_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Chats>;
};

/** Ordering options when selecting data from "chats". */
export type Chats_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly breakout?: InputMaybe<Breakouts_Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly public_sender?: InputMaybe<Public_Users_Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly sender?: InputMaybe<Users_Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "chats" */
export enum Chats_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "chats" */
export type Chats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Chats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chats_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly source?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

export enum Club_Onboarding_Requirement {
  Linked = 'linked',
  LinkedAcknowledgement = 'linked_acknowledgement',
  Onboarded = 'onboarded',
  Oriented = 'oriented'
}

export type Club_Onboarding_Requirement_Bool_Exp = {
  readonly _contains: Club_Onboarding_Requirement;
  readonly _eq?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
};

export enum Club_Privacy {
  Hidden = 'hidden',
  Private = 'private',
  Public = 'public'
}

export type Club_Privacy_Bool_Exp = {
  readonly _eq?: InputMaybe<Club_Privacy>;
  readonly _in?: InputMaybe<ReadonlyArray<Club_Privacy>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Club_Privacy>;
  readonly _nin?: InputMaybe<ReadonlyArray<Club_Privacy>>;
};

export enum Club_Status {
  Closed = 'closed',
  Open = 'open'
}

export type Club_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Club_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Club_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Club_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Club_Status>>;
};

/** columns and relationships of "clubs" */
export type Clubs = {
  readonly __typename?: 'clubs';
  /** An object relationship */
  readonly access_feature?: Maybe<Features>;
  readonly access_feature_id?: Maybe<Scalars['uuid']['output']>;
  readonly association_id: Scalars['String']['output'];
  /** An array relationship */
  readonly badges: ReadonlyArray<Badges>;
  readonly clipboard_prompt?: Maybe<Scalars['clipboard_prompt']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly display_settings: Scalars['clubs_display_settings']['output'];
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  readonly id: Scalars['uuid']['output'];
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  readonly members: ReadonlyArray<Clubs_Members>;
  readonly name: Scalars['String']['output'];
  readonly onboarding?: Maybe<Scalars['clubs_onboarding']['output']>;
  readonly onboarding_requirements?: Maybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly privacy: Club_Privacy;
  /** An array relationship */
  readonly projects: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_aggregate: Projects_Aggregate;
  /** An array relationship */
  readonly prospective_users_purchases: ReadonlyArray<Prospective_Users_Purchases>;
  /** An array relationship */
  readonly quests: ReadonlyArray<Quests>;
  /** An aggregate relationship */
  readonly quests_aggregate: Quests_Aggregate;
  /** An array relationship */
  readonly rooms: ReadonlyArray<Rooms>;
  /** An aggregate relationship */
  readonly rooms_aggregate?: Maybe<Rooms_Aggregate>;
  /** An object relationship */
  readonly schedule?: Maybe<Schedules>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  /** A function to compute club status based on lock_time and unlock_time. */
  readonly status: Club_Status;
  /** An array relationship */
  readonly themes: ReadonlyArray<Themes>;
  /** An array relationship */
  readonly tutorials: ReadonlyArray<Tutorials>;
  readonly typeform_name?: Maybe<Scalars['String']['output']>;
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly url_name: Scalars['String']['output'];
};


/** columns and relationships of "clubs" */
export type ClubsBadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsClipboard_PromptArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "clubs" */
export type ClubsDisplay_SettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "clubs" */
export type ClubsFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsMembersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsOnboardingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "clubs" */
export type ClubsOnboarding_RequirementsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "clubs" */
export type ClubsProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsProjects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsProspective_Users_PurchasesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsQuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsQuests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsRoomsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsRooms_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsThemesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Themes_Order_By>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsTutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Order_By>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};

/** aggregated selection of "clubs" */
export type Clubs_Aggregate = {
  readonly __typename?: 'clubs_aggregate';
  readonly aggregate?: Maybe<Clubs_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Clubs>;
};

export type Clubs_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Clubs_Aggregate_Bool_Exp_Count>;
};

export type Clubs_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Clubs_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clubs" */
export type Clubs_Aggregate_Fields = {
  readonly __typename?: 'clubs_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Clubs_Max_Fields>;
  readonly min?: Maybe<Clubs_Min_Fields>;
};


/** aggregate fields of "clubs" */
export type Clubs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "clubs" */
export type Clubs_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Clubs_Max_Order_By>;
  readonly min?: InputMaybe<Clubs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Clubs_Append_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly display_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly onboarding_requirements?: InputMaybe<Club_Onboarding_Requirement>;
};

/** Boolean expression to filter rows from the table "clubs". All fields are combined with a logical 'AND'. */
export type Clubs_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Clubs_Bool_Exp>>;
  readonly _not?: InputMaybe<Clubs_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Clubs_Bool_Exp>>;
  readonly access_feature?: InputMaybe<Features_Bool_Exp>;
  readonly access_feature_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly badges?: InputMaybe<Badges_Bool_Exp>;
  readonly clipboard_prompt?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly display_settings?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly feeds?: InputMaybe<Feeds_Bool_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly lock_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly members?: InputMaybe<Clubs_Members_Bool_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly onboarding?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly onboarding_requirements?: InputMaybe<Club_Onboarding_Requirement_Bool_Exp>;
  readonly privacy?: InputMaybe<Club_Privacy_Bool_Exp>;
  readonly projects?: InputMaybe<Projects_Bool_Exp>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  readonly prospective_users_purchases?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
  readonly quests?: InputMaybe<Quests_Bool_Exp>;
  readonly quests_aggregate?: InputMaybe<Quests_Aggregate_Bool_Exp>;
  readonly rooms?: InputMaybe<Rooms_Bool_Exp>;
  readonly rooms_aggregate?: InputMaybe<Rooms_Aggregate_Bool_Exp>;
  readonly schedule?: InputMaybe<Schedules_Bool_Exp>;
  readonly schedule_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly status?: InputMaybe<Club_Status_Bool_Exp>;
  readonly themes?: InputMaybe<Themes_Bool_Exp>;
  readonly tutorials?: InputMaybe<Tutorials_Bool_Exp>;
  readonly typeform_name?: InputMaybe<String_Comparison_Exp>;
  readonly unlock_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly url_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clubs" */
export enum Clubs_Constraint {
  /** unique or primary key constraint on columns "association_id" */
  ClubsAssociationIdKey = 'clubs_association_id_key',
  /** unique or primary key constraint on columns "id" */
  ClubsPkey = 'clubs_pkey',
  /** unique or primary key constraint on columns "url_name" */
  ClubsUrlNameKey = 'clubs_url_name_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Clubs_Delete_At_Path_Input = {
  readonly clipboard_prompt?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly display_settings?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Clubs_Delete_Elem_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['Int']['input']>;
  readonly display_settings?: InputMaybe<Scalars['Int']['input']>;
  readonly onboarding_requirements?: InputMaybe<Club_Onboarding_Requirement>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Clubs_Delete_Key_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['String']['input']>;
  readonly display_settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "clubs" */
export type Clubs_Insert_Input = {
  readonly access_feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly display_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly mute?: InputMaybe<Scalars['clubs_display_settings']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly onboarding?: InputMaybe<Scalars['clubs_onboarding']['input']>;
  readonly onboarding_requirements?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly privacy?: InputMaybe<Club_Privacy>;
  readonly projects?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  readonly rooms?: InputMaybe<Rooms_Arr_Rel_Insert_Input>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Club_Status>;
  readonly typeform_name?: InputMaybe<Scalars['String']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Clubs_Max_Fields = {
  readonly __typename?: 'clubs_max_fields';
  readonly access_feature_id?: Maybe<Scalars['uuid']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  /** A function to compute club status based on lock_time and unlock_time. */
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly typeform_name?: Maybe<Scalars['String']['output']>;
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "clubs" */
export type Clubs_Max_Order_By = {
  readonly access_feature_id?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly typeform_name?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** columns and relationships of "clubs_members" */
export type Clubs_Members = {
  readonly __typename?: 'clubs_members';
  /** An object relationship */
  readonly club: Clubs;
  readonly club_id: Scalars['uuid']['output'];
  readonly onboarded: Scalars['Boolean']['output'];
  readonly onboarding?: Maybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};


/** columns and relationships of "clubs_members" */
export type Clubs_MembersOnboardingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "clubs_members" */
export type Clubs_Members_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Clubs_Members_Max_Order_By>;
  readonly min?: InputMaybe<Clubs_Members_Min_Order_By>;
};

export type Clubs_Members_Append_Input = {
  readonly onboarding?: InputMaybe<Club_Onboarding_Requirement>;
};

/** Boolean expression to filter rows from the table "clubs_members". All fields are combined with a logical 'AND'. */
export type Clubs_Members_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Clubs_Members_Bool_Exp>>;
  readonly _not?: InputMaybe<Clubs_Members_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Clubs_Members_Bool_Exp>>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly onboarded?: InputMaybe<Boolean_Comparison_Exp>;
  readonly onboarding?: InputMaybe<Club_Onboarding_Requirement_Bool_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

export type Clubs_Members_Delete_Elem_Input = {
  readonly onboarding?: InputMaybe<Club_Onboarding_Requirement>;
};

export type Clubs_Members_Insert_Input = {
  readonly onboarding?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
};

/** order by max() on columns of table "clubs_members" */
export type Clubs_Members_Max_Order_By = {
  readonly club_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "clubs_members" */
export type Clubs_Members_Min_Order_By = {
  readonly club_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clubs_members" */
export type Clubs_Members_Mutation_Response = {
  readonly __typename?: 'clubs_members_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Clubs_Members>;
};

/** Ordering options when selecting data from "clubs_members". */
export type Clubs_Members_Order_By = {
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly onboarded?: InputMaybe<Order_By>;
  readonly onboarding?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clubs_members */
export type Clubs_Members_Pk_Columns_Input = {
  readonly club_id: Scalars['uuid']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** select columns of table "clubs_members" */
export enum Clubs_Members_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  Onboarding = 'onboarding',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "clubs_members" */
export type Clubs_Members_Set_Input = {
  readonly last_join?: InputMaybe<Scalars['date']['input']>;
  readonly onboarding?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
};

/** Streaming cursor of the table "clubs_members" */
export type Clubs_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Clubs_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Members_Stream_Cursor_Value_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  readonly onboarding?: InputMaybe<Scalars['jsonb']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

export type Clubs_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Clubs_Members_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Clubs_Members_Bool_Exp;
};

/** aggregate min on columns */
export type Clubs_Min_Fields = {
  readonly __typename?: 'clubs_min_fields';
  readonly access_feature_id?: Maybe<Scalars['uuid']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  /** A function to compute club status based on lock_time and unlock_time. */
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly typeform_name?: Maybe<Scalars['String']['output']>;
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "clubs" */
export type Clubs_Min_Order_By = {
  readonly access_feature_id?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly typeform_name?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clubs" */
export type Clubs_Mutation_Response = {
  readonly __typename?: 'clubs_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  readonly returning?: Maybe<ReadonlyArray<Clubs>>;
};

/** input type for inserting object relation for remote table "clubs" */
export type Clubs_Obj_Rel_Insert_Input = {
  readonly data?: InputMaybe<Clubs_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Clubs_On_Conflict>;
};

/** on_conflict condition type for table "clubs" */
export type Clubs_On_Conflict = {
  readonly constraint?: InputMaybe<Clubs_Constraint>;
  readonly update_columns?: InputMaybe<ReadonlyArray<Clubs_Update_Column>>;
  readonly where?: InputMaybe<Clubs_Bool_Exp>;
};

/** Ordering options when selecting data from "clubs". */
export type Clubs_Order_By = {
  readonly access_feature?: InputMaybe<Features_Order_By>;
  readonly access_feature_id?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly badges_aggregate?: InputMaybe<Badges_Aggregate_Order_By>;
  readonly clipboard_prompt?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly display_settings?: InputMaybe<Order_By>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly members_aggregate?: InputMaybe<Clubs_Members_Aggregate_Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly onboarding?: InputMaybe<Order_By>;
  readonly onboarding_requirements?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  readonly prospective_users_purchases_aggregate?: InputMaybe<Prospective_Users_Purchases_Aggregate_Order_By>;
  readonly quests_aggregate?: InputMaybe<Quests_Aggregate_Order_By>;
  readonly rooms_aggregate?: InputMaybe<Rooms_Aggregate_Order_By>;
  readonly schedule?: InputMaybe<Schedules_Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly themes_aggregate?: InputMaybe<Themes_Aggregate_Order_By>;
  readonly tutorials_aggregate?: InputMaybe<Tutorials_Aggregate_Order_By>;
  readonly typeform_name?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clubs */
export type Clubs_Pk_Columns_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Clubs_Prepend_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly display_settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "clubs" */
export enum Clubs_Select_Column {
  /** column name */
  AccessFeatureId = 'access_feature_id',
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplaySettings = 'display_settings',
  /** column name */
  Id = 'id',
  /** column name */
  LockTime = 'lock_time',
  /** column name */
  Name = 'name',
  /** column name */
  Onboarding = 'onboarding',
  /** column name */
  OnboardingRequirements = 'onboarding_requirements',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  TypeformName = 'typeform_name',
  /** column name */
  UnlockTime = 'unlock_time',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlName = 'url_name'
}

/** input type for updating data in table "clubs" */
export type Clubs_Set_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly display_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly mute?: InputMaybe<Scalars['clubs_display_settings']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly onboarding?: InputMaybe<Scalars['clubs_onboarding']['input']>;
  readonly onboarding_requirements?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly privacy?: InputMaybe<Club_Privacy>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Club_Status>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "clubs" */
export type Clubs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Clubs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Stream_Cursor_Value_Input = {
  readonly access_feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly display_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly lock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly onboarding?: InputMaybe<Scalars['jsonb']['input']>;
  readonly onboarding_requirements?: InputMaybe<Scalars['jsonb']['input']>;
  readonly privacy?: InputMaybe<Scalars['String']['input']>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly typeform_name?: InputMaybe<Scalars['String']['input']>;
  readonly unlock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "clubs" */
export enum Clubs_Update_Column {
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  DisplaySettings = 'display_settings',
  /** column name */
  Name = 'name',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  UrlName = 'url_name'
}

export type Clubs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Clubs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Clubs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Clubs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Clubs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Clubs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Clubs_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where?: InputMaybe<Clubs_Bool_Exp>;
};

export enum Comment_Object_Type {
  Competitions = 'competitions',
  Orders = 'orders',
  Posts = 'posts',
  Projects = 'projects',
  Themes = 'themes'
}

export type Comment_Object_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Comment_Object_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Comment_Object_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Comment_Object_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Comment_Object_Type>>;
};

/** columns and relationships of "comments" */
export type Comments = {
  readonly __typename?: 'comments';
  readonly association_id: Scalars['String']['output'];
  readonly content: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly object_id: Scalars['String']['output'];
  readonly object_type: Comment_Object_Type;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Comments_Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Comments_Votes_Aggregate;
};


/** columns and relationships of "comments" */
export type CommentsVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type CommentsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};

/** aggregated selection of "comments" */
export type Comments_Aggregate = {
  readonly __typename?: 'comments_aggregate';
  readonly aggregate?: Maybe<Comments_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Comments>;
};

export type Comments_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Comments_Aggregate_Bool_Exp_Count>;
};

export type Comments_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Comments_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comments" */
export type Comments_Aggregate_Fields = {
  readonly __typename?: 'comments_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Comments_Max_Fields>;
  readonly min?: Maybe<Comments_Min_Fields>;
};


/** aggregate fields of "comments" */
export type Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comments" */
export type Comments_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Comments_Max_Order_By>;
  readonly min?: InputMaybe<Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comments" */
export type Comments_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Comments_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comments". All fields are combined with a logical 'AND'. */
export type Comments_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Comments_Bool_Exp>>;
  readonly _not?: InputMaybe<Comments_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Comments_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly content?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly object_id?: InputMaybe<String_Comparison_Exp>;
  readonly object_type?: InputMaybe<Comment_Object_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly votes?: InputMaybe<Comments_Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Comments_Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "comments" */
export enum Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsCommentsPkey = 'projects_comments_pkey'
}

export type Comments_Delete_Args = {
  readonly comment_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** input type for inserting data into table "comments" */
export type Comments_Insert_Input = {
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly object_id?: InputMaybe<Scalars['String']['input']>;
  readonly object_type?: InputMaybe<Comment_Object_Type>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly votes?: InputMaybe<Comments_Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Comments_Max_Fields = {
  readonly __typename?: 'comments_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly content?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly object_id?: Maybe<Scalars['String']['output']>;
  readonly object_type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "comments" */
export type Comments_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly content?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comments_Min_Fields = {
  readonly __typename?: 'comments_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly content?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly object_id?: Maybe<Scalars['String']['output']>;
  readonly object_type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "comments" */
export type Comments_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly content?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comments" */
export type Comments_Mutation_Response = {
  readonly __typename?: 'comments_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Comments>;
};

/** input type for inserting object relation for remote table "comments" */
export type Comments_Obj_Rel_Insert_Input = {
  readonly data: Comments_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** on_conflict condition type for table "comments" */
export type Comments_On_Conflict = {
  readonly constraint: Comments_Constraint;
  readonly update_columns?: ReadonlyArray<Comments_Update_Column>;
  readonly where?: InputMaybe<Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "comments". */
export type Comments_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly content?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Comments_Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: comments */
export type Comments_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "comments" */
export enum Comments_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  ObjectType = 'object_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "comments" */
export type Comments_Set_Input = {
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly object_type?: InputMaybe<Comment_Object_Type>;
};

/** Streaming cursor of the table "comments" */
export type Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comments_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly object_id?: InputMaybe<Scalars['String']['input']>;
  readonly object_type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "comments" */
export enum Comments_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  DeletedAt = 'deleted_at'
}

export type Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Comments_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Comments_Bool_Exp;
};

/** columns and relationships of "comments_votes" */
export type Comments_Votes = {
  readonly __typename?: 'comments_votes';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly comment: Comments;
  readonly comment_id: Scalars['uuid']['output'];
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "comments_votes" */
export type Comments_Votes_Aggregate = {
  readonly __typename?: 'comments_votes_aggregate';
  readonly aggregate?: Maybe<Comments_Votes_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Comments_Votes>;
};

export type Comments_Votes_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Comments_Votes_Aggregate_Bool_Exp_Count>;
};

export type Comments_Votes_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Comments_Votes_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comments_votes" */
export type Comments_Votes_Aggregate_Fields = {
  readonly __typename?: 'comments_votes_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Comments_Votes_Max_Fields>;
  readonly min?: Maybe<Comments_Votes_Min_Fields>;
};


/** aggregate fields of "comments_votes" */
export type Comments_Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comments_votes" */
export type Comments_Votes_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Comments_Votes_Max_Order_By>;
  readonly min?: InputMaybe<Comments_Votes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comments_votes" */
export type Comments_Votes_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Comments_Votes_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Comments_Votes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comments_votes". All fields are combined with a logical 'AND'. */
export type Comments_Votes_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Comments_Votes_Bool_Exp>>;
  readonly _not?: InputMaybe<Comments_Votes_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Comments_Votes_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly comment?: InputMaybe<Comments_Bool_Exp>;
  readonly comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "comments_votes" */
export enum Comments_Votes_Constraint {
  /** unique or primary key constraint on columns "comment_id", "user_id", "emote_name" */
  CommentsVotesCommentIdUserIdEmoteNameKey = 'comments_votes_comment_id_user_id_emote_name_key',
  /** unique or primary key constraint on columns "id" */
  CommentsVotesPkey = 'comments_votes_pkey'
}

/** input type for inserting data into table "comments_votes" */
export type Comments_Votes_Insert_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  readonly comment_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly emote_hex?: InputMaybe<Scalars['String']['input']>;
  readonly emote_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Comments_Votes_Max_Fields = {
  readonly __typename?: 'comments_votes_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly comment_id?: Maybe<Scalars['uuid']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "comments_votes" */
export type Comments_Votes_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly comment_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comments_Votes_Min_Fields = {
  readonly __typename?: 'comments_votes_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly comment_id?: Maybe<Scalars['uuid']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "comments_votes" */
export type Comments_Votes_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly comment_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comments_votes" */
export type Comments_Votes_Mutation_Response = {
  readonly __typename?: 'comments_votes_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Comments_Votes>;
};

/** on_conflict condition type for table "comments_votes" */
export type Comments_Votes_On_Conflict = {
  readonly constraint: Comments_Votes_Constraint;
  readonly update_columns?: ReadonlyArray<Comments_Votes_Update_Column>;
  readonly where?: InputMaybe<Comments_Votes_Bool_Exp>;
};

/** Ordering options when selecting data from "comments_votes". */
export type Comments_Votes_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly comment?: InputMaybe<Comments_Order_By>;
  readonly comment_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** select columns of table "comments_votes" */
export enum Comments_Votes_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "comments_votes" */
export type Comments_Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Comments_Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comments_Votes_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly comment_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "comments_votes" (current role has no relevant permissions) */
export enum Comments_Votes_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

export enum Competition_Status {
  Active = 'active',
  Ended = 'ended',
  Hidden = 'hidden',
  Preregistration = 'preregistration',
  Review = 'review',
  Submission = 'submission'
}

export type Competition_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Competition_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Competition_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Competition_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Competition_Status>>;
};

export enum Competition_Type {
  Challenge = 'challenge',
  Live = 'live',
  Long = 'long'
}

export type Competition_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Competition_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Competition_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Competition_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Competition_Type>>;
};

/** columns and relationships of "competitions" */
export type Competitions = {
  readonly __typename?: 'competitions';
  readonly association_id: Scalars['String']['output'];
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly default_project_name?: Maybe<Scalars['String']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly due_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly gif?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly host: Users;
  readonly host_id: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  /** An array relationship */
  readonly judges: ReadonlyArray<Competitions_Judges>;
  readonly max_age_allowed?: Maybe<Scalars['Int']['output']>;
  readonly max_team_size: Scalars['Int']['output'];
  readonly meta?: Maybe<Scalars['competitions_meta']['output']>;
  readonly name: Scalars['String']['output'];
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  readonly photo: Scalars['String']['output'];
  readonly prize_meta?: Maybe<Scalars['competitions_prize_meta']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
  readonly prizes_distributed: Scalars['Boolean']['output'];
  /** An array relationship */
  readonly projects: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_aggregate: Projects_Aggregate;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly rules?: Maybe<Scalars['String']['output']>;
  readonly special_prizes?: Maybe<Scalars['jsonb']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status: Competition_Status;
  readonly submission_form_link?: Maybe<Scalars['String']['output']>;
  readonly submissions_reviewed: Scalars['Boolean']['output'];
  readonly tagline?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly tags: ReadonlyArray<Competitions_Tags>;
  /** An object relationship */
  readonly theme?: Maybe<Themes>;
  readonly theme_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  readonly tool?: Maybe<Tools>;
  readonly tool_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly topic?: Maybe<Topics>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
  /** An array relationship */
  readonly tutorials: ReadonlyArray<Competitions_Tutorials>;
  readonly type: Competition_Type;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name: Scalars['String']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
};


/** columns and relationships of "competitions" */
export type CompetitionsCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsJudgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "competitions" */
export type CompetitionsPrize_MetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "competitions" */
export type CompetitionsProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsProjects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsSpecial_PrizesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "competitions" */
export type CompetitionsTagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsTutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** aggregated selection of "competitions" */
export type Competitions_Aggregate = {
  readonly __typename?: 'competitions_aggregate';
  readonly aggregate?: Maybe<Competitions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Competitions>;
};

export type Competitions_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Competitions_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Competitions_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Competitions_Aggregate_Bool_Exp_Count>;
};

export type Competitions_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Competitions_Select_Column_Competitions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Competitions_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Competitions_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Competitions_Select_Column_Competitions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Competitions_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Competitions_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Competitions_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "competitions" */
export type Competitions_Aggregate_Fields = {
  readonly __typename?: 'competitions_aggregate_fields';
  readonly avg?: Maybe<Competitions_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Competitions_Max_Fields>;
  readonly min?: Maybe<Competitions_Min_Fields>;
  readonly stddev?: Maybe<Competitions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Competitions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Competitions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Competitions_Sum_Fields>;
  readonly var_pop?: Maybe<Competitions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Competitions_Var_Samp_Fields>;
  readonly variance?: Maybe<Competitions_Variance_Fields>;
};


/** aggregate fields of "competitions" */
export type Competitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "competitions" */
export type Competitions_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Competitions_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Competitions_Max_Order_By>;
  readonly min?: InputMaybe<Competitions_Min_Order_By>;
  readonly stddev?: InputMaybe<Competitions_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Competitions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Competitions_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Competitions_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Competitions_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Competitions_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Competitions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Competitions_Append_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Competitions_Avg_Fields = {
  readonly __typename?: 'competitions_avg_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by avg() on columns of table "competitions" */
export type Competitions_Avg_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions". All fields are combined with a logical 'AND'. */
export type Competitions_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Competitions_Bool_Exp>>;
  readonly _not?: InputMaybe<Competitions_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Competitions_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly default_project_name?: InputMaybe<String_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly due_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly ends_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly gif?: InputMaybe<String_Comparison_Exp>;
  readonly host?: InputMaybe<Users_Bool_Exp>;
  readonly host_id?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly judges?: InputMaybe<Competitions_Judges_Bool_Exp>;
  readonly max_age_allowed?: InputMaybe<Int_Comparison_Exp>;
  readonly max_team_size?: InputMaybe<Int_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly num_contestants?: InputMaybe<Bigint_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly prize_meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly prize_pool?: InputMaybe<Numeric_Comparison_Exp>;
  readonly prizes_distributed?: InputMaybe<Boolean_Comparison_Exp>;
  readonly projects?: InputMaybe<Projects_Bool_Exp>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly room_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly rules?: InputMaybe<String_Comparison_Exp>;
  readonly special_prizes?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly status?: InputMaybe<Competition_Status_Bool_Exp>;
  readonly submission_form_link?: InputMaybe<String_Comparison_Exp>;
  readonly submissions_reviewed?: InputMaybe<Boolean_Comparison_Exp>;
  readonly tagline?: InputMaybe<String_Comparison_Exp>;
  readonly tags?: InputMaybe<Competitions_Tags_Bool_Exp>;
  readonly theme?: InputMaybe<Themes_Bool_Exp>;
  readonly theme_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly tool?: InputMaybe<Tools_Bool_Exp>;
  readonly tool_id?: InputMaybe<String_Comparison_Exp>;
  readonly topic?: InputMaybe<Topics_Bool_Exp>;
  readonly topic_id?: InputMaybe<String_Comparison_Exp>;
  readonly transactions?: InputMaybe<Transactions_Bool_Exp>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
  readonly tutorials?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
  readonly type?: InputMaybe<Competition_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly url_name?: InputMaybe<String_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "competitions" */
export enum Competitions_Constraint {
  /** unique or primary key constraint on columns "name" */
  CompetitionsNameKey = 'competitions_name_key',
  /** unique or primary key constraint on columns "id" */
  CompetitionsPkey = 'competitions_pkey',
  /** unique or primary key constraint on columns "url_name" */
  CompetitionsUrlNameKey = 'competitions_url_name_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Competitions_Delete_At_Path_Input = {
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly prize_meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly special_prizes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Competitions_Delete_Elem_Input = {
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['Int']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Competitions_Delete_Key_Input = {
  readonly meta?: InputMaybe<Scalars['String']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['String']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "competitions" */
export type Competitions_Inc_Input = {
  readonly max_age_allowed?: InputMaybe<Scalars['Int']['input']>;
  readonly max_team_size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "competitions" */
export type Competitions_Insert_Input = {
  readonly comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly due_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly gif?: InputMaybe<Scalars['String']['input']>;
  readonly host_id?: InputMaybe<Scalars['String']['input']>;
  readonly max_age_allowed?: InputMaybe<Scalars['Int']['input']>;
  readonly max_team_size?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['competitions_meta']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['competitions_prize_meta']['input']>;
  readonly prizes_distributed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly projects?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly rules?: InputMaybe<Scalars['String']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Competition_Status>;
  readonly submission_form_link?: InputMaybe<Scalars['String']['input']>;
  readonly submissions_reviewed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly tool_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Competition_Type>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
  readonly votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "competitions_judges" */
export type Competitions_Judges = {
  readonly __typename?: 'competitions_judges';
  /** An object relationship */
  readonly competition: Competitions;
  readonly competition_id: Scalars['String']['output'];
  /** An object relationship */
  readonly judge: Users;
  readonly judge_id: Scalars['String']['output'];
};

/** order by aggregate values of table "competitions_judges" */
export type Competitions_Judges_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Competitions_Judges_Max_Order_By>;
  readonly min?: InputMaybe<Competitions_Judges_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "competitions_judges". All fields are combined with a logical 'AND'. */
export type Competitions_Judges_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Competitions_Judges_Bool_Exp>>;
  readonly _not?: InputMaybe<Competitions_Judges_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Competitions_Judges_Bool_Exp>>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly competition_id?: InputMaybe<String_Comparison_Exp>;
  readonly judge?: InputMaybe<Users_Bool_Exp>;
  readonly judge_id?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "competitions_judges" */
export type Competitions_Judges_Max_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions_judges" */
export type Competitions_Judges_Min_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "competitions_judges". */
export type Competitions_Judges_Order_By = {
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly judge?: InputMaybe<Users_Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
};

/** select columns of table "competitions_judges" */
export enum Competitions_Judges_Select_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  JudgeId = 'judge_id'
}

/** Streaming cursor of the table "competitions_judges" */
export type Competitions_Judges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Competitions_Judges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Judges_Stream_Cursor_Value_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly judge_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Competitions_Max_Fields = {
  readonly __typename?: 'competitions_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly default_project_name?: Maybe<Scalars['String']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly due_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly gif?: Maybe<Scalars['String']['output']>;
  readonly host_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly max_age_allowed?: Maybe<Scalars['Int']['output']>;
  readonly max_team_size?: Maybe<Scalars['Int']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly rules?: Maybe<Scalars['String']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly submission_form_link?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly theme_id?: Maybe<Scalars['uuid']['output']>;
  readonly tool_id?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "competitions" */
export type Competitions_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly default_project_name?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly due_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly gif?: InputMaybe<Order_By>;
  readonly host_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly rules?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submission_form_link?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly theme_id?: InputMaybe<Order_By>;
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Competitions_Min_Fields = {
  readonly __typename?: 'competitions_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly default_project_name?: Maybe<Scalars['String']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly due_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly gif?: Maybe<Scalars['String']['output']>;
  readonly host_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly max_age_allowed?: Maybe<Scalars['Int']['output']>;
  readonly max_team_size?: Maybe<Scalars['Int']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly rules?: Maybe<Scalars['String']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly submission_form_link?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly theme_id?: Maybe<Scalars['uuid']['output']>;
  readonly tool_id?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "competitions" */
export type Competitions_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly default_project_name?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly due_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly gif?: InputMaybe<Order_By>;
  readonly host_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly rules?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submission_form_link?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly theme_id?: InputMaybe<Order_By>;
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions" */
export type Competitions_Mutation_Response = {
  readonly __typename?: 'competitions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Competitions>;
};

/** input type for inserting object relation for remote table "competitions" */
export type Competitions_Obj_Rel_Insert_Input = {
  readonly data: Competitions_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Competitions_On_Conflict>;
};

/** on_conflict condition type for table "competitions" */
export type Competitions_On_Conflict = {
  readonly constraint: Competitions_Constraint;
  readonly update_columns?: ReadonlyArray<Competitions_Update_Column>;
  readonly where?: InputMaybe<Competitions_Bool_Exp>;
};

/** Ordering options when selecting data from "competitions". */
export type Competitions_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly default_project_name?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly due_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly gif?: InputMaybe<Order_By>;
  readonly host?: InputMaybe<Users_Order_By>;
  readonly host_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly judges_aggregate?: InputMaybe<Competitions_Judges_Aggregate_Order_By>;
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly num_contestants?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly prize_meta?: InputMaybe<Order_By>;
  readonly prize_pool?: InputMaybe<Order_By>;
  readonly prizes_distributed?: InputMaybe<Order_By>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly rules?: InputMaybe<Order_By>;
  readonly special_prizes?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submission_form_link?: InputMaybe<Order_By>;
  readonly submissions_reviewed?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly tags_aggregate?: InputMaybe<Competitions_Tags_Aggregate_Order_By>;
  readonly theme?: InputMaybe<Themes_Order_By>;
  readonly theme_id?: InputMaybe<Order_By>;
  readonly tool?: InputMaybe<Tools_Order_By>;
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic?: InputMaybe<Topics_Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Order_By>;
  readonly tutorials_aggregate?: InputMaybe<Competitions_Tutorials_Aggregate_Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: competitions */
export type Competitions_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Competitions_Prepend_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "competitions" */
export enum Competitions_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultProjectName = 'default_project_name',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueAt = 'due_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  Gif = 'gif',
  /** column name */
  HostId = 'host_id',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAgeAllowed = 'max_age_allowed',
  /** column name */
  MaxTeamSize = 'max_team_size',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  PrizeMeta = 'prize_meta',
  /** column name */
  PrizesDistributed = 'prizes_distributed',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Rules = 'rules',
  /** column name */
  SpecialPrizes = 'special_prizes',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  SubmissionFormLink = 'submission_form_link',
  /** column name */
  SubmissionsReviewed = 'submissions_reviewed',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlName = 'url_name'
}

/** select "competitions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "competitions" */
export enum Competitions_Select_Column_Competitions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PrizesDistributed = 'prizes_distributed',
  /** column name */
  SubmissionsReviewed = 'submissions_reviewed'
}

/** select "competitions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "competitions" */
export enum Competitions_Select_Column_Competitions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PrizesDistributed = 'prizes_distributed',
  /** column name */
  SubmissionsReviewed = 'submissions_reviewed'
}

/** input type for updating data in table "competitions" */
export type Competitions_Set_Input = {
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly due_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly gif?: InputMaybe<Scalars['String']['input']>;
  readonly host_id?: InputMaybe<Scalars['String']['input']>;
  readonly max_age_allowed?: InputMaybe<Scalars['Int']['input']>;
  readonly max_team_size?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['competitions_meta']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['competitions_prize_meta']['input']>;
  readonly prizes_distributed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly rules?: InputMaybe<Scalars['String']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Competition_Status>;
  readonly submission_form_link?: InputMaybe<Scalars['String']['input']>;
  readonly submissions_reviewed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly tool_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Competition_Type>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Competitions_Stddev_Fields = {
  readonly __typename?: 'competitions_stddev_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev() on columns of table "competitions" */
export type Competitions_Stddev_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Competitions_Stddev_Pop_Fields = {
  readonly __typename?: 'competitions_stddev_pop_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev_pop() on columns of table "competitions" */
export type Competitions_Stddev_Pop_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Competitions_Stddev_Samp_Fields = {
  readonly __typename?: 'competitions_stddev_samp_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev_samp() on columns of table "competitions" */
export type Competitions_Stddev_Samp_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions" */
export type Competitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Competitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly default_project_name?: InputMaybe<Scalars['String']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly due_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly gif?: InputMaybe<Scalars['String']['input']>;
  readonly host_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly max_age_allowed?: InputMaybe<Scalars['Int']['input']>;
  readonly max_team_size?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly prizes_distributed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rules?: InputMaybe<Scalars['String']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly submission_form_link?: InputMaybe<Scalars['String']['input']>;
  readonly submissions_reviewed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly theme_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly tool_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Competitions_Sum_Fields = {
  readonly __typename?: 'competitions_sum_fields';
  readonly max_age_allowed?: Maybe<Scalars['Int']['output']>;
  readonly max_team_size?: Maybe<Scalars['Int']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "competitions" */
export type Competitions_Sum_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** columns and relationships of "competitions_tags" */
export type Competitions_Tags = {
  readonly __typename?: 'competitions_tags';
  /** An object relationship */
  readonly competition: Competitions;
  readonly competition_id: Scalars['String']['output'];
  /** An object relationship */
  readonly tag: Tags;
  readonly tag_id: Scalars['String']['output'];
};

/** order by aggregate values of table "competitions_tags" */
export type Competitions_Tags_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Competitions_Tags_Max_Order_By>;
  readonly min?: InputMaybe<Competitions_Tags_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "competitions_tags". All fields are combined with a logical 'AND'. */
export type Competitions_Tags_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Competitions_Tags_Bool_Exp>>;
  readonly _not?: InputMaybe<Competitions_Tags_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Competitions_Tags_Bool_Exp>>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly competition_id?: InputMaybe<String_Comparison_Exp>;
  readonly tag?: InputMaybe<Tags_Bool_Exp>;
  readonly tag_id?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "competitions_tags" */
export type Competitions_Tags_Max_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions_tags" */
export type Competitions_Tags_Min_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "competitions_tags". */
export type Competitions_Tags_Order_By = {
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tag?: InputMaybe<Tags_Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** select columns of table "competitions_tags" */
export enum Competitions_Tags_Select_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  TagId = 'tag_id'
}

/** Streaming cursor of the table "competitions_tags" */
export type Competitions_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Competitions_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Tags_Stream_Cursor_Value_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "competitions_tutorials" */
export type Competitions_Tutorials = {
  readonly __typename?: 'competitions_tutorials';
  /** An object relationship */
  readonly competition: Competitions;
  readonly competition_id: Scalars['String']['output'];
  /** An object relationship */
  readonly tutorial: Tutorials;
  readonly tutorial_id: Scalars['String']['output'];
};

/** order by aggregate values of table "competitions_tutorials" */
export type Competitions_Tutorials_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Competitions_Tutorials_Max_Order_By>;
  readonly min?: InputMaybe<Competitions_Tutorials_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "competitions_tutorials". All fields are combined with a logical 'AND'. */
export type Competitions_Tutorials_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Bool_Exp>>;
  readonly _not?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Bool_Exp>>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly competition_id?: InputMaybe<String_Comparison_Exp>;
  readonly tutorial?: InputMaybe<Tutorials_Bool_Exp>;
  readonly tutorial_id?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "competitions_tutorials" */
export type Competitions_Tutorials_Max_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions_tutorials" */
export type Competitions_Tutorials_Min_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "competitions_tutorials". */
export type Competitions_Tutorials_Order_By = {
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tutorial?: InputMaybe<Tutorials_Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** select columns of table "competitions_tutorials" */
export enum Competitions_Tutorials_Select_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  TutorialId = 'tutorial_id'
}

/** Streaming cursor of the table "competitions_tutorials" */
export type Competitions_Tutorials_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Competitions_Tutorials_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Tutorials_Stream_Cursor_Value_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "competitions" */
export enum Competitions_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DueAt = 'due_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  Gif = 'gif',
  /** column name */
  HostId = 'host_id',
  /** column name */
  MaxAgeAllowed = 'max_age_allowed',
  /** column name */
  MaxTeamSize = 'max_team_size',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  PrizeMeta = 'prize_meta',
  /** column name */
  PrizesDistributed = 'prizes_distributed',
  /** column name */
  Rules = 'rules',
  /** column name */
  SpecialPrizes = 'special_prizes',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  SubmissionFormLink = 'submission_form_link',
  /** column name */
  SubmissionsReviewed = 'submissions_reviewed',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UrlName = 'url_name'
}

export type Competitions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Competitions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Competitions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Competitions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Competitions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Competitions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Competitions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Competitions_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Competitions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Competitions_Var_Pop_Fields = {
  readonly __typename?: 'competitions_var_pop_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by var_pop() on columns of table "competitions" */
export type Competitions_Var_Pop_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Competitions_Var_Samp_Fields = {
  readonly __typename?: 'competitions_var_samp_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by var_samp() on columns of table "competitions" */
export type Competitions_Var_Samp_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Competitions_Variance_Fields = {
  readonly __typename?: 'competitions_variance_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by variance() on columns of table "competitions" */
export type Competitions_Variance_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

export type Complete_Clubs_Members_Onboarding_Requirement_Args = {
  readonly _club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly _requirement?: InputMaybe<Scalars['String']['input']>;
  readonly _user_id?: InputMaybe<Scalars['String']['input']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['date']['input']>;
  readonly _gt?: InputMaybe<Scalars['date']['input']>;
  readonly _gte?: InputMaybe<Scalars['date']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['date']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['date']['input']>;
  readonly _lte?: InputMaybe<Scalars['date']['input']>;
  readonly _neq?: InputMaybe<Scalars['date']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['date']['input']>>;
};

/** A list of product features */
export type Features = {
  readonly __typename?: 'features';
  readonly active: Scalars['Boolean']['output'];
  /** An array relationship */
  readonly clubs: ReadonlyArray<Clubs>;
  /** An aggregate relationship */
  readonly clubs_aggregate: Clubs_Aggregate;
  /** An array relationship */
  readonly users: ReadonlyArray<Users_Features>;
};


/** A list of product features */
export type FeaturesClubsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


/** A list of product features */
export type FeaturesClubs_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


/** A list of product features */
export type FeaturesUsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "features". All fields are combined with a logical 'AND'. */
export type Features_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Features_Bool_Exp>>;
  readonly _not?: InputMaybe<Features_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Features_Bool_Exp>>;
  readonly active?: InputMaybe<Boolean_Comparison_Exp>;
  readonly clubs?: InputMaybe<Clubs_Bool_Exp>;
  readonly clubs_aggregate?: InputMaybe<Clubs_Aggregate_Bool_Exp>;
  readonly users?: InputMaybe<Users_Features_Bool_Exp>;
};

/** Ordering options when selecting data from "features". */
export type Features_Order_By = {
  readonly active?: InputMaybe<Order_By>;
  readonly clubs_aggregate?: InputMaybe<Clubs_Aggregate_Order_By>;
  readonly users_aggregate?: InputMaybe<Users_Features_Aggregate_Order_By>;
};

/** select columns of table "features" */
export enum Features_Select_Column {
  /** column name */
  Active = 'active'
}

/** Streaming cursor of the table "features" */
export type Features_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Features_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Features_Stream_Cursor_Value_Input = {
  readonly active?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Feed_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Scalars['feed_type']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['feed_type']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['feed_type']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['feed_type']['input']>>;
};

/** Suggested prize store items */
export type Feedback = {
  readonly __typename?: 'feedback';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  /** An object relationship */
  readonly competition?: Maybe<Competitions>;
  readonly created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly creator: Users;
  readonly creator_id: Scalars['String']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly idea: Scalars['String']['output'];
  /** An object relationship */
  readonly live_event?: Maybe<Live_Events>;
  /** An object relationship */
  readonly project?: Maybe<Projects>;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly sentiment: Feedback_Sentiment;
  readonly source: Scalars['feedback_source']['output'];
};

/** order by aggregate values of table "feedback" */
export type Feedback_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Feedback_Max_Order_By>;
  readonly min?: InputMaybe<Feedback_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "feedback". All fields are combined with a logical 'AND'. */
export type Feedback_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Feedback_Bool_Exp>>;
  readonly _not?: InputMaybe<Feedback_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Feedback_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly creator?: InputMaybe<Users_Bool_Exp>;
  readonly creator_id?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly idea?: InputMaybe<String_Comparison_Exp>;
  readonly live_event?: InputMaybe<Live_Events_Bool_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly sentiment?: InputMaybe<Feedback_Sentiment_Bool_Exp>;
  readonly source?: InputMaybe<Feedback_Source_Bool_Exp>;
};

/** unique or primary key constraints on table "feedback" */
export enum Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemSuggestionsPkey = 'item_suggestions_pkey'
}

/** input type for inserting data into table "feedback" */
export type Feedback_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly competition?: InputMaybe<Competitions_Obj_Rel_Insert_Input>;
  readonly idea?: InputMaybe<Scalars['String']['input']>;
  readonly live_event?: InputMaybe<Live_Events_Obj_Rel_Insert_Input>;
  readonly project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly sentiment?: InputMaybe<Feedback_Sentiment>;
  readonly source?: InputMaybe<Scalars['feedback_source']['input']>;
};

/** order by max() on columns of table "feedback" */
export type Feedback_Max_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly idea?: InputMaybe<Order_By>;
  readonly sentiment?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "feedback" */
export type Feedback_Min_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly idea?: InputMaybe<Order_By>;
  readonly sentiment?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "feedback" */
export type Feedback_Mutation_Response = {
  readonly __typename?: 'feedback_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Feedback>;
};

/** on_conflict condition type for table "feedback" */
export type Feedback_On_Conflict = {
  readonly constraint: Feedback_Constraint;
  readonly update_columns?: ReadonlyArray<Feedback_Update_Column>;
  readonly where?: InputMaybe<Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "feedback". */
export type Feedback_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator?: InputMaybe<Users_Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly idea?: InputMaybe<Order_By>;
  readonly live_event?: InputMaybe<Live_Events_Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly sentiment?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
};

/** select columns of table "feedback" */
export enum Feedback_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Idea = 'idea',
  /** column name */
  Sentiment = 'sentiment',
  /** column name */
  Source = 'source'
}

export enum Feedback_Sentiment {
  Bad = 'bad',
  Good = 'good',
  Neutral = 'neutral'
}

export type Feedback_Sentiment_Bool_Exp = {
  readonly _eq?: InputMaybe<Feedback_Sentiment>;
  readonly _in?: InputMaybe<ReadonlyArray<Feedback_Sentiment>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Feedback_Sentiment>;
  readonly _nin?: InputMaybe<ReadonlyArray<Feedback_Sentiment>>;
};

export type Feedback_Set_Input = {
  readonly sentiment?: InputMaybe<Feedback_Sentiment>;
  readonly source?: InputMaybe<Scalars['feedback_source']['input']>;
};

export type Feedback_Source_Bool_Exp = {
  readonly _eq?: InputMaybe<Scalars['feedback_source']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['feedback_source']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['feedback_source']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['feedback_source']['input']>>;
};

/** Streaming cursor of the table "feedback" */
export type Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feedback_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly idea?: InputMaybe<Scalars['String']['input']>;
  readonly sentiment?: InputMaybe<Scalars['String']['input']>;
  readonly source?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "feedback" (current role has no relevant permissions) */
export enum Feedback_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "feeds" */
export type Feeds = {
  readonly __typename?: 'feeds';
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  readonly comments_votes: ReadonlyArray<Comments_Votes>;
  /** An aggregate relationship */
  readonly comments_votes_aggregate: Comments_Votes_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly payload: Scalars['jsonb']['output'];
  readonly type: Scalars['feed_type']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
};


/** columns and relationships of "feeds" */
export type FeedsCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "feeds" */
export type FeedsComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "feeds" */
export type FeedsComments_VotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


/** columns and relationships of "feeds" */
export type FeedsComments_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


/** columns and relationships of "feeds" */
export type FeedsPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "feeds" */
export type FeedsVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "feeds" */
export type FeedsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** order by aggregate values of table "feeds" */
export type Feeds_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Feeds_Max_Order_By>;
  readonly min?: InputMaybe<Feeds_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "feeds". All fields are combined with a logical 'AND'. */
export type Feeds_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Feeds_Bool_Exp>>;
  readonly _not?: InputMaybe<Feeds_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Feeds_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly comments_votes?: InputMaybe<Comments_Votes_Bool_Exp>;
  readonly comments_votes_aggregate?: InputMaybe<Comments_Votes_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly payload?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly type?: InputMaybe<Feed_Type_Bool_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
};

export type Feeds_Delete_Args = {
  readonly feed_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type Feeds_Insert_Input = {
  readonly type?: InputMaybe<Scalars['feed_type']['input']>;
};

/** order by max() on columns of table "feeds" */
export type Feeds_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "feeds" */
export type Feeds_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "feeds". */
export type Feeds_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly comments_votes_aggregate?: InputMaybe<Comments_Votes_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly payload?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
};

/** select columns of table "feeds" */
export enum Feeds_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type'
}

export type Feeds_Set_Input = {
  readonly type?: InputMaybe<Scalars['feed_type']['input']>;
};

/** Streaming cursor of the table "feeds" */
export type Feeds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Feeds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feeds_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "follows" */
export type Follows = {
  readonly __typename?: 'follows';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly follower: Users;
  readonly follower_id: Scalars['String']['output'];
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "follows" */
export type Follows_Aggregate = {
  readonly __typename?: 'follows_aggregate';
  readonly aggregate?: Maybe<Follows_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Follows>;
};

export type Follows_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Follows_Aggregate_Bool_Exp_Count>;
};

export type Follows_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Follows_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "follows" */
export type Follows_Aggregate_Fields = {
  readonly __typename?: 'follows_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Follows_Max_Fields>;
  readonly min?: Maybe<Follows_Min_Fields>;
};


/** aggregate fields of "follows" */
export type Follows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "follows" */
export type Follows_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Follows_Max_Order_By>;
  readonly min?: InputMaybe<Follows_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "follows". All fields are combined with a logical 'AND'. */
export type Follows_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Follows_Bool_Exp>>;
  readonly _not?: InputMaybe<Follows_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Follows_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly follower?: InputMaybe<Users_Bool_Exp>;
  readonly follower_id?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "follows" */
export enum Follows_Constraint {
  /** unique or primary key constraint on columns "user_id", "follower_id" */
  FollowsFollowerIdUserIdKey = 'follows_follower_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  FollowsPkey = 'follows_pkey'
}

/** input type for inserting data into table "follows" */
export type Follows_Insert_Input = {
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Follows_Max_Fields = {
  readonly __typename?: 'follows_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly follower_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "follows" */
export type Follows_Max_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly follower_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Follows_Min_Fields = {
  readonly __typename?: 'follows_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly follower_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "follows" */
export type Follows_Min_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly follower_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "follows" */
export type Follows_Mutation_Response = {
  readonly __typename?: 'follows_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Follows>;
};

/** on_conflict condition type for table "follows" */
export type Follows_On_Conflict = {
  readonly constraint: Follows_Constraint;
  readonly update_columns?: ReadonlyArray<Follows_Update_Column>;
  readonly where?: InputMaybe<Follows_Bool_Exp>;
};

/** Ordering options when selecting data from "follows". */
export type Follows_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly follower?: InputMaybe<Users_Order_By>;
  readonly follower_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: follows */
export type Follows_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "follows" */
export enum Follows_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FollowerId = 'follower_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "follows" */
export type Follows_Set_Input = {
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "follows" */
export type Follows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Follows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Follows_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly follower_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "follows" */
export enum Follows_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at'
}

export type Follows_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Follows_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Follows_Bool_Exp;
};

/** columns and relationships of "friendships" */
export type Friendships = {
  readonly __typename?: 'friendships';
  readonly created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly friendships_users: ReadonlyArray<Friendships_Users>;
  readonly id: Scalars['uuid']['output'];
  readonly initiated_by: Scalars['String']['output'];
  /** An object relationship */
  readonly initiator: Users;
  /** A computed field, executes function "get_friendship_status" */
  readonly status: Friendships_Status;
};


/** columns and relationships of "friendships" */
export type FriendshipsFriendships_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "friendships". All fields are combined with a logical 'AND'. */
export type Friendships_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Friendships_Bool_Exp>>;
  readonly _not?: InputMaybe<Friendships_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Friendships_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly friendships_users?: InputMaybe<Friendships_Users_Bool_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly initiated_by?: InputMaybe<String_Comparison_Exp>;
  readonly initiator?: InputMaybe<Users_Bool_Exp>;
  readonly status?: InputMaybe<Friendships_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "friendships". */
export type Friendships_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly friendships_users_aggregate?: InputMaybe<Friendships_Users_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly initiated_by?: InputMaybe<Order_By>;
  readonly initiator?: InputMaybe<Users_Order_By>;
  readonly status?: InputMaybe<Order_By>;
};

/** select columns of table "friendships" */
export enum Friendships_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InitiatedBy = 'initiated_by'
}

export enum Friendships_Status {
  Active = 'active',
  Pending = 'pending',
  Terminated = 'terminated'
}

export type Friendships_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Friendships_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Friendships_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Friendships_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Friendships_Status>>;
};

/** Streaming cursor of the table "friendships" */
export type Friendships_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Friendships_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Friendships_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly initiated_by?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "friendships_users" */
export type Friendships_Users = {
  readonly __typename?: 'friendships_users';
  readonly created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly friendship: Friendships;
  readonly friendship_id: Scalars['uuid']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly status: Friendships_Users_Status;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** order by aggregate values of table "friendships_users" */
export type Friendships_Users_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Friendships_Users_Max_Order_By>;
  readonly min?: InputMaybe<Friendships_Users_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "friendships_users". All fields are combined with a logical 'AND'. */
export type Friendships_Users_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Friendships_Users_Bool_Exp>>;
  readonly _not?: InputMaybe<Friendships_Users_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Friendships_Users_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly friendship?: InputMaybe<Friendships_Bool_Exp>;
  readonly friendship_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly status?: InputMaybe<Friendships_Users_Status_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "friendships_users" */
export enum Friendships_Users_Constraint {
  /** unique or primary key constraint on columns "user_id", "friendship_id" */
  FriendshipsUsersFriendshipIdUserIdKey = 'friendships_users_friendship_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  FriendshipsUsersPkey = 'friendships_users_pkey'
}

/** input type for inserting data into table "friendships_users" */
export type Friendships_Users_Insert_Input = {
  readonly friendship_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Friendships_Users_Status>;
};

/** order by max() on columns of table "friendships_users" */
export type Friendships_Users_Max_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly friendship_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "friendships_users" */
export type Friendships_Users_Min_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly friendship_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "friendships_users" */
export type Friendships_Users_Mutation_Response = {
  readonly __typename?: 'friendships_users_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Friendships_Users>;
};

/** on_conflict condition type for table "friendships_users" */
export type Friendships_Users_On_Conflict = {
  readonly constraint: Friendships_Users_Constraint;
  readonly update_columns?: ReadonlyArray<Friendships_Users_Update_Column>;
  readonly where?: InputMaybe<Friendships_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "friendships_users". */
export type Friendships_Users_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly friendship?: InputMaybe<Friendships_Order_By>;
  readonly friendship_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: friendships_users */
export type Friendships_Users_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "friendships_users" */
export enum Friendships_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FriendshipId = 'friendship_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "friendships_users" */
export type Friendships_Users_Set_Input = {
  readonly status?: InputMaybe<Friendships_Users_Status>;
};

export enum Friendships_Users_Status {
  Active = 'active',
  Pending = 'pending',
  Terminated = 'terminated'
}

export type Friendships_Users_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Friendships_Users_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Friendships_Users_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Friendships_Users_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Friendships_Users_Status>>;
};

/** Streaming cursor of the table "friendships_users" */
export type Friendships_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Friendships_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Friendships_Users_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly friendship_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "friendships_users" */
export enum Friendships_Users_Update_Column {
  /** column name */
  Status = 'status'
}

export type Friendships_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Friendships_Users_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Friendships_Users_Bool_Exp;
};

export type GenerateCallAccessTokenOutput = {
  readonly __typename?: 'generateCallAccessTokenOutput';
  readonly exp: Scalars['Int']['output'];
  readonly token: Scalars['String']['output'];
};

export type Infrastructure_Operation_Type = {
  readonly _eq?: InputMaybe<Infrastructure_Operations_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Infrastructure_Operations_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Type>>;
};

export type Infrastructure_Operations = {
  readonly __typename?: 'infrastructure_operations';
  readonly type: Infrastructure_Operations_Type;
};

export type Infrastructure_Operations_Bool_Exp = {
  readonly type?: InputMaybe<Infrastructure_Operation_Type>;
};

export type Infrastructure_Operations_Insert_Input = {
  readonly type?: InputMaybe<Infrastructure_Operations_Type>;
};

export type Infrastructure_Operations_Set_Input = {
  readonly type?: InputMaybe<Comment_Object_Type>;
};

export enum Infrastructure_Operations_Type {
  ClearHasuraLogs = 'clear_hasura_logs',
  ClearInfrastructureOperations = 'clear_infrastructure_operations'
}

export enum Item_Status {
  Active = 'active',
  Hidden = 'hidden',
  Review = 'review'
}

export type Item_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Item_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Item_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Item_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Item_Status>>;
};

/** columns and relationships of "items" */
export type Items = {
  readonly __typename?: 'items';
  readonly created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly creator?: Maybe<Users>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly level_required: Scalars['Int']['output'];
  readonly max_purchase?: Maybe<Scalars['Int']['output']>;
  readonly meta: Scalars['jsonb']['output'];
  readonly name: Scalars['String']['output'];
  /** An array relationship */
  readonly orders: ReadonlyArray<Orders>;
  /** An aggregate relationship */
  readonly orders_aggregate: Orders_Aggregate;
  readonly photo: Scalars['String']['output'];
  readonly price: Scalars['numeric']['output'];
  readonly status: Item_Status;
  readonly submission_form_link?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly tags: ReadonlyArray<Items_Tags>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
};


/** columns and relationships of "items" */
export type ItemsMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "items" */
export type ItemsOrdersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsOrders_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsTagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Tags_Order_By>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "items". All fields are combined with a logical 'AND'. */
export type Items_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Items_Bool_Exp>>;
  readonly _not?: InputMaybe<Items_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Items_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly creator?: InputMaybe<Users_Bool_Exp>;
  readonly creator_id?: InputMaybe<String_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly level_required?: InputMaybe<Int_Comparison_Exp>;
  readonly max_purchase?: InputMaybe<Int_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly orders?: InputMaybe<Orders_Bool_Exp>;
  readonly orders_aggregate?: InputMaybe<Orders_Aggregate_Bool_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly price?: InputMaybe<Numeric_Comparison_Exp>;
  readonly status?: InputMaybe<Item_Status_Bool_Exp>;
  readonly submission_form_link?: InputMaybe<String_Comparison_Exp>;
  readonly tags?: InputMaybe<Items_Tags_Bool_Exp>;
  readonly transactions?: InputMaybe<Transactions_Bool_Exp>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "items" */
export enum Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemsPkey = 'items_pkey'
}

/** input type for inserting data into table "items" */
export type Items_Insert_Input = {
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly orders?: InputMaybe<Orders_Arr_Rel_Insert_Input>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly price?: InputMaybe<Scalars['numeric']['input']>;
  readonly purchase_link?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Item_Status>;
};

/** response of any mutation on the table "items" */
export type Items_Mutation_Response = {
  readonly __typename?: 'items_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Items>;
};

/** input type for inserting object relation for remote table "items" */
export type Items_Obj_Rel_Insert_Input = {
  readonly data: Items_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Items_On_Conflict>;
};

/** on_conflict condition type for table "items" */
export type Items_On_Conflict = {
  readonly constraint: Items_Constraint;
  readonly update_columns?: ReadonlyArray<Items_Update_Column>;
  readonly where?: InputMaybe<Items_Bool_Exp>;
};

/** Ordering options when selecting data from "items". */
export type Items_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator?: InputMaybe<Users_Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_required?: InputMaybe<Order_By>;
  readonly max_purchase?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly orders_aggregate?: InputMaybe<Orders_Aggregate_Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly price?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submission_form_link?: InputMaybe<Order_By>;
  readonly tags_aggregate?: InputMaybe<Items_Tags_Aggregate_Order_By>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Order_By>;
};

/** select columns of table "items" */
export enum Items_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LevelRequired = 'level_required',
  /** column name */
  MaxPurchase = 'max_purchase',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  Price = 'price',
  /** column name */
  Status = 'status',
  /** column name */
  SubmissionFormLink = 'submission_form_link'
}

export type Items_Set_Input = {
  readonly status?: InputMaybe<Item_Status>;
};

/** Streaming cursor of the table "items" */
export type Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly level_required?: InputMaybe<Scalars['Int']['input']>;
  readonly max_purchase?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly price?: InputMaybe<Scalars['numeric']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly submission_form_link?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "items_tags" */
export type Items_Tags = {
  readonly __typename?: 'items_tags';
  /** An object relationship */
  readonly item: Items;
  readonly item_id: Scalars['String']['output'];
  /** An object relationship */
  readonly tag: Tags;
  readonly tag_id: Scalars['String']['output'];
};

/** order by aggregate values of table "items_tags" */
export type Items_Tags_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Items_Tags_Max_Order_By>;
  readonly min?: InputMaybe<Items_Tags_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "items_tags". All fields are combined with a logical 'AND'. */
export type Items_Tags_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Items_Tags_Bool_Exp>>;
  readonly _not?: InputMaybe<Items_Tags_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Items_Tags_Bool_Exp>>;
  readonly item?: InputMaybe<Items_Bool_Exp>;
  readonly item_id?: InputMaybe<String_Comparison_Exp>;
  readonly tag?: InputMaybe<Tags_Bool_Exp>;
  readonly tag_id?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "items_tags" */
export type Items_Tags_Max_Order_By = {
  readonly item_id?: InputMaybe<Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "items_tags" */
export type Items_Tags_Min_Order_By = {
  readonly item_id?: InputMaybe<Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "items_tags". */
export type Items_Tags_Order_By = {
  readonly item?: InputMaybe<Items_Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly tag?: InputMaybe<Tags_Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** select columns of table "items_tags" */
export enum Items_Tags_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  TagId = 'tag_id'
}

/** Streaming cursor of the table "items_tags" */
export type Items_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Items_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Tags_Stream_Cursor_Value_Input = {
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "items" (current role has no relevant permissions) */
export enum Items_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

export type Jsonb_Cast_Exp = {
  readonly String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  readonly _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  readonly _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  readonly _contains?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _eq?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _gt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  readonly _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  readonly _has_keys_all?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  readonly _has_keys_any?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['jsonb']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _lte?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _neq?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['jsonb']['input']>>;
};

export type Level_Reward = {
  readonly __typename?: 'level_reward';
  readonly associated_object: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
};

export type Level_Reward_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "levels" */
export type Levels = {
  readonly __typename?: 'levels';
  readonly level: Scalars['Int']['output'];
  readonly rewards: ReadonlyArray<Level_Reward>;
  readonly xp: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "levels". All fields are combined with a logical 'AND'. */
export type Levels_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Levels_Bool_Exp>>;
  readonly _not?: InputMaybe<Levels_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Levels_Bool_Exp>>;
  readonly level?: InputMaybe<Int_Comparison_Exp>;
  readonly xp?: InputMaybe<Int_Comparison_Exp>;
};

export type Levels_Insert_Input = {
  readonly rewards?: InputMaybe<ReadonlyArray<InputMaybe<Level_Reward_Input>>>;
};

/** Ordering options when selecting data from "levels". */
export type Levels_Order_By = {
  readonly level?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** select columns of table "levels" */
export enum Levels_Select_Column {
  /** column name */
  Level = 'level',
  /** column name */
  Xp = 'xp'
}

/** Streaming cursor of the table "levels" */
export type Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Levels_Stream_Cursor_Value_Input = {
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

export enum Live_Event_Recurring {
  Daily = 'daily',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export type Live_Event_Recurring_Bool_Exp = {
  readonly _eq?: InputMaybe<Live_Event_Recurring>;
  readonly _in?: InputMaybe<ReadonlyArray<Live_Event_Recurring>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Live_Event_Recurring>;
  readonly _nin?: InputMaybe<ReadonlyArray<Live_Event_Recurring>>;
};

export enum Live_Event_Status {
  Cancelled = 'cancelled',
  Complete = 'complete',
  Hidden = 'hidden',
  Live = 'live',
  Open = 'open',
  Upcoming = 'upcoming'
}

export type Live_Event_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Live_Event_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Live_Event_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Live_Event_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Live_Event_Status>>;
};

/** columns and relationships of "live_events" */
export type Live_Events = {
  readonly __typename?: 'live_events';
  readonly agenda: Scalars['String']['output'];
  readonly association_id: Scalars['String']['output'];
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly clipboard_prompt?: Maybe<Scalars['clipboard_prompt']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly hosts: ReadonlyArray<Live_Events_Hosts>;
  /** An aggregate relationship */
  readonly hosts_aggregate: Live_Events_Hosts_Aggregate;
  readonly id: Scalars['uuid']['output'];
  readonly members_only: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  /** An object relationship */
  readonly next_event?: Maybe<Live_Events>;
  /** An object relationship */
  readonly previous_event?: Maybe<Live_Events>;
  readonly previous_event_id?: Maybe<Scalars['uuid']['output']>;
  readonly promo_image_url: Scalars['String']['output'];
  readonly recurring: Live_Event_Recurring;
  readonly requires_host: Scalars['Boolean']['output'];
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  readonly schedule?: Maybe<Schedules>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status: Live_Event_Status;
  readonly tagline: Scalars['String']['output'];
  readonly thumbnail_image_url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "live_events" */
export type Live_EventsClipboard_PromptArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "live_events" */
export type Live_EventsHostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


/** columns and relationships of "live_events" */
export type Live_EventsHosts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};

/** aggregated selection of "live_events" */
export type Live_Events_Aggregate = {
  readonly __typename?: 'live_events_aggregate';
  readonly aggregate?: Maybe<Live_Events_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Live_Events>;
};

export type Live_Events_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Live_Events_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Live_Events_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Live_Events_Aggregate_Bool_Exp_Count>;
};

export type Live_Events_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Live_Events_Select_Column_Live_Events_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Live_Events_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Live_Events_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Live_Events_Select_Column_Live_Events_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Live_Events_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Live_Events_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Live_Events_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "live_events" */
export type Live_Events_Aggregate_Fields = {
  readonly __typename?: 'live_events_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Live_Events_Max_Fields>;
  readonly min?: Maybe<Live_Events_Min_Fields>;
};


/** aggregate fields of "live_events" */
export type Live_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "live_events" */
export type Live_Events_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Live_Events_Max_Order_By>;
  readonly min?: InputMaybe<Live_Events_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Live_Events_Append_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "live_events" */
export type Live_Events_Arr_Rel_Insert_Input = {
  readonly data?: InputMaybe<ReadonlyArray<Live_Events_Insert_Input>>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Live_Events_On_Conflict>;
};

/** Boolean expression to filter rows from the table "live_events". All fields are combined with a logical 'AND'. */
export type Live_Events_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Live_Events_Bool_Exp>>;
  readonly _not?: InputMaybe<Live_Events_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Live_Events_Bool_Exp>>;
  readonly agenda?: InputMaybe<String_Comparison_Exp>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly background_image_url?: InputMaybe<String_Comparison_Exp>;
  readonly clipboard_prompt?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly ends_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly feedback_question?: InputMaybe<String_Comparison_Exp>;
  readonly hosts?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
  readonly hosts_aggregate?: InputMaybe<Live_Events_Hosts_Aggregate_Bool_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly members_only?: InputMaybe<Boolean_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly next_event?: InputMaybe<Live_Events_Bool_Exp>;
  readonly previous_event?: InputMaybe<Live_Events_Bool_Exp>;
  readonly previous_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly promo_image_url?: InputMaybe<String_Comparison_Exp>;
  readonly recurring?: InputMaybe<Live_Event_Recurring_Bool_Exp>;
  readonly requires_host?: InputMaybe<Boolean_Comparison_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly room_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly schedule?: InputMaybe<Schedules_Bool_Exp>;
  readonly schedule_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly status?: InputMaybe<Live_Event_Status_Bool_Exp>;
  readonly tagline?: InputMaybe<String_Comparison_Exp>;
  readonly thumbnail_image_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "live_events" */
export enum Live_Events_Constraint {
  /** unique or primary key constraint on columns "association_id" */
  LiveEventsAssociationIdKey = 'live_events_association_id_key',
  /** unique or primary key constraint on columns "id" */
  LiveEventsPkey = 'live_events_pkey',
  /** unique or primary key constraint on columns "previous_event_id" */
  LiveEventsPreviousEventIdKey = 'live_events_previous_event_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Live_Events_Delete_At_Path_Input = {
  readonly clipboard_prompt?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Live_Events_Delete_Elem_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Live_Events_Delete_Key_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "live_events_hosts" */
export type Live_Events_Hosts = {
  readonly __typename?: 'live_events_hosts';
  /** An object relationship */
  readonly live_event: Live_Events;
  readonly live_event_id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "live_events_hosts" */
export type Live_Events_Hosts_Aggregate = {
  readonly __typename?: 'live_events_hosts_aggregate';
  readonly aggregate?: Maybe<Live_Events_Hosts_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Live_Events_Hosts>;
};

export type Live_Events_Hosts_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Live_Events_Hosts_Aggregate_Bool_Exp_Count>;
};

export type Live_Events_Hosts_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "live_events_hosts" */
export type Live_Events_Hosts_Aggregate_Fields = {
  readonly __typename?: 'live_events_hosts_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Live_Events_Hosts_Max_Fields>;
  readonly min?: Maybe<Live_Events_Hosts_Min_Fields>;
};


/** aggregate fields of "live_events_hosts" */
export type Live_Events_Hosts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "live_events_hosts" */
export type Live_Events_Hosts_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Live_Events_Hosts_Max_Order_By>;
  readonly min?: InputMaybe<Live_Events_Hosts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "live_events_hosts" */
export type Live_Events_Hosts_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Live_Events_Hosts_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Live_Events_Hosts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "live_events_hosts". All fields are combined with a logical 'AND'. */
export type Live_Events_Hosts_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Bool_Exp>>;
  readonly _not?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Bool_Exp>>;
  readonly live_event?: InputMaybe<Live_Events_Bool_Exp>;
  readonly live_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "live_events_hosts" */
export enum Live_Events_Hosts_Constraint {
  /** unique or primary key constraint on columns "user_id", "live_event_id" */
  LiveEventsHostsPkey = 'live_events_hosts_pkey'
}

/** input type for inserting data into table "live_events_hosts" */
export type Live_Events_Hosts_Insert_Input = {
  readonly live_event?: InputMaybe<Live_Events_Obj_Rel_Insert_Input>;
  readonly live_event_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Live_Events_Hosts_Max_Fields = {
  readonly __typename?: 'live_events_hosts_max_fields';
  readonly live_event_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "live_events_hosts" */
export type Live_Events_Hosts_Max_Order_By = {
  readonly live_event_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Live_Events_Hosts_Min_Fields = {
  readonly __typename?: 'live_events_hosts_min_fields';
  readonly live_event_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "live_events_hosts" */
export type Live_Events_Hosts_Min_Order_By = {
  readonly live_event_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "live_events_hosts" */
export type Live_Events_Hosts_Mutation_Response = {
  readonly __typename?: 'live_events_hosts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Live_Events_Hosts>;
};

/** on_conflict condition type for table "live_events_hosts" */
export type Live_Events_Hosts_On_Conflict = {
  readonly constraint: Live_Events_Hosts_Constraint;
  readonly update_columns?: ReadonlyArray<Live_Events_Hosts_Update_Column>;
  readonly where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};

/** Ordering options when selecting data from "live_events_hosts". */
export type Live_Events_Hosts_Order_By = {
  readonly live_event?: InputMaybe<Live_Events_Order_By>;
  readonly live_event_id?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** select columns of table "live_events_hosts" */
export enum Live_Events_Hosts_Select_Column {
  /** column name */
  LiveEventId = 'live_event_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "live_events_hosts" */
export type Live_Events_Hosts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Live_Events_Hosts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Live_Events_Hosts_Stream_Cursor_Value_Input = {
  readonly live_event_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "live_events_hosts" (current role has no relevant permissions) */
export enum Live_Events_Hosts_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** input type for inserting data into table "live_events" */
export type Live_Events_Insert_Input = {
  readonly agenda?: InputMaybe<Scalars['String']['input']>;
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly hosts?: InputMaybe<Live_Events_Hosts_Arr_Rel_Insert_Input>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly members_only?: InputMaybe<Scalars['Boolean']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly next_event?: InputMaybe<Live_Events_Obj_Rel_Insert_Input>;
  readonly previous_event?: InputMaybe<Live_Events_Obj_Rel_Insert_Input>;
  readonly promo_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly recurring?: InputMaybe<Live_Event_Recurring>;
  readonly requires_host?: InputMaybe<Scalars['Boolean']['input']>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Live_Event_Status>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly thumbnail_image_url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Live_Events_Max_Fields = {
  readonly __typename?: 'live_events_max_fields';
  readonly agenda?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly previous_event_id?: Maybe<Scalars['uuid']['output']>;
  readonly promo_image_url?: Maybe<Scalars['String']['output']>;
  readonly recurring?: Maybe<Scalars['String']['output']>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly thumbnail_image_url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "live_events" */
export type Live_Events_Max_Order_By = {
  readonly agenda?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly previous_event_id?: InputMaybe<Order_By>;
  readonly promo_image_url?: InputMaybe<Order_By>;
  readonly recurring?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly thumbnail_image_url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Live_Events_Min_Fields = {
  readonly __typename?: 'live_events_min_fields';
  readonly agenda?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly previous_event_id?: Maybe<Scalars['uuid']['output']>;
  readonly promo_image_url?: Maybe<Scalars['String']['output']>;
  readonly recurring?: Maybe<Scalars['String']['output']>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly thumbnail_image_url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "live_events" */
export type Live_Events_Min_Order_By = {
  readonly agenda?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly previous_event_id?: InputMaybe<Order_By>;
  readonly promo_image_url?: InputMaybe<Order_By>;
  readonly recurring?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly thumbnail_image_url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "live_events" */
export type Live_Events_Mutation_Response = {
  readonly __typename?: 'live_events_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Live_Events>;
};

/** input type for inserting object relation for remote table "live_events" */
export type Live_Events_Obj_Rel_Insert_Input = {
  readonly data: Live_Events_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Live_Events_On_Conflict>;
};

/** on_conflict condition type for table "live_events" */
export type Live_Events_On_Conflict = {
  readonly constraint: Live_Events_Constraint;
  readonly update_columns?: ReadonlyArray<Live_Events_Update_Column>;
  readonly where?: InputMaybe<Live_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "live_events". */
export type Live_Events_Order_By = {
  readonly agenda?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly clipboard_prompt?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly hosts_aggregate?: InputMaybe<Live_Events_Hosts_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly members_only?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly next_event?: InputMaybe<Live_Events_Order_By>;
  readonly previous_event?: InputMaybe<Live_Events_Order_By>;
  readonly previous_event_id?: InputMaybe<Order_By>;
  readonly promo_image_url?: InputMaybe<Order_By>;
  readonly recurring?: InputMaybe<Order_By>;
  readonly requires_host?: InputMaybe<Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly schedule?: InputMaybe<Schedules_Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly thumbnail_image_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: live_events */
export type Live_Events_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Live_Events_Prepend_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "live_events" */
export enum Live_Events_Select_Column {
  /** column name */
  Agenda = 'agenda',
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  BackgroundImageUrl = 'background_image_url',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  FeedbackQuestion = 'feedback_question',
  /** column name */
  Id = 'id',
  /** column name */
  MembersOnly = 'members_only',
  /** column name */
  Name = 'name',
  /** column name */
  PreviousEventId = 'previous_event_id',
  /** column name */
  PromoImageUrl = 'promo_image_url',
  /** column name */
  Recurring = 'recurring',
  /** column name */
  RequiresHost = 'requires_host',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  ThumbnailImageUrl = 'thumbnail_image_url'
}

/** select "live_events_aggregate_bool_exp_bool_and_arguments_columns" columns of table "live_events" */
export enum Live_Events_Select_Column_Live_Events_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  MembersOnly = 'members_only',
  /** column name */
  RequiresHost = 'requires_host'
}

/** select "live_events_aggregate_bool_exp_bool_or_arguments_columns" columns of table "live_events" */
export enum Live_Events_Select_Column_Live_Events_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  MembersOnly = 'members_only',
  /** column name */
  RequiresHost = 'requires_host'
}

/** input type for updating data in table "live_events" */
export type Live_Events_Set_Input = {
  readonly agenda?: InputMaybe<Scalars['String']['input']>;
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly members_only?: InputMaybe<Scalars['Boolean']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly promo_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly recurring?: InputMaybe<Live_Event_Recurring>;
  readonly requires_host?: InputMaybe<Scalars['Boolean']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Live_Event_Status>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly thumbnail_image_url?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "live_events" */
export type Live_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Live_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Live_Events_Stream_Cursor_Value_Input = {
  readonly agenda?: InputMaybe<Scalars['String']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly members_only?: InputMaybe<Scalars['Boolean']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly previous_event_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly promo_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly recurring?: InputMaybe<Scalars['String']['input']>;
  readonly requires_host?: InputMaybe<Scalars['Boolean']['input']>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly thumbnail_image_url?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "live_events" */
export enum Live_Events_Update_Column {
  /** column name */
  Agenda = 'agenda',
  /** column name */
  BackgroundImageUrl = 'background_image_url',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  FeedbackQuestion = 'feedback_question',
  /** column name */
  MembersOnly = 'members_only',
  /** column name */
  Name = 'name',
  /** column name */
  PromoImageUrl = 'promo_image_url',
  /** column name */
  Recurring = 'recurring',
  /** column name */
  RequiresHost = 'requires_host',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  ThumbnailImageUrl = 'thumbnail_image_url'
}

export type Live_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Live_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Live_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Live_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Live_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Live_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Live_Events_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Live_Events_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  readonly __typename?: 'mutation_root';
  readonly acceptTeamInvite?: Maybe<AcceptTeamInviteOutput>;
  readonly banChannelUser?: Maybe<BanChannelUserOutput>;
  readonly bookOnboarding?: Maybe<BookOnboardingOutput>;
  /** execute VOLATILE function "comments_delete" which returns "comments" */
  readonly comments_delete?: Maybe<Comments>;
  /** execute VOLATILE function "complete_clubs_members_onboarding_requirement" which returns "clubs_members" */
  readonly complete_clubs_members_onboarding_requirement?: Maybe<ReadonlyArray<Clubs_Members>>;
  /** delete data from the table: "accounts" */
  readonly delete_accounts?: Maybe<Accounts_Mutation_Response>;
  /** delete single row from the table: "accounts" */
  readonly delete_accounts_by_pk?: Maybe<Accounts>;
  /** delete data from the table: "breakouts" */
  readonly delete_breakouts?: Maybe<Breakouts_Mutation_Response>;
  /** delete single row from the table: "breakouts" */
  readonly delete_breakouts_by_pk?: Maybe<Breakouts>;
  /** delete data from the table: "channels" */
  readonly delete_channels?: Maybe<Channels_Mutation_Response>;
  /** delete single row from the table: "channels" */
  readonly delete_channels_by_pk?: Maybe<Channels>;
  /** delete data from the table: "chats" */
  readonly delete_chats?: Maybe<Chats_Mutation_Response>;
  /** delete single row from the table: "chats" */
  readonly delete_chats_by_pk?: Maybe<Chats>;
  /** delete data from the table: "clubs" */
  readonly delete_clubs?: Maybe<Clubs_Mutation_Response>;
  /** delete single row from the table: "clubs" */
  readonly delete_clubs_by_pk?: Maybe<Clubs>;
  /** delete data from the table: "comments_votes" */
  readonly delete_comments_votes?: Maybe<Comments_Votes_Mutation_Response>;
  /** delete single row from the table: "comments_votes" */
  readonly delete_comments_votes_by_pk?: Maybe<Comments_Votes>;
  /** delete data from the table: "competitions" */
  readonly delete_competitions?: Maybe<Competitions_Mutation_Response>;
  /** delete single row from the table: "competitions" */
  readonly delete_competitions_by_pk?: Maybe<Competitions>;
  /** delete data from the table: "live_events" */
  readonly delete_live_events?: Maybe<Live_Events_Mutation_Response>;
  /** delete single row from the table: "live_events" */
  readonly delete_live_events_by_pk?: Maybe<Live_Events>;
  /** delete data from the table: "live_events_hosts" */
  readonly delete_live_events_hosts?: Maybe<Live_Events_Hosts_Mutation_Response>;
  /** delete single row from the table: "live_events_hosts" */
  readonly delete_live_events_hosts_by_pk?: Maybe<Live_Events_Hosts>;
  /** delete data from the table: "projects" */
  readonly delete_projects?: Maybe<Projects_Mutation_Response>;
  /** delete single row from the table: "projects" */
  readonly delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "projects_collaborators" */
  readonly delete_projects_collaborators?: Maybe<Projects_Collaborators_Mutation_Response>;
  /** delete single row from the table: "projects_collaborators" */
  readonly delete_projects_collaborators_by_pk?: Maybe<Projects_Collaborators>;
  /** delete data from the table: "rooms" */
  readonly delete_rooms?: Maybe<Rooms_Mutation_Response>;
  /** delete single row from the table: "rooms" */
  readonly delete_rooms_by_pk?: Maybe<Rooms>;
  /** delete data from the table: "votes" */
  readonly delete_votes?: Maybe<Votes_Mutation_Response>;
  /** delete single row from the table: "votes" */
  readonly delete_votes_by_pk?: Maybe<Votes>;
  /** execute VOLATILE function "feeds_delete" which returns "feeds" */
  readonly feeds_delete?: Maybe<Feeds>;
  readonly generateCallAccessToken?: Maybe<GenerateCallAccessTokenOutput>;
  /** insert data into the table: "breakouts" */
  readonly insert_breakouts?: Maybe<Breakouts_Mutation_Response>;
  /** insert a single row into the table: "breakouts" */
  readonly insert_breakouts_one?: Maybe<Breakouts>;
  /** insert data into the table: "channels_events" */
  readonly insert_channels_events?: Maybe<Channels_Events_Mutation_Response>;
  /** insert a single row into the table: "channels_events" */
  readonly insert_channels_events_one?: Maybe<Channels_Events>;
  /** insert data into the table: "channels_user" */
  readonly insert_channels_user?: Maybe<Channels_User_Mutation_Response>;
  /** insert a single row into the table: "channels_user" */
  readonly insert_channels_user_one?: Maybe<Channels_User>;
  /** insert data into the table: "clubs" */
  readonly insert_clubs?: Maybe<Clubs_Mutation_Response>;
  /** insert a single row into the table: "clubs" */
  readonly insert_clubs_one?: Maybe<Clubs>;
  /** insert data into the table: "comments" */
  readonly insert_comments?: Maybe<Comments_Mutation_Response>;
  /** insert a single row into the table: "comments" */
  readonly insert_comments_one?: Maybe<Comments>;
  /** insert data into the table: "comments_votes" */
  readonly insert_comments_votes?: Maybe<Comments_Votes_Mutation_Response>;
  /** insert a single row into the table: "comments_votes" */
  readonly insert_comments_votes_one?: Maybe<Comments_Votes>;
  /** insert data into the table: "competitions" */
  readonly insert_competitions?: Maybe<Competitions_Mutation_Response>;
  /** insert a single row into the table: "competitions" */
  readonly insert_competitions_one?: Maybe<Competitions>;
  /** insert data into the table: "feedback" */
  readonly insert_feedback?: Maybe<Feedback_Mutation_Response>;
  /** insert a single row into the table: "feedback" */
  readonly insert_feedback_one?: Maybe<Feedback>;
  /** insert data into the table: "follows" */
  readonly insert_follows?: Maybe<Follows_Mutation_Response>;
  /** insert a single row into the table: "follows" */
  readonly insert_follows_one?: Maybe<Follows>;
  /** insert data into the table: "friendships_users" */
  readonly insert_friendships_users?: Maybe<Friendships_Users_Mutation_Response>;
  /** insert a single row into the table: "friendships_users" */
  readonly insert_friendships_users_one?: Maybe<Friendships_Users>;
  /** insert data into the table: "items" */
  readonly insert_items?: Maybe<Items_Mutation_Response>;
  /** insert a single row into the table: "items" */
  readonly insert_items_one?: Maybe<Items>;
  /** insert data into the table: "live_events" */
  readonly insert_live_events?: Maybe<Live_Events_Mutation_Response>;
  /** insert data into the table: "live_events_hosts" */
  readonly insert_live_events_hosts?: Maybe<Live_Events_Hosts_Mutation_Response>;
  /** insert a single row into the table: "live_events_hosts" */
  readonly insert_live_events_hosts_one?: Maybe<Live_Events_Hosts>;
  /** insert a single row into the table: "live_events" */
  readonly insert_live_events_one?: Maybe<Live_Events>;
  /** insert data into the table: "orders" */
  readonly insert_orders?: Maybe<Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  readonly insert_orders_one?: Maybe<Orders>;
  /** insert data into the table: "pages" */
  readonly insert_pages?: Maybe<Pages_Mutation_Response>;
  /** insert a single row into the table: "pages" */
  readonly insert_pages_one?: Maybe<Pages>;
  /** insert data into the table: "posts" */
  readonly insert_posts?: Maybe<Posts_Mutation_Response>;
  /** insert a single row into the table: "posts" */
  readonly insert_posts_one?: Maybe<Posts>;
  /** insert data into the table: "projects" */
  readonly insert_projects?: Maybe<Projects_Mutation_Response>;
  /** insert data into the table: "projects_collaborators" */
  readonly insert_projects_collaborators?: Maybe<Projects_Collaborators_Mutation_Response>;
  /** insert a single row into the table: "projects_collaborators" */
  readonly insert_projects_collaborators_one?: Maybe<Projects_Collaborators>;
  /** insert a single row into the table: "projects" */
  readonly insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "reports" */
  readonly insert_reports?: Maybe<Reports_Mutation_Response>;
  /** insert a single row into the table: "reports" */
  readonly insert_reports_one?: Maybe<Reports>;
  /** insert data into the table: "rooms" */
  readonly insert_rooms?: Maybe<Rooms_Mutation_Response>;
  /** insert a single row into the table: "rooms" */
  readonly insert_rooms_one?: Maybe<Rooms>;
  /** insert data into the table: "tags_associations" */
  readonly insert_tags_associations?: Maybe<Tags_Associations_Mutation_Response>;
  /** insert a single row into the table: "tags_associations" */
  readonly insert_tags_associations_one?: Maybe<Tags_Associations>;
  /** insert data into the table: "user_invites" */
  readonly insert_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** insert a single row into the table: "user_invites" */
  readonly insert_user_invites_one?: Maybe<User_Invites>;
  /** insert data into the table: "user_presence" */
  readonly insert_user_presence?: Maybe<User_Presence_Mutation_Response>;
  /** insert a single row into the table: "user_presence" */
  readonly insert_user_presence_one?: Maybe<User_Presence>;
  /** insert data into the table: "users_actions" */
  readonly insert_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** insert a single row into the table: "users_actions" */
  readonly insert_users_actions_one?: Maybe<Users_Actions>;
  /** insert data into the table: "users_notifications" */
  readonly insert_users_notifications?: Maybe<Users_Notifications_Mutation_Response>;
  /** insert a single row into the table: "users_notifications" */
  readonly insert_users_notifications_one?: Maybe<Users_Notifications>;
  /** insert data into the table: "votes" */
  readonly insert_votes?: Maybe<Votes_Mutation_Response>;
  /** insert a single row into the table: "votes" */
  readonly insert_votes_one?: Maybe<Votes>;
  readonly joinBreakout: JoinBreakoutOutput;
  readonly joinCompetition?: Maybe<JoinCompetitionOutput>;
  readonly leaveCompetition?: Maybe<LeaveCompetitionOutput>;
  /** execute VOLATILE function "posts_delete" which returns "posts" */
  readonly posts_delete?: Maybe<Posts>;
  readonly publishToMinecraft?: Maybe<PublishToMinecraftOutput>;
  /** sendFriendRequest */
  readonly sendFriendRequest?: Maybe<SendFriendRequestOutput>;
  readonly sendPasswordReset?: Maybe<SendPasswordResetOutput>;
  readonly submitChatMessage?: Maybe<SubmitChatMessageOutput>;
  /** toggleRoomHostId */
  readonly toggleRoomHostId: ToggleRoomHostIdOutput;
  readonly unlinkDiscord?: Maybe<UnlinkDiscordOutput>;
  readonly unlinkXbox?: Maybe<UnlinkXboxOutput>;
  readonly updateChannelMuteEnabled?: Maybe<UpdateChannelMuteEnabledOutput>;
  readonly updateChannelUserPermissions?: Maybe<UpdateChannelUserPermissionsOutput>;
  /** updateChannelsUserForceMuted */
  readonly updateChannelsUserForceMuted?: Maybe<UpdateChannelsUserForceMutedOutput>;
  readonly updateHandRaised?: Maybe<UpdateHandRaisedOutput>;
  readonly updatePassword?: Maybe<UpdatePasswordOutput>;
  /** Adds a user's club preferences to their meta */
  readonly updateUserClubPreferences?: Maybe<UpdateUserClubPreferencesOutput>;
  /** update data of the table: "breakouts" */
  readonly update_breakouts?: Maybe<Breakouts_Mutation_Response>;
  /** update single row of the table: "breakouts" */
  readonly update_breakouts_by_pk?: Maybe<Breakouts>;
  /** update multiples rows of table: "breakouts" */
  readonly update_breakouts_many?: Maybe<ReadonlyArray<Maybe<Breakouts_Mutation_Response>>>;
  /** update data of the table: "channels" */
  readonly update_channels?: Maybe<Channels_Mutation_Response>;
  /** update single row of the table: "channels" */
  readonly update_channels_by_pk?: Maybe<Channels>;
  /** update multiples rows of table: "channels" */
  readonly update_channels_many?: Maybe<ReadonlyArray<Maybe<Channels_Mutation_Response>>>;
  /** update data of the table: "channels_user" */
  readonly update_channels_user?: Maybe<Channels_User_Mutation_Response>;
  /** update single row of the table: "channels_user" */
  readonly update_channels_user_by_pk?: Maybe<Channels_User>;
  /** update multiples rows of table: "channels_user" */
  readonly update_channels_user_many?: Maybe<ReadonlyArray<Maybe<Channels_User_Mutation_Response>>>;
  /** update data of the table: "clubs" */
  readonly update_clubs?: Maybe<Clubs_Mutation_Response>;
  /** update single row of the table: "clubs" */
  readonly update_clubs_by_pk?: Maybe<Clubs>;
  /** update multiples rows of table: "clubs" */
  readonly update_clubs_many?: Maybe<ReadonlyArray<Maybe<Clubs_Mutation_Response>>>;
  /** update data of the table: "clubs_members" */
  readonly update_clubs_members?: Maybe<Clubs_Members_Mutation_Response>;
  /** update single row of the table: "clubs_members" */
  readonly update_clubs_members_by_pk?: Maybe<Clubs_Members>;
  /** update multiples rows of table: "clubs_members" */
  readonly update_clubs_members_many?: Maybe<ReadonlyArray<Maybe<Clubs_Members_Mutation_Response>>>;
  /** update data of the table: "comments" */
  readonly update_comments?: Maybe<Comments_Mutation_Response>;
  /** update single row of the table: "comments" */
  readonly update_comments_by_pk?: Maybe<Comments>;
  /** update multiples rows of table: "comments" */
  readonly update_comments_many?: Maybe<ReadonlyArray<Maybe<Comments_Mutation_Response>>>;
  /** update data of the table: "competitions" */
  readonly update_competitions?: Maybe<Competitions_Mutation_Response>;
  /** update single row of the table: "competitions" */
  readonly update_competitions_by_pk?: Maybe<Competitions>;
  /** update multiples rows of table: "competitions" */
  readonly update_competitions_many?: Maybe<ReadonlyArray<Maybe<Competitions_Mutation_Response>>>;
  /** update data of the table: "follows" */
  readonly update_follows?: Maybe<Follows_Mutation_Response>;
  /** update single row of the table: "follows" */
  readonly update_follows_by_pk?: Maybe<Follows>;
  /** update multiples rows of table: "follows" */
  readonly update_follows_many?: Maybe<ReadonlyArray<Maybe<Follows_Mutation_Response>>>;
  /** update data of the table: "friendships_users" */
  readonly update_friendships_users?: Maybe<Friendships_Users_Mutation_Response>;
  /** update single row of the table: "friendships_users" */
  readonly update_friendships_users_by_pk?: Maybe<Friendships_Users>;
  /** update multiples rows of table: "friendships_users" */
  readonly update_friendships_users_many?: Maybe<ReadonlyArray<Maybe<Friendships_Users_Mutation_Response>>>;
  /** update data of the table: "live_events" */
  readonly update_live_events?: Maybe<Live_Events_Mutation_Response>;
  /** update single row of the table: "live_events" */
  readonly update_live_events_by_pk?: Maybe<Live_Events>;
  /** update multiples rows of table: "live_events" */
  readonly update_live_events_many?: Maybe<ReadonlyArray<Maybe<Live_Events_Mutation_Response>>>;
  /** update data of the table: "pages" */
  readonly update_pages?: Maybe<Pages_Mutation_Response>;
  /** update single row of the table: "pages" */
  readonly update_pages_by_pk?: Maybe<Pages>;
  /** update multiples rows of table: "pages" */
  readonly update_pages_many?: Maybe<ReadonlyArray<Maybe<Pages_Mutation_Response>>>;
  /** update data of the table: "posts" */
  readonly update_posts?: Maybe<Posts_Mutation_Response>;
  /** update single row of the table: "posts" */
  readonly update_posts_by_pk?: Maybe<Posts>;
  /** update multiples rows of table: "posts" */
  readonly update_posts_many?: Maybe<ReadonlyArray<Maybe<Posts_Mutation_Response>>>;
  /** update data of the table: "projects" */
  readonly update_projects?: Maybe<Projects_Mutation_Response>;
  /** update single row of the table: "projects" */
  readonly update_projects_by_pk?: Maybe<Projects>;
  /** update data of the table: "projects_collaborators" */
  readonly update_projects_collaborators?: Maybe<Projects_Collaborators_Mutation_Response>;
  /** update single row of the table: "projects_collaborators" */
  readonly update_projects_collaborators_by_pk?: Maybe<Projects_Collaborators>;
  /** update multiples rows of table: "projects_collaborators" */
  readonly update_projects_collaborators_many?: Maybe<ReadonlyArray<Maybe<Projects_Collaborators_Mutation_Response>>>;
  /** update data of the table: "projects_editables" */
  readonly update_projects_editables?: Maybe<Projects_Editables_Mutation_Response>;
  /** update multiples rows of table: "projects_editables" */
  readonly update_projects_editables_many?: Maybe<ReadonlyArray<Maybe<Projects_Editables_Mutation_Response>>>;
  /** update multiples rows of table: "projects" */
  readonly update_projects_many?: Maybe<ReadonlyArray<Maybe<Projects_Mutation_Response>>>;
  /** update data of the table: "rooms" */
  readonly update_rooms?: Maybe<Rooms_Mutation_Response>;
  /** update single row of the table: "rooms" */
  readonly update_rooms_by_pk?: Maybe<Rooms>;
  /** update multiples rows of table: "rooms" */
  readonly update_rooms_many?: Maybe<ReadonlyArray<Maybe<Rooms_Mutation_Response>>>;
  /** update data of the table: "user_invites" */
  readonly update_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** update single row of the table: "user_invites" */
  readonly update_user_invites_by_pk?: Maybe<User_Invites>;
  /** update multiples rows of table: "user_invites" */
  readonly update_user_invites_many?: Maybe<ReadonlyArray<Maybe<User_Invites_Mutation_Response>>>;
  /** update data of the table: "user_presence" */
  readonly update_user_presence?: Maybe<User_Presence_Mutation_Response>;
  /** update single row of the table: "user_presence" */
  readonly update_user_presence_by_pk?: Maybe<User_Presence>;
  /** update multiples rows of table: "user_presence" */
  readonly update_user_presence_many?: Maybe<ReadonlyArray<Maybe<User_Presence_Mutation_Response>>>;
  /** update data of the table: "users" */
  readonly update_users?: Maybe<Users_Mutation_Response>;
  /** update data of the table: "users_actions" */
  readonly update_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** update single row of the table: "users_actions" */
  readonly update_users_actions_by_pk?: Maybe<Users_Actions>;
  /** update multiples rows of table: "users_actions" */
  readonly update_users_actions_many?: Maybe<ReadonlyArray<Maybe<Users_Actions_Mutation_Response>>>;
  /** update single row of the table: "users" */
  readonly update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  readonly update_users_many?: Maybe<ReadonlyArray<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "users_notifications" */
  readonly update_users_notifications?: Maybe<Users_Notifications_Mutation_Response>;
  /** update single row of the table: "users_notifications" */
  readonly update_users_notifications_by_pk?: Maybe<Users_Notifications>;
  /** update multiples rows of table: "users_notifications" */
  readonly update_users_notifications_many?: Maybe<ReadonlyArray<Maybe<Users_Notifications_Mutation_Response>>>;
  /** update data of the table: "users_quests" */
  readonly update_users_quests?: Maybe<Users_Quests_Mutation_Response>;
  /** update single row of the table: "users_quests" */
  readonly update_users_quests_by_pk?: Maybe<Users_Quests>;
  /** update multiples rows of table: "users_quests" */
  readonly update_users_quests_many?: Maybe<ReadonlyArray<Maybe<Users_Quests_Mutation_Response>>>;
  readonly upsertClub?: Maybe<UpsertClubOutput>;
  /** Inserts or updates a room. */
  readonly upsertRoom?: Maybe<UpsertRoomOutput>;
  readonly upsertSchedule?: Maybe<UpsertScheduleOutput>;
};


/** mutation root */
export type Mutation_RootAcceptTeamInviteArgs = {
  channelId: Scalars['uuid']['input'];
  inviterId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootBanChannelUserArgs = {
  channelId: Scalars['uuid']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootBookOnboardingArgs = {
  clubId: Scalars['uuid']['input'];
  date: Scalars['timestamptz']['input'];
  platform: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootComments_DeleteArgs = {
  args: Comments_Delete_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootComplete_Clubs_Members_Onboarding_RequirementArgs = {
  args: Complete_Clubs_Members_Onboarding_Requirement_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDelete_AccountsArgs = {
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BreakoutsArgs = {
  where: Breakouts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Breakouts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ChannelsArgs = {
  where: Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ChatsArgs = {
  where: Chats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chats_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ClubsArgs = {
  where: Clubs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clubs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Comments_VotesArgs = {
  where: Comments_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comments_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CompetitionsArgs = {
  where: Competitions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Competitions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Live_EventsArgs = {
  where: Live_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Live_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Live_Events_HostsArgs = {
  where: Live_Events_Hosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Live_Events_Hosts_By_PkArgs = {
  live_event_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectsArgs = {
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Projects_CollaboratorsArgs = {
  where: Projects_Collaborators_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_Collaborators_By_PkArgs = {
  collaborator_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RoomsArgs = {
  where: Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rooms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_VotesArgs = {
  where: Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootFeeds_DeleteArgs = {
  args: Feeds_Delete_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootGenerateCallAccessTokenArgs = {
  channelId: Scalars['uuid']['input'];
  roomId: Scalars['uuid']['input'];
  startAudioOff?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootInsert_BreakoutsArgs = {
  objects: ReadonlyArray<Breakouts_Insert_Input>;
  on_conflict?: InputMaybe<Breakouts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Breakouts_OneArgs = {
  object: Breakouts_Insert_Input;
  on_conflict?: InputMaybe<Breakouts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_EventsArgs = {
  objects: ReadonlyArray<Channels_Events_Insert_Input>;
  on_conflict?: InputMaybe<Channels_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_Events_OneArgs = {
  object: Channels_Events_Insert_Input;
  on_conflict?: InputMaybe<Channels_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_UserArgs = {
  objects: ReadonlyArray<Channels_User_Insert_Input>;
  on_conflict?: InputMaybe<Channels_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_User_OneArgs = {
  object: Channels_User_Insert_Input;
  on_conflict?: InputMaybe<Channels_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClubsArgs = {
  objects: ReadonlyArray<Clubs_Insert_Input>;
  on_conflict?: InputMaybe<Clubs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clubs_OneArgs = {
  object: Clubs_Insert_Input;
  on_conflict?: InputMaybe<Clubs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommentsArgs = {
  objects: ReadonlyArray<Comments_Insert_Input>;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comments_OneArgs = {
  object: Comments_Insert_Input;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comments_VotesArgs = {
  objects: ReadonlyArray<Comments_Votes_Insert_Input>;
  on_conflict?: InputMaybe<Comments_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comments_Votes_OneArgs = {
  object: Comments_Votes_Insert_Input;
  on_conflict?: InputMaybe<Comments_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompetitionsArgs = {
  objects: ReadonlyArray<Competitions_Insert_Input>;
  on_conflict?: InputMaybe<Competitions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Competitions_OneArgs = {
  object: Competitions_Insert_Input;
  on_conflict?: InputMaybe<Competitions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackArgs = {
  objects: ReadonlyArray<Feedback_Insert_Input>;
  on_conflict?: InputMaybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feedback_OneArgs = {
  object: Feedback_Insert_Input;
  on_conflict?: InputMaybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FollowsArgs = {
  objects: ReadonlyArray<Follows_Insert_Input>;
  on_conflict?: InputMaybe<Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Follows_OneArgs = {
  object: Follows_Insert_Input;
  on_conflict?: InputMaybe<Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friendships_UsersArgs = {
  objects: ReadonlyArray<Friendships_Users_Insert_Input>;
  on_conflict?: InputMaybe<Friendships_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friendships_Users_OneArgs = {
  object: Friendships_Users_Insert_Input;
  on_conflict?: InputMaybe<Friendships_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ItemsArgs = {
  objects: ReadonlyArray<Items_Insert_Input>;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_OneArgs = {
  object: Items_Insert_Input;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Live_EventsArgs = {
  objects: ReadonlyArray<Live_Events_Insert_Input>;
  on_conflict?: InputMaybe<Live_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Live_Events_HostsArgs = {
  objects: ReadonlyArray<Live_Events_Hosts_Insert_Input>;
  on_conflict?: InputMaybe<Live_Events_Hosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Live_Events_Hosts_OneArgs = {
  object: Live_Events_Hosts_Insert_Input;
  on_conflict?: InputMaybe<Live_Events_Hosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Live_Events_OneArgs = {
  object: Live_Events_Insert_Input;
  on_conflict?: InputMaybe<Live_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrdersArgs = {
  objects: ReadonlyArray<Orders_Insert_Input>;
  on_conflict?: InputMaybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_OneArgs = {
  object: Orders_Insert_Input;
  on_conflict?: InputMaybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PagesArgs = {
  objects: ReadonlyArray<Pages_Insert_Input>;
  on_conflict?: InputMaybe<Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pages_OneArgs = {
  object: Pages_Insert_Input;
  on_conflict?: InputMaybe<Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PostsArgs = {
  objects: ReadonlyArray<Posts_Insert_Input>;
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Posts_OneArgs = {
  object: Posts_Insert_Input;
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectsArgs = {
  objects: ReadonlyArray<Projects_Insert_Input>;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_CollaboratorsArgs = {
  objects: ReadonlyArray<Projects_Collaborators_Insert_Input>;
  on_conflict?: InputMaybe<Projects_Collaborators_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_Collaborators_OneArgs = {
  object: Projects_Collaborators_Insert_Input;
  on_conflict?: InputMaybe<Projects_Collaborators_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_OneArgs = {
  object: Projects_Insert_Input;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportsArgs = {
  objects: ReadonlyArray<Reports_Insert_Input>;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reports_OneArgs = {
  object: Reports_Insert_Input;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoomsArgs = {
  objects: ReadonlyArray<Rooms_Insert_Input>;
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rooms_OneArgs = {
  object: Rooms_Insert_Input;
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_AssociationsArgs = {
  objects: ReadonlyArray<Tags_Associations_Insert_Input>;
  on_conflict?: InputMaybe<Tags_Associations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_Associations_OneArgs = {
  object: Tags_Associations_Insert_Input;
  on_conflict?: InputMaybe<Tags_Associations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InvitesArgs = {
  objects: ReadonlyArray<User_Invites_Insert_Input>;
  on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Invites_OneArgs = {
  object: User_Invites_Insert_Input;
  on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_PresenceArgs = {
  objects: ReadonlyArray<User_Presence_Insert_Input>;
  on_conflict?: InputMaybe<User_Presence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Presence_OneArgs = {
  object: User_Presence_Insert_Input;
  on_conflict?: InputMaybe<User_Presence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_ActionsArgs = {
  objects: ReadonlyArray<Users_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Users_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Actions_OneArgs = {
  object: Users_Actions_Insert_Input;
  on_conflict?: InputMaybe<Users_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_NotificationsArgs = {
  objects: ReadonlyArray<Users_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Users_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Notifications_OneArgs = {
  object: Users_Notifications_Insert_Input;
  on_conflict?: InputMaybe<Users_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VotesArgs = {
  objects: ReadonlyArray<Votes_Insert_Input>;
  on_conflict?: InputMaybe<Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Votes_OneArgs = {
  object: Votes_Insert_Input;
  on_conflict?: InputMaybe<Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootJoinBreakoutArgs = {
  channelId: Scalars['uuid']['input'];
  clubId: Scalars['uuid']['input'];
  joinCall?: InputMaybe<Scalars['Boolean']['input']>;
  roomId: Scalars['uuid']['input'];
  startAudioOff?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootJoinCompetitionArgs = {
  associated_competition_id: Scalars['String']['input'];
  matchmaking?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootLeaveCompetitionArgs = {
  channel_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootPosts_DeleteArgs = {
  args: Posts_Delete_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootPublishToMinecraftArgs = {
  channel: Scalars['String']['input'];
  payload: Scalars['jsonb']['input'];
};


/** mutation root */
export type Mutation_RootSendFriendRequestArgs = {
  recipient_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSendPasswordResetArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSubmitChatMessageArgs = {
  channelId: Scalars['uuid']['input'];
  id: Scalars['uuid']['input'];
  message: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootToggleRoomHostIdArgs = {
  roomId: Scalars['uuid']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUnlinkDiscordArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUnlinkXboxArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateChannelMuteEnabledArgs = {
  args: UpdateChannelMuteEnabledInput;
};


/** mutation root */
export type Mutation_RootUpdateChannelUserPermissionsArgs = {
  handRaised?: InputMaybe<Scalars['Boolean']['input']>;
  microphone?: InputMaybe<Scalars['Boolean']['input']>;
  screenShare?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
  volume?: InputMaybe<Scalars['Int']['input']>;
  webCam?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateChannelsUserForceMutedArgs = {
  channelId: Scalars['uuid']['input'];
  forceMuted: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateHandRaisedArgs = {
  channelId: Scalars['uuid']['input'];
  force?: InputMaybe<Scalars['Boolean']['input']>;
  handRaised: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdatePasswordArgs = {
  password: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateUserClubPreferencesArgs = {
  clubId: Scalars['uuid']['input'];
  device?: InputMaybe<Scalars['String']['input']>;
  gameMode?: InputMaybe<Scalars['String']['input']>;
  orientationDate?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdate_BreakoutsArgs = {
  _append?: InputMaybe<Breakouts_Append_Input>;
  _delete_at_path?: InputMaybe<Breakouts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Breakouts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Breakouts_Delete_Key_Input>;
  _prepend?: InputMaybe<Breakouts_Prepend_Input>;
  _set?: InputMaybe<Breakouts_Set_Input>;
  where: Breakouts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Breakouts_By_PkArgs = {
  _append?: InputMaybe<Breakouts_Append_Input>;
  _delete_at_path?: InputMaybe<Breakouts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Breakouts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Breakouts_Delete_Key_Input>;
  _prepend?: InputMaybe<Breakouts_Prepend_Input>;
  _set?: InputMaybe<Breakouts_Set_Input>;
  pk_columns: Breakouts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Breakouts_ManyArgs = {
  updates: ReadonlyArray<Breakouts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ChannelsArgs = {
  _set?: InputMaybe<Channels_Set_Input>;
  where: Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_By_PkArgs = {
  _set?: InputMaybe<Channels_Set_Input>;
  pk_columns: Channels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_ManyArgs = {
  updates: ReadonlyArray<Channels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_UserArgs = {
  _inc?: InputMaybe<Channels_User_Inc_Input>;
  _set?: InputMaybe<Channels_User_Set_Input>;
  where: Channels_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_User_By_PkArgs = {
  _inc?: InputMaybe<Channels_User_Inc_Input>;
  _set?: InputMaybe<Channels_User_Set_Input>;
  pk_columns: Channels_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_User_ManyArgs = {
  updates: ReadonlyArray<Channels_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ClubsArgs = {
  _append?: InputMaybe<Clubs_Append_Input>;
  _delete_at_path?: InputMaybe<Clubs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Clubs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Clubs_Delete_Key_Input>;
  _prepend?: InputMaybe<Clubs_Prepend_Input>;
  _set?: InputMaybe<Clubs_Set_Input>;
  where: Clubs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clubs_By_PkArgs = {
  _append?: InputMaybe<Clubs_Append_Input>;
  _delete_at_path?: InputMaybe<Clubs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Clubs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Clubs_Delete_Key_Input>;
  _prepend?: InputMaybe<Clubs_Prepend_Input>;
  _set?: InputMaybe<Clubs_Set_Input>;
  pk_columns: Clubs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clubs_ManyArgs = {
  updates: ReadonlyArray<Clubs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Clubs_MembersArgs = {
  _set?: InputMaybe<Clubs_Members_Set_Input>;
  where: Clubs_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clubs_Members_By_PkArgs = {
  _set?: InputMaybe<Clubs_Members_Set_Input>;
  pk_columns: Clubs_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clubs_Members_ManyArgs = {
  updates: ReadonlyArray<Clubs_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommentsArgs = {
  _set?: InputMaybe<Comments_Set_Input>;
  where: Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_By_PkArgs = {
  _set?: InputMaybe<Comments_Set_Input>;
  pk_columns: Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_ManyArgs = {
  updates: ReadonlyArray<Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompetitionsArgs = {
  _append?: InputMaybe<Competitions_Append_Input>;
  _delete_at_path?: InputMaybe<Competitions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Competitions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Competitions_Delete_Key_Input>;
  _inc?: InputMaybe<Competitions_Inc_Input>;
  _prepend?: InputMaybe<Competitions_Prepend_Input>;
  _set?: InputMaybe<Competitions_Set_Input>;
  where: Competitions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_By_PkArgs = {
  _append?: InputMaybe<Competitions_Append_Input>;
  _delete_at_path?: InputMaybe<Competitions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Competitions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Competitions_Delete_Key_Input>;
  _inc?: InputMaybe<Competitions_Inc_Input>;
  _prepend?: InputMaybe<Competitions_Prepend_Input>;
  _set?: InputMaybe<Competitions_Set_Input>;
  pk_columns: Competitions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_ManyArgs = {
  updates: ReadonlyArray<Competitions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FollowsArgs = {
  _set?: InputMaybe<Follows_Set_Input>;
  where: Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Follows_By_PkArgs = {
  _set?: InputMaybe<Follows_Set_Input>;
  pk_columns: Follows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Follows_ManyArgs = {
  updates: ReadonlyArray<Follows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Friendships_UsersArgs = {
  _set?: InputMaybe<Friendships_Users_Set_Input>;
  where: Friendships_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Friendships_Users_By_PkArgs = {
  _set?: InputMaybe<Friendships_Users_Set_Input>;
  pk_columns: Friendships_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Friendships_Users_ManyArgs = {
  updates: ReadonlyArray<Friendships_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Live_EventsArgs = {
  _append?: InputMaybe<Live_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Live_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Live_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Live_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Live_Events_Prepend_Input>;
  _set?: InputMaybe<Live_Events_Set_Input>;
  where: Live_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Events_By_PkArgs = {
  _append?: InputMaybe<Live_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Live_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Live_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Live_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Live_Events_Prepend_Input>;
  _set?: InputMaybe<Live_Events_Set_Input>;
  pk_columns: Live_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Events_ManyArgs = {
  updates: ReadonlyArray<Live_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PagesArgs = {
  _set?: InputMaybe<Pages_Set_Input>;
  where: Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pages_By_PkArgs = {
  _set?: InputMaybe<Pages_Set_Input>;
  pk_columns: Pages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pages_ManyArgs = {
  updates: ReadonlyArray<Pages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PostsArgs = {
  _set?: InputMaybe<Posts_Set_Input>;
  where: Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Posts_By_PkArgs = {
  _set?: InputMaybe<Posts_Set_Input>;
  pk_columns: Posts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Posts_ManyArgs = {
  updates: ReadonlyArray<Posts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectsArgs = {
  _append?: InputMaybe<Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Projects_Delete_Key_Input>;
  _prepend?: InputMaybe<Projects_Prepend_Input>;
  _set?: InputMaybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_By_PkArgs = {
  _append?: InputMaybe<Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Projects_Delete_Key_Input>;
  _prepend?: InputMaybe<Projects_Prepend_Input>;
  _set?: InputMaybe<Projects_Set_Input>;
  pk_columns: Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_CollaboratorsArgs = {
  _set?: InputMaybe<Projects_Collaborators_Set_Input>;
  where: Projects_Collaborators_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Collaborators_By_PkArgs = {
  _set?: InputMaybe<Projects_Collaborators_Set_Input>;
  pk_columns: Projects_Collaborators_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Collaborators_ManyArgs = {
  updates: ReadonlyArray<Projects_Collaborators_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_EditablesArgs = {
  _append?: InputMaybe<Projects_Editables_Append_Input>;
  _delete_at_path?: InputMaybe<Projects_Editables_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Projects_Editables_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Projects_Editables_Delete_Key_Input>;
  _prepend?: InputMaybe<Projects_Editables_Prepend_Input>;
  _set?: InputMaybe<Projects_Editables_Set_Input>;
  where: Projects_Editables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Editables_ManyArgs = {
  updates: ReadonlyArray<Projects_Editables_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_ManyArgs = {
  updates: ReadonlyArray<Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoomsArgs = {
  _append?: InputMaybe<Rooms_Append_Input>;
  _delete_at_path?: InputMaybe<Rooms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rooms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rooms_Delete_Key_Input>;
  _inc?: InputMaybe<Rooms_Inc_Input>;
  _prepend?: InputMaybe<Rooms_Prepend_Input>;
  _set?: InputMaybe<Rooms_Set_Input>;
  where: Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rooms_By_PkArgs = {
  _append?: InputMaybe<Rooms_Append_Input>;
  _delete_at_path?: InputMaybe<Rooms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rooms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rooms_Delete_Key_Input>;
  _inc?: InputMaybe<Rooms_Inc_Input>;
  _prepend?: InputMaybe<Rooms_Prepend_Input>;
  _set?: InputMaybe<Rooms_Set_Input>;
  pk_columns: Rooms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rooms_ManyArgs = {
  updates: ReadonlyArray<Rooms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_InvitesArgs = {
  _set?: InputMaybe<User_Invites_Set_Input>;
  where: User_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Invites_By_PkArgs = {
  _set?: InputMaybe<User_Invites_Set_Input>;
  pk_columns: User_Invites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Invites_ManyArgs = {
  updates: ReadonlyArray<User_Invites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_PresenceArgs = {
  _append?: InputMaybe<User_Presence_Append_Input>;
  _delete_at_path?: InputMaybe<User_Presence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Presence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Presence_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Presence_Prepend_Input>;
  _set?: InputMaybe<User_Presence_Set_Input>;
  where: User_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Presence_By_PkArgs = {
  _append?: InputMaybe<User_Presence_Append_Input>;
  _delete_at_path?: InputMaybe<User_Presence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Presence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Presence_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Presence_Prepend_Input>;
  _set?: InputMaybe<User_Presence_Set_Input>;
  pk_columns: User_Presence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Presence_ManyArgs = {
  updates: ReadonlyArray<User_Presence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ActionsArgs = {
  where: Users_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_By_PkArgs = {
  pk_columns: Users_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_ManyArgs = {
  updates: ReadonlyArray<Users_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: ReadonlyArray<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_NotificationsArgs = {
  where: Users_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Notifications_By_PkArgs = {
  pk_columns: Users_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Notifications_ManyArgs = {
  updates: ReadonlyArray<Users_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_QuestsArgs = {
  _set?: InputMaybe<Users_Quests_Set_Input>;
  where: Users_Quests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Quests_By_PkArgs = {
  _set?: InputMaybe<Users_Quests_Set_Input>;
  pk_columns: Users_Quests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Quests_ManyArgs = {
  updates: ReadonlyArray<Users_Quests_Updates>;
};


/** mutation root */
export type Mutation_RootUpsertClubArgs = {
  displaySettings?: InputMaybe<Scalars['jsonb']['input']>;
  id: Scalars['uuid']['input'];
  name: Scalars['String']['input'];
  privacy: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpsertRoomArgs = {
  clipboardPrompt?: InputMaybe<Scalars['jsonb']['input']>;
  clubId?: InputMaybe<Scalars['uuid']['input']>;
  feedbackQuestion?: InputMaybe<Scalars['String']['input']>;
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['uuid']['input'];
  lockTime?: InputMaybe<Scalars['timestamptz']['input']>;
  name: Scalars['String']['input'];
  privacy?: InputMaybe<Scalars['String']['input']>;
  tagline: Scalars['String']['input'];
  unlockTime?: InputMaybe<Scalars['timestamptz']['input']>;
};


/** mutation root */
export type Mutation_RootUpsertScheduleArgs = {
  clubs?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  id: Scalars['uuid']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  rooms?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  schedule: Scalars['jsonb']['input'];
  timezone: Scalars['String']['input'];
};

export enum Notification_Target {
  Announcement = 'announcement',
  Discord = 'discord',
  Email = 'email',
  Inapp = 'inapp',
  Sms = 'sms'
}

export type Notification_Target_Bool_Exp = {
  readonly _eq?: InputMaybe<Notification_Target>;
  readonly _in?: InputMaybe<ReadonlyArray<Notification_Target>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Notification_Target>;
  readonly _nin?: InputMaybe<ReadonlyArray<Notification_Target>>;
};

export type Notification_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Scalars['notification_type']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['notification_type']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['notification_type']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['notification_type']['input']>>;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  readonly __typename?: 'notifications';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly payload?: Maybe<Scalars['jsonb']['output']>;
  readonly receiver_ids: ReadonlyArray<Scalars['String']['output']>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly target: Notification_Target;
  readonly type: Scalars['notification_type']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly userBySenderId?: Maybe<Users>;
  /** An array relationship */
  readonly users_notifications: ReadonlyArray<Users_Notifications>;
  /** An aggregate relationship */
  readonly users_notifications_aggregate: Users_Notifications_Aggregate;
  readonly valid_from?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_till?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "notifications" */
export type NotificationsPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "notifications" */
export type NotificationsUsers_NotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


/** columns and relationships of "notifications" */
export type NotificationsUsers_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  readonly __typename?: 'notifications_aggregate';
  readonly aggregate?: Maybe<Notifications_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Notifications>;
};

export type Notifications_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Notifications_Aggregate_Bool_Exp_Count>;
};

export type Notifications_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Notifications_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  readonly __typename?: 'notifications_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Notifications_Max_Fields>;
  readonly min?: Maybe<Notifications_Min_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Notifications_Max_Order_By>;
  readonly min?: InputMaybe<Notifications_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Notifications_Bool_Exp>>;
  readonly _not?: InputMaybe<Notifications_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Notifications_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly payload?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly receiver_ids?: InputMaybe<String_Array_Comparison_Exp>;
  readonly sender_id?: InputMaybe<String_Comparison_Exp>;
  readonly target?: InputMaybe<Notification_Target_Bool_Exp>;
  readonly type?: InputMaybe<Notification_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly userBySenderId?: InputMaybe<Users_Bool_Exp>;
  readonly users_notifications?: InputMaybe<Users_Notifications_Bool_Exp>;
  readonly users_notifications_aggregate?: InputMaybe<Users_Notifications_Aggregate_Bool_Exp>;
  readonly valid_from?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly valid_till?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export type Notifications_Insert_Input = {
  readonly target: Notification_Target;
  readonly type?: InputMaybe<Scalars['notification_type']['input']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  readonly __typename?: 'notifications_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly receiver_ids?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly target?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_from?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_till?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly receiver_ids?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly target?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly valid_from?: InputMaybe<Order_By>;
  readonly valid_till?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  readonly __typename?: 'notifications_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly receiver_ids?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly target?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_from?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_till?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly receiver_ids?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly target?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly valid_from?: InputMaybe<Order_By>;
  readonly valid_till?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly payload?: InputMaybe<Order_By>;
  readonly receiver_ids?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly target?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly userBySenderId?: InputMaybe<Users_Order_By>;
  readonly users_notifications_aggregate?: InputMaybe<Users_Notifications_Aggregate_Order_By>;
  readonly valid_from?: InputMaybe<Order_By>;
  readonly valid_till?: InputMaybe<Order_By>;
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  ReceiverIds = 'receiver_ids',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Target = 'target',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTill = 'valid_till'
}

export type Notifications_Set_Input = {
  readonly target?: InputMaybe<Notification_Target>;
  readonly type?: InputMaybe<Scalars['notification_type']['input']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly receiver_ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly target?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_from?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_till?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['numeric']['input']>;
  readonly _gt?: InputMaybe<Scalars['numeric']['input']>;
  readonly _gte?: InputMaybe<Scalars['numeric']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['numeric']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['numeric']['input']>;
  readonly _lte?: InputMaybe<Scalars['numeric']['input']>;
  readonly _neq?: InputMaybe<Scalars['numeric']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "orders" */
export type Orders = {
  readonly __typename?: 'orders';
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  readonly id: Scalars['String']['output'];
  /** An object relationship */
  readonly item: Items;
  readonly item_id: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly purchasor: Users;
  readonly purhcasor_id: Scalars['String']['output'];
  readonly shipping_address: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
  readonly tracking_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly transaction?: Maybe<Transactions>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
};


/** columns and relationships of "orders" */
export type OrdersCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** aggregated selection of "orders" */
export type Orders_Aggregate = {
  readonly __typename?: 'orders_aggregate';
  readonly aggregate?: Maybe<Orders_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Orders>;
};

export type Orders_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Orders_Aggregate_Bool_Exp_Count>;
};

export type Orders_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Orders_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "orders" */
export type Orders_Aggregate_Fields = {
  readonly __typename?: 'orders_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Orders_Max_Fields>;
  readonly min?: Maybe<Orders_Min_Fields>;
};


/** aggregate fields of "orders" */
export type Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "orders" */
export type Orders_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Orders_Max_Order_By>;
  readonly min?: InputMaybe<Orders_Min_Order_By>;
};

/** input type for inserting array relation for remote table "orders" */
export type Orders_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Orders_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Orders_On_Conflict>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Orders_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Orders_Bool_Exp>>;
  readonly _not?: InputMaybe<Orders_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Orders_Bool_Exp>>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly feeds?: InputMaybe<Feeds_Bool_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly item?: InputMaybe<Items_Bool_Exp>;
  readonly item_id?: InputMaybe<String_Comparison_Exp>;
  readonly notes?: InputMaybe<String_Comparison_Exp>;
  readonly purchasor?: InputMaybe<Users_Bool_Exp>;
  readonly purhcasor_id?: InputMaybe<String_Comparison_Exp>;
  readonly shipping_address?: InputMaybe<String_Comparison_Exp>;
  readonly status?: InputMaybe<String_Comparison_Exp>;
  readonly tracking_url?: InputMaybe<String_Comparison_Exp>;
  readonly transaction?: InputMaybe<Transactions_Bool_Exp>;
  readonly transactions?: InputMaybe<Transactions_Bool_Exp>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrdersPkey = 'orders_pkey'
}

/** input type for inserting data into table "orders" */
export type Orders_Insert_Input = {
  readonly comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  readonly votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Orders_Max_Fields = {
  readonly __typename?: 'orders_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly item_id?: Maybe<Scalars['String']['output']>;
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly purhcasor_id?: Maybe<Scalars['String']['output']>;
  readonly shipping_address?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tracking_url?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "orders" */
export type Orders_Max_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly notes?: InputMaybe<Order_By>;
  readonly purhcasor_id?: InputMaybe<Order_By>;
  readonly shipping_address?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tracking_url?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Orders_Min_Fields = {
  readonly __typename?: 'orders_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly item_id?: Maybe<Scalars['String']['output']>;
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly purhcasor_id?: Maybe<Scalars['String']['output']>;
  readonly shipping_address?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tracking_url?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "orders" */
export type Orders_Min_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly notes?: InputMaybe<Order_By>;
  readonly purhcasor_id?: InputMaybe<Order_By>;
  readonly shipping_address?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tracking_url?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "orders" */
export type Orders_Mutation_Response = {
  readonly __typename?: 'orders_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Orders>;
};

/** on_conflict condition type for table "orders" */
export type Orders_On_Conflict = {
  readonly constraint: Orders_Constraint;
  readonly update_columns?: ReadonlyArray<Orders_Update_Column>;
  readonly where?: InputMaybe<Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Orders_Order_By = {
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly item?: InputMaybe<Items_Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly notes?: InputMaybe<Order_By>;
  readonly purchasor?: InputMaybe<Users_Order_By>;
  readonly purhcasor_id?: InputMaybe<Order_By>;
  readonly shipping_address?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tracking_url?: InputMaybe<Order_By>;
  readonly transaction?: InputMaybe<Transactions_Order_By>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
};

/** select columns of table "orders" */
export enum Orders_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PurhcasorId = 'purhcasor_id',
  /** column name */
  ShippingAddress = 'shipping_address',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingUrl = 'tracking_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "orders" */
export type Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Orders_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly purhcasor_id?: InputMaybe<Scalars['String']['input']>;
  readonly shipping_address?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly tracking_url?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** placeholder for update columns of table "orders" (current role has no relevant permissions) */
export enum Orders_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

export enum Otp_Recipient_Type {
  Email = 'email',
  Minecraft = 'minecraft',
  Phone = 'phone',
  Platform = 'platform'
}

export type Otp_Recipient_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Otp_Recipient_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Otp_Recipient_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Otp_Recipient_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Otp_Recipient_Type>>;
};

export type Otps = {
  readonly __typename?: 'otps';
  readonly data: Scalars['otps_data']['output'];
  readonly recipient_type: Otp_Recipient_Type;
};

export type Otps_Bool_Exp = {
  readonly recipient_type?: InputMaybe<Otp_Recipient_Type_Bool_Exp>;
};

export type Otps_Insert_Input = {
  readonly data?: InputMaybe<Scalars['otps_data']['input']>;
  readonly recipient_type?: InputMaybe<Otp_Recipient_Type>;
};

export type Otps_Set_Input = {
  readonly data?: InputMaybe<Scalars['otps_data']['input']>;
  readonly recipient_type?: InputMaybe<Otp_Recipient_Type>;
};

/** columns and relationships of "pages" */
export type Pages = {
  readonly __typename?: 'pages';
  readonly content?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly slug?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "pages". All fields are combined with a logical 'AND'. */
export type Pages_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Pages_Bool_Exp>>;
  readonly _not?: InputMaybe<Pages_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Pages_Bool_Exp>>;
  readonly content?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly slug?: InputMaybe<String_Comparison_Exp>;
  readonly title?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pages" */
export enum Pages_Constraint {
  /** unique or primary key constraint on columns "id" */
  PagesPkey = 'pages_pkey',
  /** unique or primary key constraint on columns "slug" */
  PagesSlugKey = 'pages_slug_key'
}

/** input type for inserting data into table "pages" */
export type Pages_Insert_Input = {
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "pages" */
export type Pages_Mutation_Response = {
  readonly __typename?: 'pages_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  readonly returning?: Maybe<ReadonlyArray<Pages>>;
};

/** on_conflict condition type for table "pages" */
export type Pages_On_Conflict = {
  readonly constraint?: InputMaybe<Pages_Constraint>;
  readonly update_columns?: InputMaybe<ReadonlyArray<Pages_Update_Column>>;
  readonly where?: InputMaybe<Pages_Bool_Exp>;
};

/** Ordering options when selecting data from "pages". */
export type Pages_Order_By = {
  readonly content?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly slug?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pages */
export type Pages_Pk_Columns_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
};

/** select columns of table "pages" */
export enum Pages_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "pages" */
export type Pages_Set_Input = {
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "pages" */
export type Pages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value?: InputMaybe<Pages_Stream_Cursor_Value_Input>;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pages_Stream_Cursor_Value_Input = {
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "pages" */
export enum Pages_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  Title = 'title'
}

export type Pages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Pages_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where?: InputMaybe<Pages_Bool_Exp>;
};

/** columns and relationships of "posts" */
export type Posts = {
  readonly __typename?: 'posts';
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  readonly id: Scalars['uuid']['output'];
  readonly message: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
};


/** columns and relationships of "posts" */
export type PostsCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** order by aggregate values of table "posts" */
export type Posts_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Posts_Max_Order_By>;
  readonly min?: InputMaybe<Posts_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "posts". All fields are combined with a logical 'AND'. */
export type Posts_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Posts_Bool_Exp>>;
  readonly _not?: InputMaybe<Posts_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Posts_Bool_Exp>>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly feeds?: InputMaybe<Feeds_Bool_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly message?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "posts" */
export enum Posts_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostsPkey = 'posts_pkey'
}

export type Posts_Delete_Args = {
  readonly post_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** input type for inserting data into table "posts" */
export type Posts_Insert_Input = {
  readonly comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "posts" */
export type Posts_Max_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "posts" */
export type Posts_Min_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "posts" */
export type Posts_Mutation_Response = {
  readonly __typename?: 'posts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Posts>;
};

/** input type for inserting object relation for remote table "posts" */
export type Posts_Obj_Rel_Insert_Input = {
  readonly data: Posts_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Posts_On_Conflict>;
};

/** on_conflict condition type for table "posts" */
export type Posts_On_Conflict = {
  readonly constraint: Posts_Constraint;
  readonly update_columns?: ReadonlyArray<Posts_Update_Column>;
  readonly where?: InputMaybe<Posts_Bool_Exp>;
};

/** Ordering options when selecting data from "posts". */
export type Posts_Order_By = {
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: posts */
export type Posts_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "posts" */
export enum Posts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "posts" */
export type Posts_Set_Input = {
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "posts" */
export type Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Posts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Posts_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "posts" */
export enum Posts_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Message = 'message'
}

export type Posts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Posts_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Posts_Bool_Exp;
};

export enum Project_Status {
  Created = 'created',
  Deleted = 'deleted',
  Disqualified = 'disqualified',
  Draft = 'draft',
  Reviewed = 'reviewed'
}

export type Project_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Project_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Project_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Project_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Project_Status>>;
};

/** columns and relationships of "projects" */
export type Projects = {
  readonly __typename?: 'projects';
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly channel?: Maybe<Channels>;
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  readonly collaborators: ReadonlyArray<Projects_Collaborators>;
  /** An aggregate relationship */
  readonly collaborators_aggregate: Projects_Collaborators_Aggregate;
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  /** An object relationship */
  readonly competition?: Maybe<Competitions>;
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly creator?: Maybe<Users>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  readonly files: Scalars['jsonb']['output'];
  readonly highlights: Scalars['jsonb']['output'];
  readonly id: Scalars['String']['output'];
  readonly images?: Maybe<Scalars['projects_images']['output']>;
  /** An object relationship */
  readonly judge?: Maybe<Users>;
  readonly judge_id?: Maybe<Scalars['String']['output']>;
  readonly meta: Scalars['jsonb']['output'];
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly place?: Maybe<Scalars['Int']['output']>;
  readonly prize?: Maybe<Scalars['projects_prize']['output']>;
  readonly review_text?: Maybe<Scalars['String']['output']>;
  readonly score?: Maybe<Scalars['Int']['output']>;
  readonly special_prizes?: Maybe<Scalars['jsonb']['output']>;
  readonly status: Project_Status;
  /** An object relationship */
  readonly submitter?: Maybe<Users>;
  readonly submitter_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly topic?: Maybe<Topics>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "projects" */
export type ProjectsCollaboratorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsCollaborators_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsFilesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsHighlightsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsImagesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsPrizeArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsSpecial_PrizesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  readonly __typename?: 'projects_aggregate';
  readonly aggregate?: Maybe<Projects_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Projects>;
};

export type Projects_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Projects_Aggregate_Bool_Exp_Count>;
};

export type Projects_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Projects_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  readonly __typename?: 'projects_aggregate_fields';
  readonly avg?: Maybe<Projects_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Projects_Max_Fields>;
  readonly min?: Maybe<Projects_Min_Fields>;
  readonly stddev?: Maybe<Projects_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Projects_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Projects_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Projects_Sum_Fields>;
  readonly var_pop?: Maybe<Projects_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Projects_Var_Samp_Fields>;
  readonly variance?: Maybe<Projects_Variance_Fields>;
};


/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "projects" */
export type Projects_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Projects_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Projects_Max_Order_By>;
  readonly min?: InputMaybe<Projects_Min_Order_By>;
  readonly stddev?: InputMaybe<Projects_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Projects_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Projects_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Projects_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Projects_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Projects_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Projects_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Projects_Append_Input = {
  readonly images?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "projects" */
export type Projects_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Projects_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** aggregate avg on columns */
export type Projects_Avg_Fields = {
  readonly __typename?: 'projects_avg_fields';
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "projects" */
export type Projects_Avg_Order_By = {
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Projects_Bool_Exp>>;
  readonly _not?: InputMaybe<Projects_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Projects_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly collaborators?: InputMaybe<Projects_Collaborators_Bool_Exp>;
  readonly collaborators_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Bool_Exp>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly competition_id?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly creator?: InputMaybe<Users_Bool_Exp>;
  readonly creator_id?: InputMaybe<String_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly feeds?: InputMaybe<Feeds_Bool_Exp>;
  readonly files?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly highlights?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly images?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly judge?: InputMaybe<Users_Bool_Exp>;
  readonly judge_id?: InputMaybe<String_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly money?: InputMaybe<Numeric_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly place?: InputMaybe<Int_Comparison_Exp>;
  readonly prize?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly review_text?: InputMaybe<String_Comparison_Exp>;
  readonly score?: InputMaybe<Int_Comparison_Exp>;
  readonly special_prizes?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly status?: InputMaybe<Project_Status_Bool_Exp>;
  readonly submitter?: InputMaybe<Users_Bool_Exp>;
  readonly submitter_id?: InputMaybe<String_Comparison_Exp>;
  readonly topic?: InputMaybe<Topics_Bool_Exp>;
  readonly topic_id?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
  readonly xp?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "projects_collaborators" */
export type Projects_Collaborators = {
  readonly __typename?: 'projects_collaborators';
  /** An object relationship */
  readonly collaborator: Users;
  readonly collaborator_id: Scalars['String']['output'];
  /** An object relationship */
  readonly project: Projects;
  readonly project_id: Scalars['String']['output'];
};

/** aggregated selection of "projects_collaborators" */
export type Projects_Collaborators_Aggregate = {
  readonly __typename?: 'projects_collaborators_aggregate';
  readonly aggregate?: Maybe<Projects_Collaborators_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Projects_Collaborators>;
};

export type Projects_Collaborators_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Projects_Collaborators_Aggregate_Bool_Exp_Count>;
};

export type Projects_Collaborators_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Projects_Collaborators_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "projects_collaborators" */
export type Projects_Collaborators_Aggregate_Fields = {
  readonly __typename?: 'projects_collaborators_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Projects_Collaborators_Max_Fields>;
  readonly min?: Maybe<Projects_Collaborators_Min_Fields>;
};


/** aggregate fields of "projects_collaborators" */
export type Projects_Collaborators_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "projects_collaborators" */
export type Projects_Collaborators_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Projects_Collaborators_Max_Order_By>;
  readonly min?: InputMaybe<Projects_Collaborators_Min_Order_By>;
};

/** input type for inserting array relation for remote table "projects_collaborators" */
export type Projects_Collaborators_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Projects_Collaborators_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Projects_Collaborators_On_Conflict>;
};

/** Boolean expression to filter rows from the table "projects_collaborators". All fields are combined with a logical 'AND'. */
export type Projects_Collaborators_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Projects_Collaborators_Bool_Exp>>;
  readonly _not?: InputMaybe<Projects_Collaborators_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Projects_Collaborators_Bool_Exp>>;
  readonly collaborator?: InputMaybe<Users_Bool_Exp>;
  readonly collaborator_id?: InputMaybe<String_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly project_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects_collaborators" */
export enum Projects_Collaborators_Constraint {
  /** unique or primary key constraint on columns "project_id", "collaborator_id" */
  ProjectsCollaboratorsPkey = 'projects_collaborators_pkey'
}

/** input type for inserting data into table "projects_collaborators" */
export type Projects_Collaborators_Insert_Input = {
  readonly collaborator_id?: InputMaybe<Scalars['String']['input']>;
  readonly project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Projects_Collaborators_Max_Fields = {
  readonly __typename?: 'projects_collaborators_max_fields';
  readonly collaborator_id?: Maybe<Scalars['String']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "projects_collaborators" */
export type Projects_Collaborators_Max_Order_By = {
  readonly collaborator_id?: InputMaybe<Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Collaborators_Min_Fields = {
  readonly __typename?: 'projects_collaborators_min_fields';
  readonly collaborator_id?: Maybe<Scalars['String']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "projects_collaborators" */
export type Projects_Collaborators_Min_Order_By = {
  readonly collaborator_id?: InputMaybe<Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "projects_collaborators" */
export type Projects_Collaborators_Mutation_Response = {
  readonly __typename?: 'projects_collaborators_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Projects_Collaborators>;
};

/** on_conflict condition type for table "projects_collaborators" */
export type Projects_Collaborators_On_Conflict = {
  readonly constraint: Projects_Collaborators_Constraint;
  readonly update_columns?: ReadonlyArray<Projects_Collaborators_Update_Column>;
  readonly where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};

/** Ordering options when selecting data from "projects_collaborators". */
export type Projects_Collaborators_Order_By = {
  readonly collaborator?: InputMaybe<Users_Order_By>;
  readonly collaborator_id?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects_collaborators */
export type Projects_Collaborators_Pk_Columns_Input = {
  readonly collaborator_id: Scalars['String']['input'];
  readonly project_id: Scalars['String']['input'];
};

/** select columns of table "projects_collaborators" */
export enum Projects_Collaborators_Select_Column {
  /** column name */
  CollaboratorId = 'collaborator_id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "projects_collaborators" */
export type Projects_Collaborators_Set_Input = {
  readonly collaborator_id?: InputMaybe<Scalars['String']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "projects_collaborators" */
export type Projects_Collaborators_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Projects_Collaborators_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Collaborators_Stream_Cursor_Value_Input = {
  readonly collaborator_id?: InputMaybe<Scalars['String']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "projects_collaborators" */
export enum Projects_Collaborators_Update_Column {
  /** column name */
  CollaboratorId = 'collaborator_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Projects_Collaborators_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Projects_Collaborators_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Projects_Collaborators_Bool_Exp;
};

/** unique or primary key constraints on table "projects" */
export enum Projects_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Projects_Delete_At_Path_Input = {
  readonly images?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Projects_Delete_Elem_Input = {
  readonly images?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Projects_Delete_Key_Input = {
  readonly images?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "projects_editables" */
export type Projects_Editables = {
  readonly __typename?: 'projects_editables';
  /** An array relationship */
  readonly collaborators: ReadonlyArray<Projects_Collaborators>;
  /** An aggregate relationship */
  readonly collaborators_aggregate: Projects_Collaborators_Aggregate;
  /** An object relationship */
  readonly competition?: Maybe<Competitions>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "projects_editables" */
export type Projects_EditablesCollaboratorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "projects_editables" */
export type Projects_EditablesCollaborators_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Projects_Editables_Append_Input = {
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "projects_editables". All fields are combined with a logical 'AND'. */
export type Projects_Editables_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Projects_Editables_Bool_Exp>>;
  readonly _not?: InputMaybe<Projects_Editables_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Projects_Editables_Bool_Exp>>;
  readonly collaborators?: InputMaybe<Projects_Collaborators_Bool_Exp>;
  readonly collaborators_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Bool_Exp>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly project_id?: InputMaybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Projects_Editables_Delete_At_Path_Input = {
  readonly highlights?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Projects_Editables_Delete_Elem_Input = {
  readonly highlights?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Projects_Editables_Delete_Key_Input = {
  readonly highlights?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "projects_editables" */
export type Projects_Editables_Mutation_Response = {
  readonly __typename?: 'projects_editables_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Projects_Editables>;
};

/** Ordering options when selecting data from "projects_editables". */
export type Projects_Editables_Order_By = {
  readonly collaborators_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Order_By>;
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Projects_Editables_Prepend_Input = {
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "projects_editables" */
export enum Projects_Editables_Select_Column {
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "projects_editables" */
export type Projects_Editables_Set_Input = {
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "projects_editables" */
export type Projects_Editables_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Projects_Editables_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Editables_Stream_Cursor_Value_Input = {
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
};

export type Projects_Editables_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Projects_Editables_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Projects_Editables_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Projects_Editables_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Projects_Editables_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Projects_Editables_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Projects_Editables_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Projects_Editables_Bool_Exp;
};

/** input type for inserting data into table "projects" */
export type Projects_Insert_Input = {
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly collaborators?: InputMaybe<Projects_Collaborators_Arr_Rel_Insert_Input>;
  readonly comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly competition?: InputMaybe<Competitions_Obj_Rel_Insert_Input>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly images?: InputMaybe<Scalars['projects_images']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly prize?: InputMaybe<Scalars['projects_prize']['input']>;
  readonly status?: InputMaybe<Project_Status>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  readonly __typename?: 'projects_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly judge_id?: Maybe<Scalars['String']['output']>;
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly place?: Maybe<Scalars['Int']['output']>;
  readonly review_text?: Maybe<Scalars['String']['output']>;
  readonly score?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly submitter_id?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "projects" */
export type Projects_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly review_text?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submitter_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  readonly __typename?: 'projects_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly judge_id?: Maybe<Scalars['String']['output']>;
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly place?: Maybe<Scalars['Int']['output']>;
  readonly review_text?: Maybe<Scalars['String']['output']>;
  readonly score?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly submitter_id?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "projects" */
export type Projects_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly review_text?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submitter_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "projects" */
export type Projects_Mutation_Response = {
  readonly __typename?: 'projects_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type Projects_Obj_Rel_Insert_Input = {
  readonly data: Projects_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** on_conflict condition type for table "projects" */
export type Projects_On_Conflict = {
  readonly constraint: Projects_Constraint;
  readonly update_columns?: ReadonlyArray<Projects_Update_Column>;
  readonly where?: InputMaybe<Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "projects". */
export type Projects_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly collaborators_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Order_By>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator?: InputMaybe<Users_Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Order_By>;
  readonly files?: InputMaybe<Order_By>;
  readonly highlights?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly images?: InputMaybe<Order_By>;
  readonly judge?: InputMaybe<Users_Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly prize?: InputMaybe<Order_By>;
  readonly review_text?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly special_prizes?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submitter?: InputMaybe<Users_Order_By>;
  readonly submitter_id?: InputMaybe<Order_By>;
  readonly topic?: InputMaybe<Topics_Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects */
export type Projects_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Projects_Prepend_Input = {
  readonly images?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  Files = 'files',
  /** column name */
  Highlights = 'highlights',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  JudgeId = 'judge_id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Money = 'money',
  /** column name */
  Name = 'name',
  /** column name */
  Place = 'place',
  /** column name */
  Prize = 'prize',
  /** column name */
  ReviewText = 'review_text',
  /** column name */
  Score = 'score',
  /** column name */
  SpecialPrizes = 'special_prizes',
  /** column name */
  Status = 'status',
  /** column name */
  SubmitterId = 'submitter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Xp = 'xp'
}

/** input type for updating data in table "projects" */
export type Projects_Set_Input = {
  readonly images?: InputMaybe<Scalars['projects_images']['input']>;
  readonly prize?: InputMaybe<Scalars['projects_prize']['input']>;
  readonly status?: InputMaybe<Project_Status>;
  readonly submitter_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Projects_Stddev_Fields = {
  readonly __typename?: 'projects_stddev_fields';
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "projects" */
export type Projects_Stddev_Order_By = {
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Projects_Stddev_Pop_Fields = {
  readonly __typename?: 'projects_stddev_pop_fields';
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "projects" */
export type Projects_Stddev_Pop_Order_By = {
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Projects_Stddev_Samp_Fields = {
  readonly __typename?: 'projects_stddev_samp_fields';
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "projects" */
export type Projects_Stddev_Samp_Order_By = {
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "projects" */
export type Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly files?: InputMaybe<Scalars['jsonb']['input']>;
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly images?: InputMaybe<Scalars['jsonb']['input']>;
  readonly judge_id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly place?: InputMaybe<Scalars['Int']['input']>;
  readonly prize?: InputMaybe<Scalars['jsonb']['input']>;
  readonly review_text?: InputMaybe<Scalars['String']['input']>;
  readonly score?: InputMaybe<Scalars['Int']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly submitter_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Projects_Sum_Fields = {
  readonly __typename?: 'projects_sum_fields';
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly place?: Maybe<Scalars['Int']['output']>;
  readonly score?: Maybe<Scalars['Int']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "projects" */
export type Projects_Sum_Order_By = {
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** update columns of table "projects" */
export enum Projects_Update_Column {
  /** column name */
  Images = 'images',
  /** column name */
  Status = 'status',
  /** column name */
  SubmitterId = 'submitter_id',
  /** column name */
  TopicId = 'topic_id'
}

export type Projects_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Projects_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Projects_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Projects_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Projects_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Projects_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Projects_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Projects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Projects_Var_Pop_Fields = {
  readonly __typename?: 'projects_var_pop_fields';
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "projects" */
export type Projects_Var_Pop_Order_By = {
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Projects_Var_Samp_Fields = {
  readonly __typename?: 'projects_var_samp_fields';
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "projects" */
export type Projects_Var_Samp_Order_By = {
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Projects_Variance_Fields = {
  readonly __typename?: 'projects_variance_fields';
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "projects" */
export type Projects_Variance_Order_By = {
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** columns and relationships of "prospective_users" */
export type Prospective_Users = {
  readonly __typename?: 'prospective_users';
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly first_name?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly last_name?: Maybe<Scalars['String']['output']>;
  readonly parent_email?: Maybe<Scalars['String']['output']>;
  readonly parent_first_name?: Maybe<Scalars['String']['output']>;
  readonly parent_last_name?: Maybe<Scalars['String']['output']>;
  readonly parent_phone?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly purchases: ReadonlyArray<Prospective_Users_Purchases>;
  /** An object relationship */
  readonly user?: Maybe<Users>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "prospective_users" */
export type Prospective_UsersPurchasesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "prospective_users". All fields are combined with a logical 'AND'. */
export type Prospective_Users_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Prospective_Users_Bool_Exp>>;
  readonly _not?: InputMaybe<Prospective_Users_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Prospective_Users_Bool_Exp>>;
  readonly age?: InputMaybe<Int_Comparison_Exp>;
  readonly email?: InputMaybe<String_Comparison_Exp>;
  readonly first_name?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly last_name?: InputMaybe<String_Comparison_Exp>;
  readonly parent_email?: InputMaybe<String_Comparison_Exp>;
  readonly parent_first_name?: InputMaybe<String_Comparison_Exp>;
  readonly parent_last_name?: InputMaybe<String_Comparison_Exp>;
  readonly parent_phone?: InputMaybe<String_Comparison_Exp>;
  readonly phone?: InputMaybe<String_Comparison_Exp>;
  readonly purchases?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "prospective_users". */
export type Prospective_Users_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly email?: InputMaybe<Order_By>;
  readonly first_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly last_name?: InputMaybe<Order_By>;
  readonly parent_email?: InputMaybe<Order_By>;
  readonly parent_first_name?: InputMaybe<Order_By>;
  readonly parent_last_name?: InputMaybe<Order_By>;
  readonly parent_phone?: InputMaybe<Order_By>;
  readonly phone?: InputMaybe<Order_By>;
  readonly purchases_aggregate?: InputMaybe<Prospective_Users_Purchases_Aggregate_Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "prospective_users_purchases" */
export type Prospective_Users_Purchases = {
  readonly __typename?: 'prospective_users_purchases';
  /** An object relationship */
  readonly club: Clubs;
  readonly club_id: Scalars['uuid']['output'];
  readonly payment_status: Scalars['String']['output'];
  /** An object relationship */
  readonly prospective_user: Prospective_Users;
  readonly prospective_user_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Prospective_Users_Purchases_Max_Order_By>;
  readonly min?: InputMaybe<Prospective_Users_Purchases_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "prospective_users_purchases". All fields are combined with a logical 'AND'. */
export type Prospective_Users_Purchases_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Bool_Exp>>;
  readonly _not?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Bool_Exp>>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly payment_status?: InputMaybe<String_Comparison_Exp>;
  readonly prospective_user?: InputMaybe<Prospective_Users_Bool_Exp>;
  readonly prospective_user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Max_Order_By = {
  readonly club_id?: InputMaybe<Order_By>;
  readonly payment_status?: InputMaybe<Order_By>;
  readonly prospective_user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Min_Order_By = {
  readonly club_id?: InputMaybe<Order_By>;
  readonly payment_status?: InputMaybe<Order_By>;
  readonly prospective_user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "prospective_users_purchases". */
export type Prospective_Users_Purchases_Order_By = {
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly payment_status?: InputMaybe<Order_By>;
  readonly prospective_user?: InputMaybe<Prospective_Users_Order_By>;
  readonly prospective_user_id?: InputMaybe<Order_By>;
};

/** select columns of table "prospective_users_purchases" */
export enum Prospective_Users_Purchases_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  ProspectiveUserId = 'prospective_user_id'
}

/** Streaming cursor of the table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Prospective_Users_Purchases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prospective_Users_Purchases_Stream_Cursor_Value_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payment_status?: InputMaybe<Scalars['String']['input']>;
  readonly prospective_user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** select columns of table "prospective_users" */
export enum Prospective_Users_Select_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ParentEmail = 'parent_email',
  /** column name */
  ParentFirstName = 'parent_first_name',
  /** column name */
  ParentLastName = 'parent_last_name',
  /** column name */
  ParentPhone = 'parent_phone',
  /** column name */
  Phone = 'phone',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "prospective_users" */
export type Prospective_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Prospective_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prospective_Users_Stream_Cursor_Value_Input = {
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly first_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly last_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_email?: InputMaybe<Scalars['String']['input']>;
  readonly parent_first_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_last_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_phone?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "public_users" */
export type Public_Users = {
  readonly __typename?: 'public_users';
  /** An object relationship */
  readonly badges?: Maybe<Users_Badges>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  readonly role?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly username?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "public_users". All fields are combined with a logical 'AND'. */
export type Public_Users_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Public_Users_Bool_Exp>>;
  readonly _not?: InputMaybe<Public_Users_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Public_Users_Bool_Exp>>;
  readonly badges?: InputMaybe<Users_Badges_Bool_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly role?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly username?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "public_users". */
export type Public_Users_Order_By = {
  readonly badges?: InputMaybe<Users_Badges_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly role?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
};

/** select columns of table "public_users" */
export enum Public_Users_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Photo = 'photo',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** Streaming cursor of the table "public_users" */
export type Public_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Public_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Public_Users_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly role?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
};

export type Query_Root = {
  readonly __typename?: 'query_root';
  /** An array relationship */
  readonly accounts: ReadonlyArray<Accounts>;
  /** fetch data from the table: "accounts" using primary key columns */
  readonly accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "avatars" */
  readonly avatars: ReadonlyArray<Avatars>;
  /** An array relationship */
  readonly badges: ReadonlyArray<Badges>;
  /** An array relationship */
  readonly breakouts: ReadonlyArray<Breakouts>;
  /** fetch data from the table: "breakouts" using primary key columns */
  readonly breakouts_by_pk?: Maybe<Breakouts>;
  /** fetch data from the table: "channels" */
  readonly channels: ReadonlyArray<Channels>;
  /** fetch aggregated fields from the table: "channels" */
  readonly channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  readonly channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table: "channels_events" */
  readonly channels_events: ReadonlyArray<Channels_Events>;
  /** fetch data from the table: "channels_events" using primary key columns */
  readonly channels_events_by_pk?: Maybe<Channels_Events>;
  /** fetch data from the table: "channels_user" */
  readonly channels_user: ReadonlyArray<Channels_User>;
  /** fetch aggregated fields from the table: "channels_user" */
  readonly channels_user_aggregate: Channels_User_Aggregate;
  /** fetch data from the table: "channels_user" using primary key columns */
  readonly channels_user_by_pk?: Maybe<Channels_User>;
  /** An array relationship */
  readonly chats: ReadonlyArray<Chats>;
  /** An aggregate relationship */
  readonly chats_aggregate: Chats_Aggregate;
  /** fetch data from the table: "chats" using primary key columns */
  readonly chats_by_pk?: Maybe<Chats>;
  /** An array relationship */
  readonly clubs: ReadonlyArray<Clubs>;
  /** An aggregate relationship */
  readonly clubs_aggregate: Clubs_Aggregate;
  /** fetch data from the table: "clubs" using primary key columns */
  readonly clubs_by_pk?: Maybe<Clubs>;
  /** fetch data from the table: "clubs_members" */
  readonly clubs_members: ReadonlyArray<Clubs_Members>;
  /** fetch data from the table: "clubs_members" using primary key columns */
  readonly clubs_members_by_pk?: Maybe<Clubs_Members>;
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  readonly comments_by_pk?: Maybe<Comments>;
  /** An array relationship */
  readonly comments_votes: ReadonlyArray<Comments_Votes>;
  /** An aggregate relationship */
  readonly comments_votes_aggregate: Comments_Votes_Aggregate;
  /** fetch data from the table: "comments_votes" using primary key columns */
  readonly comments_votes_by_pk?: Maybe<Comments_Votes>;
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Aggregate;
  /** fetch data from the table: "competitions" using primary key columns */
  readonly competitions_by_pk?: Maybe<Competitions>;
  /** fetch data from the table: "competitions_judges" */
  readonly competitions_judges: ReadonlyArray<Competitions_Judges>;
  /** fetch data from the table: "competitions_judges" using primary key columns */
  readonly competitions_judges_by_pk?: Maybe<Competitions_Judges>;
  /** fetch data from the table: "competitions_tags" */
  readonly competitions_tags: ReadonlyArray<Competitions_Tags>;
  /** fetch data from the table: "competitions_tags" using primary key columns */
  readonly competitions_tags_by_pk?: Maybe<Competitions_Tags>;
  /** fetch data from the table: "competitions_tutorials" */
  readonly competitions_tutorials: ReadonlyArray<Competitions_Tutorials>;
  /** fetch data from the table: "competitions_tutorials" using primary key columns */
  readonly competitions_tutorials_by_pk?: Maybe<Competitions_Tutorials>;
  /** fetch data from the table: "features" */
  readonly features: ReadonlyArray<Features>;
  /** An array relationship */
  readonly feedback: ReadonlyArray<Feedback>;
  /** fetch data from the table: "feedback" using primary key columns */
  readonly feedback_by_pk?: Maybe<Feedback>;
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  /** fetch data from the table: "feeds" using primary key columns */
  readonly feeds_by_pk?: Maybe<Feeds>;
  /** fetch data from the table: "follows" */
  readonly follows: ReadonlyArray<Follows>;
  /** fetch aggregated fields from the table: "follows" */
  readonly follows_aggregate: Follows_Aggregate;
  /** fetch data from the table: "follows" using primary key columns */
  readonly follows_by_pk?: Maybe<Follows>;
  /** fetch data from the table: "friendships" */
  readonly friendships: ReadonlyArray<Friendships>;
  /** fetch data from the table: "friendships" using primary key columns */
  readonly friendships_by_pk?: Maybe<Friendships>;
  /** An array relationship */
  readonly friendships_users: ReadonlyArray<Friendships_Users>;
  /** fetch data from the table: "friendships_users" using primary key columns */
  readonly friendships_users_by_pk?: Maybe<Friendships_Users>;
  readonly getAllAvatars?: Maybe<GetAllAvatarsOutput>;
  /** fetch data from the table: "items" */
  readonly items: ReadonlyArray<Items>;
  /** fetch data from the table: "items" using primary key columns */
  readonly items_by_pk?: Maybe<Items>;
  /** fetch data from the table: "items_tags" */
  readonly items_tags: ReadonlyArray<Items_Tags>;
  /** fetch data from the table: "items_tags" using primary key columns */
  readonly items_tags_by_pk?: Maybe<Items_Tags>;
  /** fetch data from the table: "levels" */
  readonly levels: ReadonlyArray<Levels>;
  /** fetch data from the table: "levels" using primary key columns */
  readonly levels_by_pk?: Maybe<Levels>;
  /** An array relationship */
  readonly live_events: ReadonlyArray<Live_Events>;
  /** An aggregate relationship */
  readonly live_events_aggregate: Live_Events_Aggregate;
  /** fetch data from the table: "live_events" using primary key columns */
  readonly live_events_by_pk?: Maybe<Live_Events>;
  /** fetch data from the table: "live_events_hosts" */
  readonly live_events_hosts: ReadonlyArray<Live_Events_Hosts>;
  /** fetch aggregated fields from the table: "live_events_hosts" */
  readonly live_events_hosts_aggregate: Live_Events_Hosts_Aggregate;
  /** fetch data from the table: "live_events_hosts" using primary key columns */
  readonly live_events_hosts_by_pk?: Maybe<Live_Events_Hosts>;
  /** An array relationship */
  readonly notifications: ReadonlyArray<Notifications>;
  /** An aggregate relationship */
  readonly notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  readonly notifications_by_pk?: Maybe<Notifications>;
  /** An array relationship */
  readonly orders: ReadonlyArray<Orders>;
  /** An aggregate relationship */
  readonly orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  readonly orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "pages" */
  readonly pages?: Maybe<ReadonlyArray<Pages>>;
  /** fetch data from the table: "pages" using primary key columns */
  readonly pages_by_pk?: Maybe<Pages>;
  /** An array relationship */
  readonly posts: ReadonlyArray<Posts>;
  /** fetch data from the table: "posts" using primary key columns */
  readonly posts_by_pk?: Maybe<Posts>;
  readonly profile_feed: ReadonlyArray<FeedItem>;
  /** An array relationship */
  readonly projects: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  readonly projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "projects_collaborators" */
  readonly projects_collaborators: ReadonlyArray<Projects_Collaborators>;
  /** fetch aggregated fields from the table: "projects_collaborators" */
  readonly projects_collaborators_aggregate: Projects_Collaborators_Aggregate;
  /** fetch data from the table: "projects_collaborators" using primary key columns */
  readonly projects_collaborators_by_pk?: Maybe<Projects_Collaborators>;
  /** fetch data from the table: "projects_editables" */
  readonly projects_editables: ReadonlyArray<Projects_Editables>;
  /** fetch data from the table: "prospective_users" */
  readonly prospective_users: ReadonlyArray<Prospective_Users>;
  /** fetch data from the table: "prospective_users" using primary key columns */
  readonly prospective_users_by_pk?: Maybe<Prospective_Users>;
  /** An array relationship */
  readonly prospective_users_purchases: ReadonlyArray<Prospective_Users_Purchases>;
  /** fetch data from the table: "prospective_users_purchases" using primary key columns */
  readonly prospective_users_purchases_by_pk?: Maybe<Prospective_Users_Purchases>;
  /** fetch data from the table: "public_users" */
  readonly public_users: ReadonlyArray<Public_Users>;
  /** An array relationship */
  readonly quests: ReadonlyArray<Quests>;
  /** An aggregate relationship */
  readonly quests_aggregate: Quests_Aggregate;
  /** fetch data from the table: "quests" using primary key columns */
  readonly quests_by_pk?: Maybe<Quests>;
  /** fetch data from the table: "reports" */
  readonly reports: ReadonlyArray<Reports>;
  /** fetch data from the table: "reports" using primary key columns */
  readonly reports_by_pk?: Maybe<Reports>;
  /** An array relationship */
  readonly rooms: ReadonlyArray<Rooms>;
  /** An aggregate relationship */
  readonly rooms_aggregate?: Maybe<Rooms_Aggregate>;
  /** fetch data from the table: "rooms" using primary key columns */
  readonly rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table: "schedules" */
  readonly schedules: ReadonlyArray<Schedules>;
  /** fetch data from the table: "schedules" using primary key columns */
  readonly schedules_by_pk?: Maybe<Schedules>;
  /** An array relationship */
  readonly subscriptions: ReadonlyArray<Subscriptions>;
  /** fetch data from the table: "subscriptions" using primary key columns */
  readonly subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table: "tags" */
  readonly tags: ReadonlyArray<Tags>;
  /** fetch data from the table: "tags_associations" */
  readonly tags_associations: ReadonlyArray<Tags_Associations>;
  /** fetch data from the table: "tags_associations" using primary key columns */
  readonly tags_associations_by_pk?: Maybe<Tags_Associations>;
  /** fetch data from the table: "tags" using primary key columns */
  readonly tags_by_pk?: Maybe<Tags>;
  /** An array relationship */
  readonly themes: ReadonlyArray<Themes>;
  /** fetch data from the table: "themes" using primary key columns */
  readonly themes_by_pk?: Maybe<Themes>;
  /** fetch data from the table: "tools" */
  readonly tools: ReadonlyArray<Tools>;
  /** fetch data from the table: "tools" using primary key columns */
  readonly tools_by_pk?: Maybe<Tools>;
  /** fetch data from the table: "topics" */
  readonly topics: ReadonlyArray<Topics>;
  /** fetch data from the table: "topics" using primary key columns */
  readonly topics_by_pk?: Maybe<Topics>;
  /** fetch data from the table: "topics_tools" */
  readonly topics_tools: ReadonlyArray<Topics_Tools>;
  /** fetch data from the table: "topics_tools" using primary key columns */
  readonly topics_tools_by_pk?: Maybe<Topics_Tools>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
  /** An array relationship */
  readonly tutorials: ReadonlyArray<Tutorials>;
  /** fetch data from the table: "tutorials" using primary key columns */
  readonly tutorials_by_pk?: Maybe<Tutorials>;
  /** fetch data from the table: "tutorials_tags" */
  readonly tutorials_tags: ReadonlyArray<Tutorials_Tags>;
  /** fetch data from the table: "tutorials_tags" using primary key columns */
  readonly tutorials_tags_by_pk?: Maybe<Tutorials_Tags>;
  readonly user_feed: ReadonlyArray<FeedItem>;
  /** An array relationship */
  readonly user_invites: ReadonlyArray<User_Invites>;
  /** fetch data from the table: "user_invites" using primary key columns */
  readonly user_invites_by_pk?: Maybe<User_Invites>;
  /** fetch data from the table: "user_presence" */
  readonly user_presence: ReadonlyArray<User_Presence>;
  /** fetch data from the table: "user_presence" using primary key columns */
  readonly user_presence_by_pk?: Maybe<User_Presence>;
  /** fetch data from the table: "users" */
  readonly users: ReadonlyArray<Users>;
  /** fetch data from the table: "users_actions" */
  readonly users_actions: ReadonlyArray<Users_Actions>;
  /** fetch data from the table: "users_actions" using primary key columns */
  readonly users_actions_by_pk?: Maybe<Users_Actions>;
  /** An array relationship */
  readonly users_badges: ReadonlyArray<Users_Badges>;
  /** fetch data from the table: "users_badges" using primary key columns */
  readonly users_badges_by_pk?: Maybe<Users_Badges>;
  /** fetch data from the table: "users" using primary key columns */
  readonly users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_features" */
  readonly users_features: ReadonlyArray<Users_Features>;
  /** An array relationship */
  readonly users_notifications: ReadonlyArray<Users_Notifications>;
  /** An aggregate relationship */
  readonly users_notifications_aggregate: Users_Notifications_Aggregate;
  /** fetch data from the table: "users_notifications" using primary key columns */
  readonly users_notifications_by_pk?: Maybe<Users_Notifications>;
  /** fetch data from the table: "users_presence" */
  readonly users_presence: ReadonlyArray<Users_Presence>;
  /** fetch aggregated fields from the table: "users_presence" */
  readonly users_presence_aggregate: Users_Presence_Aggregate;
  /** An array relationship */
  readonly users_quests: ReadonlyArray<Users_Quests>;
  /** An aggregate relationship */
  readonly users_quests_aggregate: Users_Quests_Aggregate;
  /** fetch data from the table: "users_quests" using primary key columns */
  readonly users_quests_by_pk?: Maybe<Users_Quests>;
  /** fetch data from the table: "users_stats" */
  readonly users_stats: ReadonlyArray<Users_Stats>;
  /** fetch data from the table: "users_stats" using primary key columns */
  readonly users_stats_by_pk?: Maybe<Users_Stats>;
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
  /** fetch data from the table: "votes" using primary key columns */
  readonly votes_by_pk?: Maybe<Votes>;
};


export type Query_RootAccountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  id: Scalars['String']['input'];
};


export type Query_RootAvatarsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Avatars_Order_By>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Query_RootBadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Query_RootBreakoutsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Breakouts_Order_By>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


export type Query_RootBreakouts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootChannelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Query_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Query_RootChannels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootChannels_EventsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Events_Order_By>>;
  where?: InputMaybe<Channels_Events_Bool_Exp>;
};


export type Query_RootChannels_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootChannels_UserArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_User_Order_By>>;
  where?: InputMaybe<Channels_User_Bool_Exp>;
};


export type Query_RootChannels_User_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_User_Order_By>>;
  where?: InputMaybe<Channels_User_Bool_Exp>;
};


export type Query_RootChannels_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootChatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Query_RootChats_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Query_RootChats_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootClubsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


export type Query_RootClubs_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


export type Query_RootClubs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootClubs_MembersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


export type Query_RootClubs_Members_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Query_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Query_RootComments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootComments_VotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


export type Query_RootComments_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


export type Query_RootComments_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


export type Query_RootCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


export type Query_RootCompetitions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootCompetitions_JudgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


export type Query_RootCompetitions_Judges_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  judge_id: Scalars['String']['input'];
};


export type Query_RootCompetitions_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


export type Query_RootCompetitions_Tags_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


export type Query_RootCompetitions_TutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


export type Query_RootCompetitions_Tutorials_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  tutorial_id: Scalars['String']['input'];
};


export type Query_RootFeaturesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Order_By>>;
  where?: InputMaybe<Features_Bool_Exp>;
};


export type Query_RootFeedbackArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Query_RootFeedback_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


export type Query_RootFeeds_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFollowsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Query_RootFollows_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Query_RootFollows_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFriendshipsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Order_By>>;
  where?: InputMaybe<Friendships_Bool_Exp>;
};


export type Query_RootFriendships_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFriendships_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


export type Query_RootFriendships_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootItemsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootItems_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootItems_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Tags_Order_By>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


export type Query_RootItems_Tags_By_PkArgs = {
  item_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


export type Query_RootLevelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Query_RootLevels_By_PkArgs = {
  level: Scalars['Int']['input'];
};


export type Query_RootLive_EventsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


export type Query_RootLive_Events_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


export type Query_RootLive_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootLive_Events_HostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


export type Query_RootLive_Events_Hosts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


export type Query_RootLive_Events_Hosts_By_PkArgs = {
  live_event_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOrdersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPagesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Pages_Order_By>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Query_RootPages_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Query_RootPosts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProfile_FeedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  username: Scalars['String']['input'];
};


export type Query_RootProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootProjects_CollaboratorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


export type Query_RootProjects_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


export type Query_RootProjects_Collaborators_By_PkArgs = {
  collaborator_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
};


export type Query_RootProjects_EditablesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Editables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Editables_Order_By>>;
  where?: InputMaybe<Projects_Editables_Bool_Exp>;
};


export type Query_RootProspective_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Order_By>>;
  where?: InputMaybe<Prospective_Users_Bool_Exp>;
};


export type Query_RootProspective_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProspective_Users_PurchasesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


export type Query_RootProspective_Users_Purchases_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  prospective_user_id: Scalars['uuid']['input'];
};


export type Query_RootPublic_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Public_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Public_Users_Order_By>>;
  where?: InputMaybe<Public_Users_Bool_Exp>;
};


export type Query_RootQuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


export type Query_RootQuests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


export type Query_RootQuests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootReportsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Query_RootReports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRoomsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Query_RootRooms_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Query_RootRooms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSchedulesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Schedules_Order_By>>;
  where?: InputMaybe<Schedules_Bool_Exp>;
};


export type Query_RootSchedules_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Query_RootSubscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_AssociationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Associations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Associations_Order_By>>;
  where?: InputMaybe<Tags_Associations_Bool_Exp>;
};


export type Query_RootTags_Associations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTags_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootThemesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Themes_Order_By>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


export type Query_RootThemes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootToolsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Query_RootTools_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTopicsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Order_By>>;
  where?: InputMaybe<Topics_Bool_Exp>;
};


export type Query_RootTopics_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTopics_ToolsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};


export type Query_RootTopics_Tools_By_PkArgs = {
  tool_id: Scalars['String']['input'];
  topic_id: Scalars['String']['input'];
};


export type Query_RootTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Query_RootTutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Order_By>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};


export type Query_RootTutorials_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTutorials_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};


export type Query_RootTutorials_Tags_By_PkArgs = {
  tag_id: Scalars['String']['input'];
  tutorial_id: Scalars['String']['input'];
};


export type Query_RootUser_FeedArgs = {
  all: Scalars['Boolean']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type Query_RootUser_InvitesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Query_RootUser_Invites_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_PresenceArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


export type Query_RootUser_Presence_By_PkArgs = {
  platform: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_ActionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Query_RootUsers_Actions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsers_BadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Badges_Order_By>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


export type Query_RootUsers_Badges_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUsers_FeaturesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


export type Query_RootUsers_NotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


export type Query_RootUsers_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


export type Query_RootUsers_Notifications_By_PkArgs = {
  notification_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootUsers_PresenceArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Presence_Order_By>>;
  where?: InputMaybe<Users_Presence_Bool_Exp>;
};


export type Query_RootUsers_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Presence_Order_By>>;
  where?: InputMaybe<Users_Presence_Bool_Exp>;
};


export type Query_RootUsers_QuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


export type Query_RootUsers_Quests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


export type Query_RootUsers_Quests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsers_StatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stats_Order_By>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


export type Query_RootUsers_Stats_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Query_RootVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Query_RootVotes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "quests" */
export type Quests = {
  readonly __typename?: 'quests';
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly description: Scalars['String']['output'];
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly icon: Scalars['String']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly name: Scalars['String']['output'];
  readonly rewards: Scalars['jsonb']['output'];
  readonly stat?: Maybe<Scalars['String']['output']>;
  readonly stat_scope?: Maybe<Scalars['String']['output']>;
  readonly stat_value?: Maybe<Scalars['Int']['output']>;
  readonly title: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly users_quests: ReadonlyArray<Users_Quests>;
  /** An aggregate relationship */
  readonly users_quests_aggregate: Users_Quests_Aggregate;
};


/** columns and relationships of "quests" */
export type QuestsRewardsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "quests" */
export type QuestsUsers_QuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


/** columns and relationships of "quests" */
export type QuestsUsers_Quests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};

/** aggregated selection of "quests" */
export type Quests_Aggregate = {
  readonly __typename?: 'quests_aggregate';
  readonly aggregate?: Maybe<Quests_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Quests>;
};

export type Quests_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Quests_Aggregate_Bool_Exp_Count>;
};

export type Quests_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Quests_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "quests" */
export type Quests_Aggregate_Fields = {
  readonly __typename?: 'quests_aggregate_fields';
  readonly avg?: Maybe<Quests_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Quests_Max_Fields>;
  readonly min?: Maybe<Quests_Min_Fields>;
  readonly stddev?: Maybe<Quests_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Quests_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Quests_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Quests_Sum_Fields>;
  readonly var_pop?: Maybe<Quests_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Quests_Var_Samp_Fields>;
  readonly variance?: Maybe<Quests_Variance_Fields>;
};


/** aggregate fields of "quests" */
export type Quests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "quests" */
export type Quests_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Quests_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Quests_Max_Order_By>;
  readonly min?: InputMaybe<Quests_Min_Order_By>;
  readonly stddev?: InputMaybe<Quests_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Quests_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Quests_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Quests_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Quests_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Quests_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Quests_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Quests_Avg_Fields = {
  readonly __typename?: 'quests_avg_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "quests" */
export type Quests_Avg_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "quests". All fields are combined with a logical 'AND'. */
export type Quests_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Quests_Bool_Exp>>;
  readonly _not?: InputMaybe<Quests_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Quests_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly icon?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly rewards?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly stat?: InputMaybe<String_Comparison_Exp>;
  readonly stat_scope?: InputMaybe<String_Comparison_Exp>;
  readonly stat_value?: InputMaybe<Int_Comparison_Exp>;
  readonly title?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly users_quests?: InputMaybe<Users_Quests_Bool_Exp>;
  readonly users_quests_aggregate?: InputMaybe<Users_Quests_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type Quests_Max_Fields = {
  readonly __typename?: 'quests_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly stat?: Maybe<Scalars['String']['output']>;
  readonly stat_scope?: Maybe<Scalars['String']['output']>;
  readonly stat_value?: Maybe<Scalars['Int']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "quests" */
export type Quests_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly expires_at?: InputMaybe<Order_By>;
  readonly icon?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly stat_scope?: InputMaybe<Order_By>;
  readonly stat_value?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Quests_Min_Fields = {
  readonly __typename?: 'quests_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly stat?: Maybe<Scalars['String']['output']>;
  readonly stat_scope?: Maybe<Scalars['String']['output']>;
  readonly stat_value?: Maybe<Scalars['Int']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "quests" */
export type Quests_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly expires_at?: InputMaybe<Order_By>;
  readonly icon?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly stat_scope?: InputMaybe<Order_By>;
  readonly stat_value?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "quests". */
export type Quests_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly completed_at?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly expires_at?: InputMaybe<Order_By>;
  readonly icon?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly rewards?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly stat_scope?: InputMaybe<Order_By>;
  readonly stat_value?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly users_quests_aggregate?: InputMaybe<Users_Quests_Aggregate_Order_By>;
};

/** select columns of table "quests" */
export enum Quests_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rewards = 'rewards',
  /** column name */
  Stat = 'stat',
  /** column name */
  StatScope = 'stat_scope',
  /** column name */
  StatValue = 'stat_value',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Quests_Stddev_Fields = {
  readonly __typename?: 'quests_stddev_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "quests" */
export type Quests_Stddev_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Quests_Stddev_Pop_Fields = {
  readonly __typename?: 'quests_stddev_pop_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "quests" */
export type Quests_Stddev_Pop_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Quests_Stddev_Samp_Fields = {
  readonly __typename?: 'quests_stddev_samp_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "quests" */
export type Quests_Stddev_Samp_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "quests" */
export type Quests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Quests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Quests_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly icon?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly rewards?: InputMaybe<Scalars['jsonb']['input']>;
  readonly stat?: InputMaybe<Scalars['String']['input']>;
  readonly stat_scope?: InputMaybe<Scalars['String']['input']>;
  readonly stat_value?: InputMaybe<Scalars['Int']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Quests_Sum_Fields = {
  readonly __typename?: 'quests_sum_fields';
  readonly stat_value?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "quests" */
export type Quests_Sum_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Quests_Var_Pop_Fields = {
  readonly __typename?: 'quests_var_pop_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "quests" */
export type Quests_Var_Pop_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Quests_Var_Samp_Fields = {
  readonly __typename?: 'quests_var_samp_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "quests" */
export type Quests_Var_Samp_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Quests_Variance_Fields = {
  readonly __typename?: 'quests_variance_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "quests" */
export type Quests_Variance_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** columns and relationships of "reports" */
export type Reports = {
  readonly __typename?: 'reports';
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly posts?: Maybe<Posts>;
  /** An object relationship */
  readonly reporter: Users;
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type Reports_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Reports_Bool_Exp>>;
  readonly _not?: InputMaybe<Reports_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Reports_Bool_Exp>>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly posts?: InputMaybe<Posts_Bool_Exp>;
  readonly reporter?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "reports" */
export enum Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey'
}

/** input type for inserting data into table "reports" */
export type Reports_Insert_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly posts?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "reports" */
export type Reports_Mutation_Response = {
  readonly __typename?: 'reports_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Reports>;
};

/** on_conflict condition type for table "reports" */
export type Reports_On_Conflict = {
  readonly constraint: Reports_Constraint;
  readonly update_columns?: ReadonlyArray<Reports_Update_Column>;
  readonly where?: InputMaybe<Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "reports". */
export type Reports_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly posts?: InputMaybe<Posts_Order_By>;
  readonly reporter?: InputMaybe<Users_Order_By>;
};

/** select columns of table "reports" */
export enum Reports_Select_Column {
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "reports" */
export type Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reports_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "reports" (current role has no relevant permissions) */
export enum Reports_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

export enum Room_Color {
  Blue = 'blue',
  Cyan = 'cyan',
  Green = 'green',
  Orange = 'orange',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  Teal = 'teal',
  Yellow = 'yellow'
}

export type Room_Color_Bool_Exp = {
  readonly _eq?: InputMaybe<Room_Color>;
  readonly _in?: InputMaybe<ReadonlyArray<Room_Color>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Room_Color>;
  readonly _nin?: InputMaybe<ReadonlyArray<Room_Color>>;
};

export enum Room_Kind {
  BreakoutContainer = 'breakout_container',
  Orientation = 'orientation',
  Standard = 'standard'
}

export type Room_Kind_Bool_Exp = {
  readonly _eq?: InputMaybe<Room_Kind>;
  readonly _in?: InputMaybe<ReadonlyArray<Room_Kind>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Room_Kind>;
  readonly _nin?: InputMaybe<ReadonlyArray<Room_Kind>>;
};

export enum Room_Privacy {
  Hidden = 'hidden',
  Private = 'private',
  Public = 'public'
}

export type Room_Privacy_Bool_Exp = {
  readonly _eq?: InputMaybe<Room_Privacy>;
  readonly _in?: InputMaybe<ReadonlyArray<Room_Privacy>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Room_Privacy>;
  readonly _nin?: InputMaybe<ReadonlyArray<Room_Privacy>>;
};

export enum Room_Status {
  Closed = 'closed',
  Open = 'open'
}

export type Room_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Room_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Room_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Room_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Room_Status>>;
};

/** columns and relationships of "rooms" */
export type Rooms = {
  readonly __typename?: 'rooms';
  readonly association_id: Scalars['String']['output'];
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly breakouts: ReadonlyArray<Breakouts>;
  /** An object relationship */
  readonly channel?: Maybe<Channels>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  readonly channels_users: ReadonlyArray<Channels_User>;
  /** An aggregate relationship */
  readonly channels_users_aggregate: Channels_User_Aggregate;
  readonly clipboard_prompt?: Maybe<Scalars['clipboard_prompt']['output']>;
  /** An object relationship */
  readonly club: Clubs;
  readonly club_id: Scalars['uuid']['output'];
  readonly color: Room_Color;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  readonly host_user_ids: Scalars['rooms_host_user_ids']['output'];
  readonly icon_url?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly kind: Room_Kind;
  /** An array relationship */
  readonly live_events: ReadonlyArray<Live_Events>;
  /** An aggregate relationship */
  readonly live_events_aggregate: Live_Events_Aggregate;
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly name: Scalars['String']['output'];
  /** An object relationship */
  readonly owner?: Maybe<Users>;
  readonly owner_id?: Maybe<Scalars['String']['output']>;
  readonly privacy: Room_Privacy;
  /** An object relationship */
  readonly rooms_schedule?: Maybe<Schedules>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly sort_order: Scalars['Int']['output'];
  /** A computed field, executes function "compute_room_status" */
  readonly status: Room_Status;
  readonly tagline: Scalars['String']['output'];
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly url_name: Scalars['String']['output'];
};


/** columns and relationships of "rooms" */
export type RoomsBreakoutsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Breakouts_Order_By>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsChannels_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_User_Order_By>>;
  where?: InputMaybe<Channels_User_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsChannels_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_User_Order_By>>;
  where?: InputMaybe<Channels_User_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsClipboard_PromptArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "rooms" */
export type RoomsHost_User_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "rooms" */
export type RoomsLive_EventsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsLive_Events_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};

/** aggregated selection of "rooms" */
export type Rooms_Aggregate = {
  readonly __typename?: 'rooms_aggregate';
  readonly aggregate?: Maybe<Rooms_Aggregate_Fields>;
  readonly nodes?: Maybe<ReadonlyArray<Rooms>>;
};

export type Rooms_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Rooms_Aggregate_Bool_Exp_Count>;
};

export type Rooms_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Rooms_Bool_Exp>;
  readonly predicate?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate fields of "rooms" */
export type Rooms_Aggregate_Fields = {
  readonly __typename?: 'rooms_aggregate_fields';
  readonly avg?: Maybe<Rooms_Avg_Fields>;
  readonly count?: Maybe<Scalars['Int']['output']>;
  readonly max?: Maybe<Rooms_Max_Fields>;
  readonly min?: Maybe<Rooms_Min_Fields>;
  readonly stddev?: Maybe<Rooms_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Rooms_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Rooms_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Rooms_Sum_Fields>;
  readonly var_pop?: Maybe<Rooms_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Rooms_Var_Samp_Fields>;
  readonly variance?: Maybe<Rooms_Variance_Fields>;
};


/** aggregate fields of "rooms" */
export type Rooms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rooms" */
export type Rooms_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Rooms_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Rooms_Max_Order_By>;
  readonly min?: InputMaybe<Rooms_Min_Order_By>;
  readonly stddev?: InputMaybe<Rooms_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Rooms_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Rooms_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Rooms_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Rooms_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Rooms_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Rooms_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rooms_Append_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "rooms" */
export type Rooms_Arr_Rel_Insert_Input = {
  readonly data?: InputMaybe<ReadonlyArray<Rooms_Insert_Input>>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** aggregate avg on columns */
export type Rooms_Avg_Fields = {
  readonly __typename?: 'rooms_avg_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rooms" */
export type Rooms_Avg_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rooms". All fields are combined with a logical 'AND'. */
export type Rooms_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Rooms_Bool_Exp>>;
  readonly _not?: InputMaybe<Rooms_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Rooms_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly background_image_url?: InputMaybe<String_Comparison_Exp>;
  readonly breakouts?: InputMaybe<Breakouts_Bool_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly channels_users?: InputMaybe<Channels_User_Bool_Exp>;
  readonly channels_users_aggregate?: InputMaybe<Channels_User_Aggregate_Bool_Exp>;
  readonly clipboard_prompt?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly color?: InputMaybe<Room_Color_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly feedback_question?: InputMaybe<String_Comparison_Exp>;
  readonly host_user_ids?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly icon_url?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly kind?: InputMaybe<Room_Kind_Bool_Exp>;
  readonly live_events?: InputMaybe<Live_Events_Bool_Exp>;
  readonly live_events_aggregate?: InputMaybe<Live_Events_Aggregate_Bool_Exp>;
  readonly lock_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly owner?: InputMaybe<Users_Bool_Exp>;
  readonly owner_id?: InputMaybe<String_Comparison_Exp>;
  readonly privacy?: InputMaybe<Room_Privacy_Bool_Exp>;
  readonly rooms_schedule?: InputMaybe<Schedules_Bool_Exp>;
  readonly schedule_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly sort_order?: InputMaybe<Int_Comparison_Exp>;
  readonly status?: InputMaybe<Room_Status_Bool_Exp>;
  readonly tagline?: InputMaybe<String_Comparison_Exp>;
  readonly unlock_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly url_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rooms" */
export enum Rooms_Constraint {
  /** unique or primary key constraint on columns "association_id" */
  RoomsAssociationIdKey = 'rooms_association_id_key',
  /** unique or primary key constraint on columns "club_id", "url_name" */
  RoomsClubIdUrlNameKey = 'rooms_club_id_url_name_key',
  /** unique or primary key constraint on columns "id" */
  RoomsPkey = 'rooms_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rooms_Delete_At_Path_Input = {
  readonly clipboard_prompt?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly host_user_ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rooms_Delete_Elem_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['Int']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rooms_Delete_Key_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['String']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "rooms" */
export type Rooms_Inc_Input = {
  readonly sort_order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "rooms" */
export type Rooms_Insert_Input = {
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly breakouts?: InputMaybe<Breakouts_Arr_Rel_Insert_Input>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly channels_users?: InputMaybe<Channels_User_Arr_Rel_Insert_Input>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly color?: InputMaybe<Room_Color>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['rooms_host_user_ids']['input']>;
  readonly icon_url?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly kind?: InputMaybe<Room_Kind>;
  readonly live_events?: InputMaybe<Live_Events_Arr_Rel_Insert_Input>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly owner_id?: InputMaybe<Scalars['String']['input']>;
  readonly privacy?: InputMaybe<Room_Privacy>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Room_Status>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rooms_Max_Fields = {
  readonly __typename?: 'rooms_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  readonly icon_url?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly kind?: Maybe<Scalars['String']['output']>;
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly owner_id?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly sort_order?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "compute_room_status" */
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rooms" */
export type Rooms_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly icon_url?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly owner_id?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rooms_Min_Fields = {
  readonly __typename?: 'rooms_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  readonly icon_url?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly kind?: Maybe<Scalars['String']['output']>;
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly owner_id?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly sort_order?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "compute_room_status" */
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rooms" */
export type Rooms_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly icon_url?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly owner_id?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rooms" */
export type Rooms_Mutation_Response = {
  readonly __typename?: 'rooms_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  readonly returning?: Maybe<ReadonlyArray<Rooms>>;
};

/** input type for inserting object relation for remote table "rooms" */
export type Rooms_Obj_Rel_Insert_Input = {
  readonly data?: InputMaybe<Rooms_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** on_conflict condition type for table "rooms" */
export type Rooms_On_Conflict = {
  readonly constraint?: InputMaybe<Rooms_Constraint>;
  readonly update_columns?: InputMaybe<ReadonlyArray<Rooms_Update_Column>>;
  readonly where?: InputMaybe<Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "rooms". */
export type Rooms_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly breakouts_aggregate?: InputMaybe<Breakouts_Aggregate_Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly channels_users_aggregate?: InputMaybe<Channels_User_Aggregate_Order_By>;
  readonly clipboard_prompt?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly host_user_ids?: InputMaybe<Order_By>;
  readonly icon_url?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly live_events_aggregate?: InputMaybe<Live_Events_Aggregate_Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly owner?: InputMaybe<Users_Order_By>;
  readonly owner_id?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly rooms_schedule?: InputMaybe<Schedules_Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rooms */
export type Rooms_Pk_Columns_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rooms_Prepend_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "rooms" */
export enum Rooms_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  BackgroundImageUrl = 'background_image_url',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedbackQuestion = 'feedback_question',
  /** column name */
  HostUserIds = 'host_user_ids',
  /** column name */
  IconUrl = 'icon_url',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  LockTime = 'lock_time',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  UnlockTime = 'unlock_time',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlName = 'url_name'
}

/** input type for updating data in table "rooms" */
export type Rooms_Set_Input = {
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly color?: InputMaybe<Room_Color>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['rooms_host_user_ids']['input']>;
  readonly icon_url?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<Room_Kind>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly privacy?: InputMaybe<Room_Privacy>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Room_Status>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rooms_Stddev_Fields = {
  readonly __typename?: 'rooms_stddev_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rooms" */
export type Rooms_Stddev_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rooms_Stddev_Pop_Fields = {
  readonly __typename?: 'rooms_stddev_pop_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rooms" */
export type Rooms_Stddev_Pop_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rooms_Stddev_Samp_Fields = {
  readonly __typename?: 'rooms_stddev_samp_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rooms" */
export type Rooms_Stddev_Samp_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rooms" */
export type Rooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Rooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rooms_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly color?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  readonly icon_url?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly kind?: InputMaybe<Scalars['String']['input']>;
  readonly lock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly owner_id?: InputMaybe<Scalars['String']['input']>;
  readonly privacy?: InputMaybe<Scalars['String']['input']>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['Int']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly unlock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Rooms_Sum_Fields = {
  readonly __typename?: 'rooms_sum_fields';
  readonly sort_order?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "rooms" */
export type Rooms_Sum_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** update columns of table "rooms" */
export enum Rooms_Update_Column {
  /** column name */
  BackgroundImageUrl = 'background_image_url',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Color = 'color',
  /** column name */
  FeedbackQuestion = 'feedback_question',
  /** column name */
  HostUserIds = 'host_user_ids',
  /** column name */
  IconUrl = 'icon_url',
  /** column name */
  Name = 'name',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  UrlName = 'url_name'
}

export type Rooms_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Rooms_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Rooms_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Rooms_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Rooms_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Rooms_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Rooms_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Rooms_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where?: InputMaybe<Rooms_Bool_Exp>;
};

/** aggregate var_pop on columns */
export type Rooms_Var_Pop_Fields = {
  readonly __typename?: 'rooms_var_pop_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rooms" */
export type Rooms_Var_Pop_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rooms_Var_Samp_Fields = {
  readonly __typename?: 'rooms_var_samp_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rooms" */
export type Rooms_Var_Samp_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rooms_Variance_Fields = {
  readonly __typename?: 'rooms_variance_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rooms" */
export type Rooms_Variance_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** Responsible for controlling the schedules for club rooms */
export type Schedules = {
  readonly __typename?: 'schedules';
  readonly id: Scalars['uuid']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly schedule: Scalars['schedules_schedule']['output'];
  readonly timezone: Scalars['String']['output'];
};


/** Responsible for controlling the schedules for club rooms */
export type SchedulesScheduleArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "schedules". All fields are combined with a logical 'AND'. */
export type Schedules_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Schedules_Bool_Exp>>;
  readonly _not?: InputMaybe<Schedules_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Schedules_Bool_Exp>>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly schedule?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly timezone?: InputMaybe<String_Comparison_Exp>;
};

export type Schedules_Insert_Input = {
  readonly schedule?: InputMaybe<Scalars['schedules_schedule']['input']>;
};

/** Ordering options when selecting data from "schedules". */
export type Schedules_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly schedule?: InputMaybe<Order_By>;
  readonly timezone?: InputMaybe<Order_By>;
};

/** select columns of table "schedules" */
export enum Schedules_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Timezone = 'timezone'
}

export type Schedules_Set_Input = {
  readonly schedule?: InputMaybe<Scalars['schedules_schedule']['input']>;
};

/** Streaming cursor of the table "schedules" */
export type Schedules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Schedules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Schedules_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly schedule?: InputMaybe<Scalars['jsonb']['input']>;
  readonly timezone?: InputMaybe<Scalars['String']['input']>;
};

export type SendPasswordResetOutput = {
  readonly __typename?: 'sendPasswordResetOutput';
  readonly success?: Maybe<Scalars['Boolean']['output']>;
};

export type Subscription_Root = {
  readonly __typename?: 'subscription_root';
  /** An array relationship */
  readonly accounts: ReadonlyArray<Accounts>;
  /** fetch data from the table: "accounts" using primary key columns */
  readonly accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table in a streaming manner: "accounts" */
  readonly accounts_stream: ReadonlyArray<Accounts>;
  /** fetch data from the table: "avatars" */
  readonly avatars: ReadonlyArray<Avatars>;
  /** fetch data from the table in a streaming manner: "avatars" */
  readonly avatars_stream: ReadonlyArray<Avatars>;
  /** An array relationship */
  readonly badges: ReadonlyArray<Badges>;
  /** fetch data from the table in a streaming manner: "badges" */
  readonly badges_stream: ReadonlyArray<Badges>;
  /** An array relationship */
  readonly breakouts: ReadonlyArray<Breakouts>;
  /** fetch data from the table: "breakouts" using primary key columns */
  readonly breakouts_by_pk?: Maybe<Breakouts>;
  /** fetch data from the table in a streaming manner: "breakouts" */
  readonly breakouts_stream: ReadonlyArray<Breakouts>;
  /** fetch data from the table: "channels" */
  readonly channels: ReadonlyArray<Channels>;
  /** fetch aggregated fields from the table: "channels" */
  readonly channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  readonly channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table: "channels_events" */
  readonly channels_events: ReadonlyArray<Channels_Events>;
  /** fetch data from the table: "channels_events" using primary key columns */
  readonly channels_events_by_pk?: Maybe<Channels_Events>;
  /** fetch data from the table in a streaming manner: "channels_events" */
  readonly channels_events_stream: ReadonlyArray<Channels_Events>;
  /** fetch data from the table in a streaming manner: "channels" */
  readonly channels_stream: ReadonlyArray<Channels>;
  /** fetch data from the table: "channels_user" */
  readonly channels_user: ReadonlyArray<Channels_User>;
  /** fetch aggregated fields from the table: "channels_user" */
  readonly channels_user_aggregate: Channels_User_Aggregate;
  /** fetch data from the table: "channels_user" using primary key columns */
  readonly channels_user_by_pk?: Maybe<Channels_User>;
  /** fetch data from the table in a streaming manner: "channels_user" */
  readonly channels_user_stream: ReadonlyArray<Channels_User>;
  /** An array relationship */
  readonly chats: ReadonlyArray<Chats>;
  /** An aggregate relationship */
  readonly chats_aggregate: Chats_Aggregate;
  /** fetch data from the table: "chats" using primary key columns */
  readonly chats_by_pk?: Maybe<Chats>;
  /** fetch data from the table in a streaming manner: "chats" */
  readonly chats_stream: ReadonlyArray<Chats>;
  /** An array relationship */
  readonly clubs: ReadonlyArray<Clubs>;
  /** An aggregate relationship */
  readonly clubs_aggregate: Clubs_Aggregate;
  /** fetch data from the table: "clubs" using primary key columns */
  readonly clubs_by_pk?: Maybe<Clubs>;
  /** fetch data from the table: "clubs_members" */
  readonly clubs_members: ReadonlyArray<Clubs_Members>;
  /** fetch data from the table: "clubs_members" using primary key columns */
  readonly clubs_members_by_pk?: Maybe<Clubs_Members>;
  /** fetch data from the table in a streaming manner: "clubs_members" */
  readonly clubs_members_stream: ReadonlyArray<Clubs_Members>;
  /** fetch data from the table in a streaming manner: "clubs" */
  readonly clubs_stream: ReadonlyArray<Clubs>;
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  readonly comments_by_pk?: Maybe<Comments>;
  /** fetch data from the table in a streaming manner: "comments" */
  readonly comments_stream: ReadonlyArray<Comments>;
  /** An array relationship */
  readonly comments_votes: ReadonlyArray<Comments_Votes>;
  /** An aggregate relationship */
  readonly comments_votes_aggregate: Comments_Votes_Aggregate;
  /** fetch data from the table: "comments_votes" using primary key columns */
  readonly comments_votes_by_pk?: Maybe<Comments_Votes>;
  /** fetch data from the table in a streaming manner: "comments_votes" */
  readonly comments_votes_stream: ReadonlyArray<Comments_Votes>;
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Aggregate;
  /** fetch data from the table: "competitions" using primary key columns */
  readonly competitions_by_pk?: Maybe<Competitions>;
  /** fetch data from the table: "competitions_judges" */
  readonly competitions_judges: ReadonlyArray<Competitions_Judges>;
  /** fetch data from the table: "competitions_judges" using primary key columns */
  readonly competitions_judges_by_pk?: Maybe<Competitions_Judges>;
  /** fetch data from the table in a streaming manner: "competitions_judges" */
  readonly competitions_judges_stream: ReadonlyArray<Competitions_Judges>;
  /** fetch data from the table in a streaming manner: "competitions" */
  readonly competitions_stream: ReadonlyArray<Competitions>;
  /** fetch data from the table: "competitions_tags" */
  readonly competitions_tags: ReadonlyArray<Competitions_Tags>;
  /** fetch data from the table: "competitions_tags" using primary key columns */
  readonly competitions_tags_by_pk?: Maybe<Competitions_Tags>;
  /** fetch data from the table in a streaming manner: "competitions_tags" */
  readonly competitions_tags_stream: ReadonlyArray<Competitions_Tags>;
  /** fetch data from the table: "competitions_tutorials" */
  readonly competitions_tutorials: ReadonlyArray<Competitions_Tutorials>;
  /** fetch data from the table: "competitions_tutorials" using primary key columns */
  readonly competitions_tutorials_by_pk?: Maybe<Competitions_Tutorials>;
  /** fetch data from the table in a streaming manner: "competitions_tutorials" */
  readonly competitions_tutorials_stream: ReadonlyArray<Competitions_Tutorials>;
  /** fetch data from the table: "features" */
  readonly features: ReadonlyArray<Features>;
  /** fetch data from the table in a streaming manner: "features" */
  readonly features_stream: ReadonlyArray<Features>;
  /** An array relationship */
  readonly feedback: ReadonlyArray<Feedback>;
  /** fetch data from the table: "feedback" using primary key columns */
  readonly feedback_by_pk?: Maybe<Feedback>;
  /** fetch data from the table in a streaming manner: "feedback" */
  readonly feedback_stream: ReadonlyArray<Feedback>;
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  /** fetch data from the table: "feeds" using primary key columns */
  readonly feeds_by_pk?: Maybe<Feeds>;
  /** fetch data from the table in a streaming manner: "feeds" */
  readonly feeds_stream: ReadonlyArray<Feeds>;
  /** fetch data from the table: "follows" */
  readonly follows: ReadonlyArray<Follows>;
  /** fetch aggregated fields from the table: "follows" */
  readonly follows_aggregate: Follows_Aggregate;
  /** fetch data from the table: "follows" using primary key columns */
  readonly follows_by_pk?: Maybe<Follows>;
  /** fetch data from the table in a streaming manner: "follows" */
  readonly follows_stream: ReadonlyArray<Follows>;
  /** fetch data from the table: "friendships" */
  readonly friendships: ReadonlyArray<Friendships>;
  /** fetch data from the table: "friendships" using primary key columns */
  readonly friendships_by_pk?: Maybe<Friendships>;
  /** fetch data from the table in a streaming manner: "friendships" */
  readonly friendships_stream: ReadonlyArray<Friendships>;
  /** An array relationship */
  readonly friendships_users: ReadonlyArray<Friendships_Users>;
  /** fetch data from the table: "friendships_users" using primary key columns */
  readonly friendships_users_by_pk?: Maybe<Friendships_Users>;
  /** fetch data from the table in a streaming manner: "friendships_users" */
  readonly friendships_users_stream: ReadonlyArray<Friendships_Users>;
  /** fetch data from the table: "items" */
  readonly items: ReadonlyArray<Items>;
  /** fetch data from the table: "items" using primary key columns */
  readonly items_by_pk?: Maybe<Items>;
  /** fetch data from the table in a streaming manner: "items" */
  readonly items_stream: ReadonlyArray<Items>;
  /** fetch data from the table: "items_tags" */
  readonly items_tags: ReadonlyArray<Items_Tags>;
  /** fetch data from the table: "items_tags" using primary key columns */
  readonly items_tags_by_pk?: Maybe<Items_Tags>;
  /** fetch data from the table in a streaming manner: "items_tags" */
  readonly items_tags_stream: ReadonlyArray<Items_Tags>;
  /** fetch data from the table: "levels" */
  readonly levels: ReadonlyArray<Levels>;
  /** fetch data from the table: "levels" using primary key columns */
  readonly levels_by_pk?: Maybe<Levels>;
  /** fetch data from the table in a streaming manner: "levels" */
  readonly levels_stream: ReadonlyArray<Levels>;
  /** An array relationship */
  readonly live_events: ReadonlyArray<Live_Events>;
  /** An aggregate relationship */
  readonly live_events_aggregate: Live_Events_Aggregate;
  /** fetch data from the table: "live_events" using primary key columns */
  readonly live_events_by_pk?: Maybe<Live_Events>;
  /** fetch data from the table: "live_events_hosts" */
  readonly live_events_hosts: ReadonlyArray<Live_Events_Hosts>;
  /** fetch aggregated fields from the table: "live_events_hosts" */
  readonly live_events_hosts_aggregate: Live_Events_Hosts_Aggregate;
  /** fetch data from the table: "live_events_hosts" using primary key columns */
  readonly live_events_hosts_by_pk?: Maybe<Live_Events_Hosts>;
  /** fetch data from the table in a streaming manner: "live_events_hosts" */
  readonly live_events_hosts_stream: ReadonlyArray<Live_Events_Hosts>;
  /** fetch data from the table in a streaming manner: "live_events" */
  readonly live_events_stream: ReadonlyArray<Live_Events>;
  /** An array relationship */
  readonly notifications: ReadonlyArray<Notifications>;
  /** An aggregate relationship */
  readonly notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  readonly notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  readonly notifications_stream: ReadonlyArray<Notifications>;
  /** An array relationship */
  readonly orders: ReadonlyArray<Orders>;
  /** An aggregate relationship */
  readonly orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  readonly orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table in a streaming manner: "orders" */
  readonly orders_stream: ReadonlyArray<Orders>;
  /** fetch data from the table: "pages" */
  readonly pages?: Maybe<ReadonlyArray<Pages>>;
  /** fetch data from the table: "pages" using primary key columns */
  readonly pages_by_pk?: Maybe<Pages>;
  /** fetch data from the table in a streaming manner: "pages" */
  readonly pages_stream?: Maybe<ReadonlyArray<Pages>>;
  /** An array relationship */
  readonly posts: ReadonlyArray<Posts>;
  /** fetch data from the table: "posts" using primary key columns */
  readonly posts_by_pk?: Maybe<Posts>;
  /** fetch data from the table in a streaming manner: "posts" */
  readonly posts_stream: ReadonlyArray<Posts>;
  /** An array relationship */
  readonly projects: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  readonly projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "projects_collaborators" */
  readonly projects_collaborators: ReadonlyArray<Projects_Collaborators>;
  /** fetch aggregated fields from the table: "projects_collaborators" */
  readonly projects_collaborators_aggregate: Projects_Collaborators_Aggregate;
  /** fetch data from the table: "projects_collaborators" using primary key columns */
  readonly projects_collaborators_by_pk?: Maybe<Projects_Collaborators>;
  /** fetch data from the table in a streaming manner: "projects_collaborators" */
  readonly projects_collaborators_stream: ReadonlyArray<Projects_Collaborators>;
  /** fetch data from the table: "projects_editables" */
  readonly projects_editables: ReadonlyArray<Projects_Editables>;
  /** fetch data from the table in a streaming manner: "projects_editables" */
  readonly projects_editables_stream: ReadonlyArray<Projects_Editables>;
  /** fetch data from the table in a streaming manner: "projects" */
  readonly projects_stream: ReadonlyArray<Projects>;
  /** fetch data from the table: "prospective_users" */
  readonly prospective_users: ReadonlyArray<Prospective_Users>;
  /** fetch data from the table: "prospective_users" using primary key columns */
  readonly prospective_users_by_pk?: Maybe<Prospective_Users>;
  /** An array relationship */
  readonly prospective_users_purchases: ReadonlyArray<Prospective_Users_Purchases>;
  /** fetch data from the table: "prospective_users_purchases" using primary key columns */
  readonly prospective_users_purchases_by_pk?: Maybe<Prospective_Users_Purchases>;
  /** fetch data from the table in a streaming manner: "prospective_users_purchases" */
  readonly prospective_users_purchases_stream: ReadonlyArray<Prospective_Users_Purchases>;
  /** fetch data from the table in a streaming manner: "prospective_users" */
  readonly prospective_users_stream: ReadonlyArray<Prospective_Users>;
  /** fetch data from the table: "public_users" */
  readonly public_users: ReadonlyArray<Public_Users>;
  /** fetch data from the table in a streaming manner: "public_users" */
  readonly public_users_stream: ReadonlyArray<Public_Users>;
  /** An array relationship */
  readonly quests: ReadonlyArray<Quests>;
  /** An aggregate relationship */
  readonly quests_aggregate: Quests_Aggregate;
  /** fetch data from the table: "quests" using primary key columns */
  readonly quests_by_pk?: Maybe<Quests>;
  /** fetch data from the table in a streaming manner: "quests" */
  readonly quests_stream: ReadonlyArray<Quests>;
  /** fetch data from the table: "reports" */
  readonly reports: ReadonlyArray<Reports>;
  /** fetch data from the table: "reports" using primary key columns */
  readonly reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  readonly reports_stream: ReadonlyArray<Reports>;
  /** An array relationship */
  readonly rooms: ReadonlyArray<Rooms>;
  /** An aggregate relationship */
  readonly rooms_aggregate?: Maybe<Rooms_Aggregate>;
  /** fetch data from the table: "rooms" using primary key columns */
  readonly rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table in a streaming manner: "rooms" */
  readonly rooms_stream: ReadonlyArray<Rooms>;
  /** fetch data from the table: "schedules" */
  readonly schedules: ReadonlyArray<Schedules>;
  /** fetch data from the table: "schedules" using primary key columns */
  readonly schedules_by_pk?: Maybe<Schedules>;
  /** fetch data from the table in a streaming manner: "schedules" */
  readonly schedules_stream: ReadonlyArray<Schedules>;
  /** An array relationship */
  readonly subscriptions: ReadonlyArray<Subscriptions>;
  /** fetch data from the table: "subscriptions" using primary key columns */
  readonly subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table in a streaming manner: "subscriptions" */
  readonly subscriptions_stream: ReadonlyArray<Subscriptions>;
  /** fetch data from the table: "tags" */
  readonly tags: ReadonlyArray<Tags>;
  /** fetch data from the table: "tags_associations" */
  readonly tags_associations: ReadonlyArray<Tags_Associations>;
  /** fetch data from the table: "tags_associations" using primary key columns */
  readonly tags_associations_by_pk?: Maybe<Tags_Associations>;
  /** fetch data from the table in a streaming manner: "tags_associations" */
  readonly tags_associations_stream: ReadonlyArray<Tags_Associations>;
  /** fetch data from the table: "tags" using primary key columns */
  readonly tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "tags" */
  readonly tags_stream: ReadonlyArray<Tags>;
  /** An array relationship */
  readonly themes: ReadonlyArray<Themes>;
  /** fetch data from the table: "themes" using primary key columns */
  readonly themes_by_pk?: Maybe<Themes>;
  /** fetch data from the table in a streaming manner: "themes" */
  readonly themes_stream: ReadonlyArray<Themes>;
  /** fetch data from the table: "tools" */
  readonly tools: ReadonlyArray<Tools>;
  /** fetch data from the table: "tools" using primary key columns */
  readonly tools_by_pk?: Maybe<Tools>;
  /** fetch data from the table in a streaming manner: "tools" */
  readonly tools_stream: ReadonlyArray<Tools>;
  /** fetch data from the table: "topics" */
  readonly topics: ReadonlyArray<Topics>;
  /** fetch data from the table: "topics" using primary key columns */
  readonly topics_by_pk?: Maybe<Topics>;
  /** fetch data from the table in a streaming manner: "topics" */
  readonly topics_stream: ReadonlyArray<Topics>;
  /** fetch data from the table: "topics_tools" */
  readonly topics_tools: ReadonlyArray<Topics_Tools>;
  /** fetch data from the table: "topics_tools" using primary key columns */
  readonly topics_tools_by_pk?: Maybe<Topics_Tools>;
  /** fetch data from the table in a streaming manner: "topics_tools" */
  readonly topics_tools_stream: ReadonlyArray<Topics_Tools>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table in a streaming manner: "transactions" */
  readonly transactions_stream: ReadonlyArray<Transactions>;
  /** An array relationship */
  readonly tutorials: ReadonlyArray<Tutorials>;
  /** fetch data from the table: "tutorials" using primary key columns */
  readonly tutorials_by_pk?: Maybe<Tutorials>;
  /** fetch data from the table in a streaming manner: "tutorials" */
  readonly tutorials_stream: ReadonlyArray<Tutorials>;
  /** fetch data from the table: "tutorials_tags" */
  readonly tutorials_tags: ReadonlyArray<Tutorials_Tags>;
  /** fetch data from the table: "tutorials_tags" using primary key columns */
  readonly tutorials_tags_by_pk?: Maybe<Tutorials_Tags>;
  /** fetch data from the table in a streaming manner: "tutorials_tags" */
  readonly tutorials_tags_stream: ReadonlyArray<Tutorials_Tags>;
  /** An array relationship */
  readonly user_invites: ReadonlyArray<User_Invites>;
  /** fetch data from the table: "user_invites" using primary key columns */
  readonly user_invites_by_pk?: Maybe<User_Invites>;
  /** fetch data from the table in a streaming manner: "user_invites" */
  readonly user_invites_stream: ReadonlyArray<User_Invites>;
  /** fetch data from the table: "user_presence" */
  readonly user_presence: ReadonlyArray<User_Presence>;
  /** fetch data from the table: "user_presence" using primary key columns */
  readonly user_presence_by_pk?: Maybe<User_Presence>;
  /** fetch data from the table in a streaming manner: "user_presence" */
  readonly user_presence_stream: ReadonlyArray<User_Presence>;
  /** fetch data from the table: "users" */
  readonly users: ReadonlyArray<Users>;
  /** fetch data from the table: "users_actions" */
  readonly users_actions: ReadonlyArray<Users_Actions>;
  /** fetch data from the table: "users_actions" using primary key columns */
  readonly users_actions_by_pk?: Maybe<Users_Actions>;
  /** fetch data from the table in a streaming manner: "users_actions" */
  readonly users_actions_stream: ReadonlyArray<Users_Actions>;
  /** An array relationship */
  readonly users_badges: ReadonlyArray<Users_Badges>;
  /** fetch data from the table: "users_badges" using primary key columns */
  readonly users_badges_by_pk?: Maybe<Users_Badges>;
  /** fetch data from the table in a streaming manner: "users_badges" */
  readonly users_badges_stream: ReadonlyArray<Users_Badges>;
  /** fetch data from the table: "users" using primary key columns */
  readonly users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_features" */
  readonly users_features: ReadonlyArray<Users_Features>;
  /** fetch data from the table in a streaming manner: "users_features" */
  readonly users_features_stream: ReadonlyArray<Users_Features>;
  /** An array relationship */
  readonly users_notifications: ReadonlyArray<Users_Notifications>;
  /** An aggregate relationship */
  readonly users_notifications_aggregate: Users_Notifications_Aggregate;
  /** fetch data from the table: "users_notifications" using primary key columns */
  readonly users_notifications_by_pk?: Maybe<Users_Notifications>;
  /** fetch data from the table in a streaming manner: "users_notifications" */
  readonly users_notifications_stream: ReadonlyArray<Users_Notifications>;
  /** fetch data from the table: "users_presence" */
  readonly users_presence: ReadonlyArray<Users_Presence>;
  /** fetch aggregated fields from the table: "users_presence" */
  readonly users_presence_aggregate: Users_Presence_Aggregate;
  /** fetch data from the table in a streaming manner: "users_presence" */
  readonly users_presence_stream: ReadonlyArray<Users_Presence>;
  /** An array relationship */
  readonly users_quests: ReadonlyArray<Users_Quests>;
  /** An aggregate relationship */
  readonly users_quests_aggregate: Users_Quests_Aggregate;
  /** fetch data from the table: "users_quests" using primary key columns */
  readonly users_quests_by_pk?: Maybe<Users_Quests>;
  /** fetch data from the table in a streaming manner: "users_quests" */
  readonly users_quests_stream: ReadonlyArray<Users_Quests>;
  /** fetch data from the table: "users_stats" */
  readonly users_stats: ReadonlyArray<Users_Stats>;
  /** fetch data from the table: "users_stats" using primary key columns */
  readonly users_stats_by_pk?: Maybe<Users_Stats>;
  /** fetch data from the table in a streaming manner: "users_stats" */
  readonly users_stats_stream: ReadonlyArray<Users_Stats>;
  /** fetch data from the table in a streaming manner: "users" */
  readonly users_stream: ReadonlyArray<Users>;
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
  /** fetch data from the table: "votes" using primary key columns */
  readonly votes_by_pk?: Maybe<Votes>;
  /** fetch data from the table in a streaming manner: "votes" */
  readonly votes_stream: ReadonlyArray<Votes>;
};


export type Subscription_RootAccountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  id: Scalars['String']['input'];
};


export type Subscription_RootAccounts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAvatarsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Avatars_Order_By>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Subscription_RootAvatars_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Avatars_Stream_Cursor_Input>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Subscription_RootBadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBadges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Badges_Stream_Cursor_Input>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBreakoutsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Breakouts_Order_By>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


export type Subscription_RootBreakouts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBreakouts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Breakouts_Stream_Cursor_Input>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


export type Subscription_RootChannelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootChannels_EventsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Events_Order_By>>;
  where?: InputMaybe<Channels_Events_Bool_Exp>;
};


export type Subscription_RootChannels_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootChannels_Events_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Channels_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Events_Bool_Exp>;
};


export type Subscription_RootChannels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Channels_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_UserArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_User_Order_By>>;
  where?: InputMaybe<Channels_User_Bool_Exp>;
};


export type Subscription_RootChannels_User_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_User_Order_By>>;
  where?: InputMaybe<Channels_User_Bool_Exp>;
};


export type Subscription_RootChannels_User_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootChannels_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Channels_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_User_Bool_Exp>;
};


export type Subscription_RootChatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Subscription_RootChats_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Subscription_RootChats_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootChats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Chats_Stream_Cursor_Input>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Subscription_RootClubsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


export type Subscription_RootClubs_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


export type Subscription_RootClubs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootClubs_MembersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


export type Subscription_RootClubs_Members_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootClubs_Members_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Clubs_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


export type Subscription_RootClubs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Clubs_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


export type Subscription_RootCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootComments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootComments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootComments_VotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


export type Subscription_RootComments_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


export type Subscription_RootComments_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootComments_Votes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Comments_Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


export type Subscription_RootCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


export type Subscription_RootCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


export type Subscription_RootCompetitions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootCompetitions_JudgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


export type Subscription_RootCompetitions_Judges_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  judge_id: Scalars['String']['input'];
};


export type Subscription_RootCompetitions_Judges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Competitions_Judges_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


export type Subscription_RootCompetitions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Competitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


export type Subscription_RootCompetitions_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


export type Subscription_RootCompetitions_Tags_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


export type Subscription_RootCompetitions_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Competitions_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


export type Subscription_RootCompetitions_TutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


export type Subscription_RootCompetitions_Tutorials_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  tutorial_id: Scalars['String']['input'];
};


export type Subscription_RootCompetitions_Tutorials_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Competitions_Tutorials_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


export type Subscription_RootFeaturesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Order_By>>;
  where?: InputMaybe<Features_Bool_Exp>;
};


export type Subscription_RootFeatures_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Features_Stream_Cursor_Input>>;
  where?: InputMaybe<Features_Bool_Exp>;
};


export type Subscription_RootFeedbackArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedback_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFeedback_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Feedback_Stream_Cursor_Input>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


export type Subscription_RootFeeds_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFeeds_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Feeds_Stream_Cursor_Input>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


export type Subscription_RootFollowsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Subscription_RootFollows_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Subscription_RootFollows_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFollows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Follows_Stream_Cursor_Input>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Subscription_RootFriendshipsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Order_By>>;
  where?: InputMaybe<Friendships_Bool_Exp>;
};


export type Subscription_RootFriendships_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFriendships_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Friendships_Stream_Cursor_Input>>;
  where?: InputMaybe<Friendships_Bool_Exp>;
};


export type Subscription_RootFriendships_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


export type Subscription_RootFriendships_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFriendships_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Friendships_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


export type Subscription_RootItemsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootItems_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Tags_Order_By>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


export type Subscription_RootItems_Tags_By_PkArgs = {
  item_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


export type Subscription_RootItems_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Items_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


export type Subscription_RootLevelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootLevels_By_PkArgs = {
  level: Scalars['Int']['input'];
};


export type Subscription_RootLevels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootLive_EventsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


export type Subscription_RootLive_Events_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


export type Subscription_RootLive_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootLive_Events_HostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


export type Subscription_RootLive_Events_Hosts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


export type Subscription_RootLive_Events_Hosts_By_PkArgs = {
  live_event_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootLive_Events_Hosts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Live_Events_Hosts_Stream_Cursor_Input>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


export type Subscription_RootLive_Events_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Live_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootOrdersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootOrders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Subscription_RootPagesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Pages_Order_By>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Subscription_RootPages_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Pages_Stream_Cursor_Input>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Subscription_RootPostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootPosts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPosts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Posts_Stream_Cursor_Input>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootProjects_CollaboratorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


export type Subscription_RootProjects_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


export type Subscription_RootProjects_Collaborators_By_PkArgs = {
  collaborator_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
};


export type Subscription_RootProjects_Collaborators_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Projects_Collaborators_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


export type Subscription_RootProjects_EditablesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Editables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Editables_Order_By>>;
  where?: InputMaybe<Projects_Editables_Bool_Exp>;
};


export type Subscription_RootProjects_Editables_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Projects_Editables_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Editables_Bool_Exp>;
};


export type Subscription_RootProjects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProspective_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Order_By>>;
  where?: InputMaybe<Prospective_Users_Bool_Exp>;
};


export type Subscription_RootProspective_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProspective_Users_PurchasesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


export type Subscription_RootProspective_Users_Purchases_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  prospective_user_id: Scalars['uuid']['input'];
};


export type Subscription_RootProspective_Users_Purchases_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Prospective_Users_Purchases_Stream_Cursor_Input>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


export type Subscription_RootProspective_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Prospective_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Prospective_Users_Bool_Exp>;
};


export type Subscription_RootPublic_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Public_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Public_Users_Order_By>>;
  where?: InputMaybe<Public_Users_Bool_Exp>;
};


export type Subscription_RootPublic_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Public_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Public_Users_Bool_Exp>;
};


export type Subscription_RootQuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


export type Subscription_RootQuests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


export type Subscription_RootQuests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootQuests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Quests_Stream_Cursor_Input>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


export type Subscription_RootReportsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootReports_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootRoomsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Subscription_RootRooms_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Subscription_RootRooms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRooms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Rooms_Stream_Cursor_Input>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Subscription_RootSchedulesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Schedules_Order_By>>;
  where?: InputMaybe<Schedules_Bool_Exp>;
};


export type Subscription_RootSchedules_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootSchedules_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Schedules_Stream_Cursor_Input>>;
  where?: InputMaybe<Schedules_Bool_Exp>;
};


export type Subscription_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootSubscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSubscriptions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_AssociationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Associations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Associations_Order_By>>;
  where?: InputMaybe<Tags_Associations_Bool_Exp>;
};


export type Subscription_RootTags_Associations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTags_Associations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Tags_Associations_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Associations_Bool_Exp>;
};


export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootThemesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Themes_Order_By>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


export type Subscription_RootThemes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootThemes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Themes_Stream_Cursor_Input>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


export type Subscription_RootToolsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Subscription_RootTools_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTools_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Tools_Stream_Cursor_Input>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Subscription_RootTopicsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Order_By>>;
  where?: InputMaybe<Topics_Bool_Exp>;
};


export type Subscription_RootTopics_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTopics_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Topics_Stream_Cursor_Input>>;
  where?: InputMaybe<Topics_Bool_Exp>;
};


export type Subscription_RootTopics_ToolsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};


export type Subscription_RootTopics_Tools_By_PkArgs = {
  tool_id: Scalars['String']['input'];
  topic_id: Scalars['String']['input'];
};


export type Subscription_RootTopics_Tools_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Topics_Tools_Stream_Cursor_Input>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};


export type Subscription_RootTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Order_By>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};


export type Subscription_RootTutorials_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTutorials_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Tutorials_Stream_Cursor_Input>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};


export type Subscription_RootTutorials_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};


export type Subscription_RootTutorials_Tags_By_PkArgs = {
  tag_id: Scalars['String']['input'];
  tutorial_id: Scalars['String']['input'];
};


export type Subscription_RootTutorials_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Tutorials_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};


export type Subscription_RootUser_InvitesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_Invites_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Invites_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<User_Invites_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_PresenceArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


export type Subscription_RootUser_Presence_By_PkArgs = {
  platform: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUser_Presence_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<User_Presence_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_ActionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsers_Actions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Subscription_RootUsers_BadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Badges_Order_By>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


export type Subscription_RootUsers_Badges_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootUsers_Badges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Badges_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUsers_FeaturesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


export type Subscription_RootUsers_Features_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Features_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


export type Subscription_RootUsers_NotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


export type Subscription_RootUsers_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


export type Subscription_RootUsers_Notifications_By_PkArgs = {
  notification_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


export type Subscription_RootUsers_PresenceArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Presence_Order_By>>;
  where?: InputMaybe<Users_Presence_Bool_Exp>;
};


export type Subscription_RootUsers_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Presence_Order_By>>;
  where?: InputMaybe<Users_Presence_Bool_Exp>;
};


export type Subscription_RootUsers_Presence_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Presence_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Presence_Bool_Exp>;
};


export type Subscription_RootUsers_QuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


export type Subscription_RootUsers_Quests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


export type Subscription_RootUsers_Quests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsers_Quests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Quests_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


export type Subscription_RootUsers_StatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stats_Order_By>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


export type Subscription_RootUsers_Stats_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Stats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Stats_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Subscription_RootVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Subscription_RootVotes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVotes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type Subscriptions = {
  readonly __typename?: 'subscriptions';
  readonly cancel_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly cancel_at_period_end: Scalars['Boolean']['output'];
  readonly canceled_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created: Scalars['timestamptz']['output'];
  readonly current_period_end: Scalars['timestamptz']['output'];
  readonly current_period_start: Scalars['timestamptz']['output'];
  readonly customer_id: Scalars['String']['output'];
  readonly ended_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly entitled: Scalars['Boolean']['output'];
  readonly id: Scalars['String']['output'];
  readonly pause_collection_behavior?: Maybe<Scalars['String']['output']>;
  readonly pause_collection_behavior_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly pause_collection_resumes_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly quantity: Scalars['Int']['output'];
  readonly status: Scalars['String']['output'];
  readonly trial_end?: Maybe<Scalars['timestamptz']['output']>;
  readonly trial_start?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly user?: Maybe<Users>;
};

/** order by aggregate values of table "subscriptions" */
export type Subscriptions_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Subscriptions_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Subscriptions_Max_Order_By>;
  readonly min?: InputMaybe<Subscriptions_Min_Order_By>;
  readonly stddev?: InputMaybe<Subscriptions_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Subscriptions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Subscriptions_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Subscriptions_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Subscriptions_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Subscriptions_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Subscriptions_Variance_Order_By>;
};

/** order by avg() on columns of table "subscriptions" */
export type Subscriptions_Avg_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscriptions". All fields are combined with a logical 'AND'. */
export type Subscriptions_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Subscriptions_Bool_Exp>>;
  readonly _not?: InputMaybe<Subscriptions_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Subscriptions_Bool_Exp>>;
  readonly cancel_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly cancel_at_period_end?: InputMaybe<Boolean_Comparison_Exp>;
  readonly canceled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly created?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly current_period_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly current_period_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly customer_id?: InputMaybe<String_Comparison_Exp>;
  readonly ended_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly entitled?: InputMaybe<Boolean_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly pause_collection_behavior?: InputMaybe<String_Comparison_Exp>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly pause_collection_resumes_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly quantity?: InputMaybe<Int_Comparison_Exp>;
  readonly status?: InputMaybe<String_Comparison_Exp>;
  readonly trial_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly trial_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
};

/** order by max() on columns of table "subscriptions" */
export type Subscriptions_Max_Order_By = {
  readonly cancel_at?: InputMaybe<Order_By>;
  readonly canceled_at?: InputMaybe<Order_By>;
  readonly created?: InputMaybe<Order_By>;
  readonly current_period_end?: InputMaybe<Order_By>;
  readonly current_period_start?: InputMaybe<Order_By>;
  readonly customer_id?: InputMaybe<Order_By>;
  readonly ended_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly pause_collection_behavior?: InputMaybe<Order_By>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Order_By>;
  readonly pause_collection_resumes_at?: InputMaybe<Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly trial_end?: InputMaybe<Order_By>;
  readonly trial_start?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "subscriptions" */
export type Subscriptions_Min_Order_By = {
  readonly cancel_at?: InputMaybe<Order_By>;
  readonly canceled_at?: InputMaybe<Order_By>;
  readonly created?: InputMaybe<Order_By>;
  readonly current_period_end?: InputMaybe<Order_By>;
  readonly current_period_start?: InputMaybe<Order_By>;
  readonly customer_id?: InputMaybe<Order_By>;
  readonly ended_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly pause_collection_behavior?: InputMaybe<Order_By>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Order_By>;
  readonly pause_collection_resumes_at?: InputMaybe<Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly trial_end?: InputMaybe<Order_By>;
  readonly trial_start?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "subscriptions". */
export type Subscriptions_Order_By = {
  readonly cancel_at?: InputMaybe<Order_By>;
  readonly cancel_at_period_end?: InputMaybe<Order_By>;
  readonly canceled_at?: InputMaybe<Order_By>;
  readonly created?: InputMaybe<Order_By>;
  readonly current_period_end?: InputMaybe<Order_By>;
  readonly current_period_start?: InputMaybe<Order_By>;
  readonly customer_id?: InputMaybe<Order_By>;
  readonly ended_at?: InputMaybe<Order_By>;
  readonly entitled?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly pause_collection_behavior?: InputMaybe<Order_By>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Order_By>;
  readonly pause_collection_resumes_at?: InputMaybe<Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly trial_end?: InputMaybe<Order_By>;
  readonly trial_start?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
};

/** select columns of table "subscriptions" */
export enum Subscriptions_Select_Column {
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CancelAtPeriodEnd = 'cancel_at_period_end',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  Created = 'created',
  /** column name */
  CurrentPeriodEnd = 'current_period_end',
  /** column name */
  CurrentPeriodStart = 'current_period_start',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Entitled = 'entitled',
  /** column name */
  Id = 'id',
  /** column name */
  PauseCollectionBehavior = 'pause_collection_behavior',
  /** column name */
  PauseCollectionBehaviorUpdatedAt = 'pause_collection_behavior_updated_at',
  /** column name */
  PauseCollectionResumesAt = 'pause_collection_resumes_at',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Status = 'status',
  /** column name */
  TrialEnd = 'trial_end',
  /** column name */
  TrialStart = 'trial_start'
}

/** order by stddev() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Pop_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Samp_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "subscriptions" */
export type Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscriptions_Stream_Cursor_Value_Input = {
  readonly cancel_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>;
  readonly canceled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly current_period_end?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly current_period_start?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly customer_id?: InputMaybe<Scalars['String']['input']>;
  readonly ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly entitled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly pause_collection_behavior?: InputMaybe<Scalars['String']['input']>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly pause_collection_resumes_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly trial_end?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly trial_start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by sum() on columns of table "subscriptions" */
export type Subscriptions_Sum_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "subscriptions" */
export type Subscriptions_Var_Pop_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "subscriptions" */
export type Subscriptions_Var_Samp_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "subscriptions" */
export type Subscriptions_Variance_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

export enum Tag_Type {
  Items = 'items',
  Projects = 'projects',
  Tutorials = 'tutorials'
}

export type Tag_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Tag_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Tag_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Tag_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Tag_Type>>;
};

/** columns and relationships of "tags" */
export type Tags = {
  readonly __typename?: 'tags';
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions_Tags>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  /** An array relationship */
  readonly tutorials: ReadonlyArray<Tutorials_Tags>;
  readonly type: Tag_Type;
};


/** columns and relationships of "tags" */
export type TagsCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsTutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};

/** Table for storing tags */
export type Tags_Associations = {
  readonly __typename?: 'tags_associations';
  readonly association_id: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly user_id: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

/** Boolean expression to filter rows from the table "tags_associations". All fields are combined with a logical 'AND'. */
export type Tags_Associations_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Tags_Associations_Bool_Exp>>;
  readonly _not?: InputMaybe<Tags_Associations_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Tags_Associations_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags_associations" */
export enum Tags_Associations_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagsAssociationsPkey = 'tags_associations_pkey'
}

/** input type for inserting data into table "tags_associations" */
export type Tags_Associations_Insert_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "tags_associations" */
export type Tags_Associations_Mutation_Response = {
  readonly __typename?: 'tags_associations_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Tags_Associations>;
};

/** on_conflict condition type for table "tags_associations" */
export type Tags_Associations_On_Conflict = {
  readonly constraint: Tags_Associations_Constraint;
  readonly update_columns?: ReadonlyArray<Tags_Associations_Update_Column>;
  readonly where?: InputMaybe<Tags_Associations_Bool_Exp>;
};

/** Ordering options when selecting data from "tags_associations". */
export type Tags_Associations_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly value?: InputMaybe<Order_By>;
};

/** select columns of table "tags_associations" */
export enum Tags_Associations_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "tags_associations" */
export type Tags_Associations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Tags_Associations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Associations_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "tags_associations" (current role has no relevant permissions) */
export enum Tags_Associations_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Tags_Bool_Exp>>;
  readonly _not?: InputMaybe<Tags_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Tags_Bool_Exp>>;
  readonly competitions?: InputMaybe<Competitions_Tags_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly tutorials?: InputMaybe<Tutorials_Tags_Bool_Exp>;
  readonly type?: InputMaybe<Tag_Type_Bool_Exp>;
};

export type Tags_Insert_Input = {
  readonly type?: InputMaybe<Tag_Type>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  readonly competitions_aggregate?: InputMaybe<Competitions_Tags_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly tutorials_aggregate?: InputMaybe<Tutorials_Tags_Aggregate_Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}

export type Tags_Set_Input = {
  readonly type?: InputMaybe<Tag_Type>;
};

/** Streaming cursor of the table "tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

export enum Theme_Status {
  Active = 'active',
  Inactive = 'inactive'
}

export type Theme_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Theme_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Theme_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Theme_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Theme_Status>>;
};

/** columns and relationships of "themes" */
export type Themes = {
  readonly __typename?: 'themes';
  readonly active_at: Scalars['timestamptz']['output'];
  readonly background_image?: Maybe<Scalars['String']['output']>;
  readonly banner_title?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly color: Scalars['String']['output'];
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Aggregate;
  readonly description: Scalars['String']['output'];
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  readonly id: Scalars['uuid']['output'];
  readonly image: Scalars['String']['output'];
  readonly inactive_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly name: Scalars['String']['output'];
  readonly status: Theme_Status;
  readonly title?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly topic: Topics;
  readonly topic_id: Scalars['String']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
};


/** columns and relationships of "themes" */
export type ThemesCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** order by aggregate values of table "themes" */
export type Themes_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Themes_Max_Order_By>;
  readonly min?: InputMaybe<Themes_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "themes". All fields are combined with a logical 'AND'. */
export type Themes_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Themes_Bool_Exp>>;
  readonly _not?: InputMaybe<Themes_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Themes_Bool_Exp>>;
  readonly active_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly background_image?: InputMaybe<String_Comparison_Exp>;
  readonly banner_title?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly color?: InputMaybe<String_Comparison_Exp>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly competitions?: InputMaybe<Competitions_Bool_Exp>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Bool_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly feeds?: InputMaybe<Feeds_Bool_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly image?: InputMaybe<String_Comparison_Exp>;
  readonly inactive_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly status?: InputMaybe<Theme_Status_Bool_Exp>;
  readonly title?: InputMaybe<String_Comparison_Exp>;
  readonly topic?: InputMaybe<Topics_Bool_Exp>;
  readonly topic_id?: InputMaybe<String_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
};

export type Themes_Insert_Input = {
  readonly status?: InputMaybe<Theme_Status>;
};

/** order by max() on columns of table "themes" */
export type Themes_Max_Order_By = {
  readonly active_at?: InputMaybe<Order_By>;
  readonly background_image?: InputMaybe<Order_By>;
  readonly banner_title?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly inactive_at?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "themes" */
export type Themes_Min_Order_By = {
  readonly active_at?: InputMaybe<Order_By>;
  readonly background_image?: InputMaybe<Order_By>;
  readonly banner_title?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly inactive_at?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "themes". */
export type Themes_Order_By = {
  readonly active_at?: InputMaybe<Order_By>;
  readonly background_image?: InputMaybe<Order_By>;
  readonly banner_title?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly inactive_at?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly topic?: InputMaybe<Topics_Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
};

/** select columns of table "themes" */
export enum Themes_Select_Column {
  /** column name */
  ActiveAt = 'active_at',
  /** column name */
  BackgroundImage = 'background_image',
  /** column name */
  BannerTitle = 'banner_title',
  /** column name */
  Color = 'color',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  InactiveAt = 'inactive_at',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id'
}

export type Themes_Set_Input = {
  readonly status?: InputMaybe<Theme_Status>;
};

/** Streaming cursor of the table "themes" */
export type Themes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Themes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Themes_Stream_Cursor_Value_Input = {
  readonly active_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly background_image?: InputMaybe<Scalars['String']['input']>;
  readonly banner_title?: InputMaybe<Scalars['String']['input']>;
  readonly color?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly inactive_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['timestamptz']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "tools" */
export type Tools = {
  readonly __typename?: 'tools';
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Aggregate;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly photo: Scalars['String']['output'];
  /** An array relationship */
  readonly topics: ReadonlyArray<Topics_Tools>;
};


/** columns and relationships of "tools" */
export type ToolsCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsTopicsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "tools". All fields are combined with a logical 'AND'. */
export type Tools_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Tools_Bool_Exp>>;
  readonly _not?: InputMaybe<Tools_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Tools_Bool_Exp>>;
  readonly competitions?: InputMaybe<Competitions_Bool_Exp>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Bool_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly topics?: InputMaybe<Topics_Tools_Bool_Exp>;
};

/** Ordering options when selecting data from "tools". */
export type Tools_Order_By = {
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly topics_aggregate?: InputMaybe<Topics_Tools_Aggregate_Order_By>;
};

/** select columns of table "tools" */
export enum Tools_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo'
}

/** Streaming cursor of the table "tools" */
export type Tools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Tools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tools_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "topics" */
export type Topics = {
  readonly __typename?: 'topics';
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Aggregate;
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly photo: Scalars['String']['output'];
  /** An array relationship */
  readonly projects: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_aggregate: Projects_Aggregate;
  /** An array relationship */
  readonly tools: ReadonlyArray<Topics_Tools>;
};


/** columns and relationships of "topics" */
export type TopicsCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "topics" */
export type TopicsCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "topics" */
export type TopicsProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "topics" */
export type TopicsProjects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "topics" */
export type TopicsToolsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "topics". All fields are combined with a logical 'AND'. */
export type Topics_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Topics_Bool_Exp>>;
  readonly _not?: InputMaybe<Topics_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Topics_Bool_Exp>>;
  readonly competitions?: InputMaybe<Competitions_Bool_Exp>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Bool_Exp>;
  readonly icon?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly projects?: InputMaybe<Projects_Bool_Exp>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  readonly tools?: InputMaybe<Topics_Tools_Bool_Exp>;
};

/** Ordering options when selecting data from "topics". */
export type Topics_Order_By = {
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Order_By>;
  readonly icon?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  readonly tools_aggregate?: InputMaybe<Topics_Tools_Aggregate_Order_By>;
};

/** select columns of table "topics" */
export enum Topics_Select_Column {
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo'
}

/** Streaming cursor of the table "topics" */
export type Topics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Topics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Topics_Stream_Cursor_Value_Input = {
  readonly icon?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "topics_tools" */
export type Topics_Tools = {
  readonly __typename?: 'topics_tools';
  /** An object relationship */
  readonly tool: Tools;
  readonly tool_id: Scalars['String']['output'];
  /** An object relationship */
  readonly topic: Topics;
  readonly topic_id: Scalars['String']['output'];
};

/** order by aggregate values of table "topics_tools" */
export type Topics_Tools_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Topics_Tools_Max_Order_By>;
  readonly min?: InputMaybe<Topics_Tools_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "topics_tools". All fields are combined with a logical 'AND'. */
export type Topics_Tools_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Topics_Tools_Bool_Exp>>;
  readonly _not?: InputMaybe<Topics_Tools_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Topics_Tools_Bool_Exp>>;
  readonly tool?: InputMaybe<Tools_Bool_Exp>;
  readonly tool_id?: InputMaybe<String_Comparison_Exp>;
  readonly topic?: InputMaybe<Topics_Bool_Exp>;
  readonly topic_id?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "topics_tools" */
export type Topics_Tools_Max_Order_By = {
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "topics_tools" */
export type Topics_Tools_Min_Order_By = {
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "topics_tools". */
export type Topics_Tools_Order_By = {
  readonly tool?: InputMaybe<Tools_Order_By>;
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic?: InputMaybe<Topics_Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
};

/** select columns of table "topics_tools" */
export enum Topics_Tools_Select_Column {
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  TopicId = 'topic_id'
}

/** Streaming cursor of the table "topics_tools" */
export type Topics_Tools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Topics_Tools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Topics_Tools_Stream_Cursor_Value_Input = {
  readonly tool_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
};

export enum Transaction_Kind {
  Money = 'money',
  Xp = 'xp'
}

export type Transaction_Kind_Bool_Exp = {
  readonly _eq?: InputMaybe<Transaction_Kind>;
  readonly _in?: InputMaybe<ReadonlyArray<Transaction_Kind>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Transaction_Kind>;
  readonly _nin?: InputMaybe<ReadonlyArray<Transaction_Kind>>;
};

/** columns and relationships of "transactions" */
export type Transactions = {
  readonly __typename?: 'transactions';
  /** An object relationship */
  readonly competition?: Maybe<Competitions>;
  /** An object relationship */
  readonly item?: Maybe<Items>;
  readonly kind: Transaction_Kind;
  /** An object relationship */
  readonly order?: Maybe<Orders>;
  /** An object relationship */
  readonly project?: Maybe<Projects>;
  readonly quantity: Scalars['numeric']['output'];
  /** An object relationship */
  readonly recipient: Users;
};

/** aggregated selection of "transactions" */
export type Transactions_Aggregate = {
  readonly __typename?: 'transactions_aggregate';
  readonly aggregate?: Maybe<Transactions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Transactions>;
};

export type Transactions_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Transactions_Aggregate_Bool_Exp_Count>;
};

export type Transactions_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Transactions_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_Fields = {
  readonly __typename?: 'transactions_aggregate_fields';
  readonly avg?: Maybe<Transactions_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Transactions_Max_Fields>;
  readonly min?: Maybe<Transactions_Min_Fields>;
  readonly stddev?: Maybe<Transactions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Transactions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Transactions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Transactions_Sum_Fields>;
  readonly var_pop?: Maybe<Transactions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Transactions_Var_Samp_Fields>;
  readonly variance?: Maybe<Transactions_Variance_Fields>;
};


/** aggregate fields of "transactions" */
export type Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "transactions" */
export type Transactions_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Transactions_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Transactions_Max_Order_By>;
  readonly min?: InputMaybe<Transactions_Min_Order_By>;
  readonly stddev?: InputMaybe<Transactions_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Transactions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Transactions_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Transactions_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Transactions_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Transactions_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Transactions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Transactions_Avg_Fields = {
  readonly __typename?: 'transactions_avg_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "transactions" */
export type Transactions_Avg_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type Transactions_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Transactions_Bool_Exp>>;
  readonly _not?: InputMaybe<Transactions_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Transactions_Bool_Exp>>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly item?: InputMaybe<Items_Bool_Exp>;
  readonly kind?: InputMaybe<Transaction_Kind_Bool_Exp>;
  readonly order?: InputMaybe<Orders_Bool_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly quantity?: InputMaybe<Numeric_Comparison_Exp>;
  readonly recipient?: InputMaybe<Users_Bool_Exp>;
};

export type Transactions_Insert_Input = {
  readonly kind?: InputMaybe<Transaction_Kind>;
};

/** aggregate max on columns */
export type Transactions_Max_Fields = {
  readonly __typename?: 'transactions_max_fields';
  readonly kind?: Maybe<Scalars['String']['output']>;
  readonly quantity?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "transactions" */
export type Transactions_Max_Order_By = {
  readonly kind?: InputMaybe<Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Transactions_Min_Fields = {
  readonly __typename?: 'transactions_min_fields';
  readonly kind?: Maybe<Scalars['String']['output']>;
  readonly quantity?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "transactions" */
export type Transactions_Min_Order_By = {
  readonly kind?: InputMaybe<Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "transactions". */
export type Transactions_Order_By = {
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly item?: InputMaybe<Items_Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly order?: InputMaybe<Orders_Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
  readonly recipient?: InputMaybe<Users_Order_By>;
};

/** select columns of table "transactions" */
export enum Transactions_Select_Column {
  /** column name */
  Kind = 'kind',
  /** column name */
  Quantity = 'quantity'
}

export type Transactions_Set_Input = {
  readonly kind?: InputMaybe<Transaction_Kind>;
};

/** aggregate stddev on columns */
export type Transactions_Stddev_Fields = {
  readonly __typename?: 'transactions_stddev_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "transactions" */
export type Transactions_Stddev_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transactions_Stddev_Pop_Fields = {
  readonly __typename?: 'transactions_stddev_pop_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "transactions" */
export type Transactions_Stddev_Pop_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transactions_Stddev_Samp_Fields = {
  readonly __typename?: 'transactions_stddev_samp_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "transactions" */
export type Transactions_Stddev_Samp_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transactions" */
export type Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transactions_Stream_Cursor_Value_Input = {
  readonly kind?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Transactions_Sum_Fields = {
  readonly __typename?: 'transactions_sum_fields';
  readonly quantity?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "transactions" */
export type Transactions_Sum_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Transactions_Var_Pop_Fields = {
  readonly __typename?: 'transactions_var_pop_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "transactions" */
export type Transactions_Var_Pop_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transactions_Var_Samp_Fields = {
  readonly __typename?: 'transactions_var_samp_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "transactions" */
export type Transactions_Var_Samp_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Transactions_Variance_Fields = {
  readonly __typename?: 'transactions_variance_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "transactions" */
export type Transactions_Variance_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

export enum Tutorial_Difficulty {
  Advanced = 'advanced',
  Beginner = 'beginner',
  Intermediate = 'intermediate'
}

export type Tutorial_Difficulty_Bool_Exp = {
  readonly _eq?: InputMaybe<Tutorial_Difficulty>;
  readonly _in?: InputMaybe<ReadonlyArray<Tutorial_Difficulty>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Tutorial_Difficulty>;
  readonly _nin?: InputMaybe<ReadonlyArray<Tutorial_Difficulty>>;
};

/** columns and relationships of "tutorials" */
export type Tutorials = {
  readonly __typename?: 'tutorials';
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions_Tutorials>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly description: Scalars['String']['output'];
  readonly difficulty: Tutorial_Difficulty;
  readonly id: Scalars['String']['output'];
  readonly link: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly order: Scalars['Int']['output'];
  readonly photo: Scalars['String']['output'];
  /** An array relationship */
  readonly tags: ReadonlyArray<Tutorials_Tags>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "tutorials" */
export type TutorialsCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


/** columns and relationships of "tutorials" */
export type TutorialsTagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};

/** order by aggregate values of table "tutorials" */
export type Tutorials_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Tutorials_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Tutorials_Max_Order_By>;
  readonly min?: InputMaybe<Tutorials_Min_Order_By>;
  readonly stddev?: InputMaybe<Tutorials_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Tutorials_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Tutorials_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Tutorials_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Tutorials_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Tutorials_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Tutorials_Variance_Order_By>;
};

/** order by avg() on columns of table "tutorials" */
export type Tutorials_Avg_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tutorials". All fields are combined with a logical 'AND'. */
export type Tutorials_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Tutorials_Bool_Exp>>;
  readonly _not?: InputMaybe<Tutorials_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Tutorials_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly competitions?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly difficulty?: InputMaybe<Tutorial_Difficulty_Bool_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly link?: InputMaybe<String_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly order?: InputMaybe<Int_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly tags?: InputMaybe<Tutorials_Tags_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

export type Tutorials_Insert_Input = {
  readonly difficulty?: InputMaybe<Tutorial_Difficulty>;
};

/** order by max() on columns of table "tutorials" */
export type Tutorials_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly difficulty?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly link?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly order?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tutorials" */
export type Tutorials_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly difficulty?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly link?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly order?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tutorials". */
export type Tutorials_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Tutorials_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly difficulty?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly link?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly order?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly tags_aggregate?: InputMaybe<Tutorials_Tags_Aggregate_Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "tutorials" */
export enum Tutorials_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Difficulty = 'difficulty',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Photo = 'photo',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tutorials_Set_Input = {
  readonly difficulty?: InputMaybe<Tutorial_Difficulty>;
};

/** order by stddev() on columns of table "tutorials" */
export type Tutorials_Stddev_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tutorials" */
export type Tutorials_Stddev_Pop_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tutorials" */
export type Tutorials_Stddev_Samp_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tutorials" */
export type Tutorials_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Tutorials_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tutorials_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly difficulty?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly link?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly order?: InputMaybe<Scalars['Int']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by sum() on columns of table "tutorials" */
export type Tutorials_Sum_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** columns and relationships of "tutorials_tags" */
export type Tutorials_Tags = {
  readonly __typename?: 'tutorials_tags';
  /** An object relationship */
  readonly tag: Tags;
  readonly tag_id: Scalars['String']['output'];
  /** An object relationship */
  readonly tutorial: Tutorials;
  readonly tutorial_id: Scalars['String']['output'];
};

/** order by aggregate values of table "tutorials_tags" */
export type Tutorials_Tags_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Tutorials_Tags_Max_Order_By>;
  readonly min?: InputMaybe<Tutorials_Tags_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "tutorials_tags". All fields are combined with a logical 'AND'. */
export type Tutorials_Tags_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Tutorials_Tags_Bool_Exp>>;
  readonly _not?: InputMaybe<Tutorials_Tags_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Tutorials_Tags_Bool_Exp>>;
  readonly tag?: InputMaybe<Tags_Bool_Exp>;
  readonly tag_id?: InputMaybe<String_Comparison_Exp>;
  readonly tutorial?: InputMaybe<Tutorials_Bool_Exp>;
  readonly tutorial_id?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "tutorials_tags" */
export type Tutorials_Tags_Max_Order_By = {
  readonly tag_id?: InputMaybe<Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tutorials_tags" */
export type Tutorials_Tags_Min_Order_By = {
  readonly tag_id?: InputMaybe<Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tutorials_tags". */
export type Tutorials_Tags_Order_By = {
  readonly tag?: InputMaybe<Tags_Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
  readonly tutorial?: InputMaybe<Tutorials_Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** select columns of table "tutorials_tags" */
export enum Tutorials_Tags_Select_Column {
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TutorialId = 'tutorial_id'
}

/** Streaming cursor of the table "tutorials_tags" */
export type Tutorials_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Tutorials_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tutorials_Tags_Stream_Cursor_Value_Input = {
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['String']['input']>;
};

/** order by var_pop() on columns of table "tutorials" */
export type Tutorials_Var_Pop_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tutorials" */
export type Tutorials_Var_Samp_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tutorials" */
export type Tutorials_Variance_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

export type UnlinkDiscordOutput = {
  readonly __typename?: 'unlinkDiscordOutput';
  readonly message: Scalars['String']['output'];
};

export type UnlinkXboxOutput = {
  readonly __typename?: 'unlinkXboxOutput';
  readonly message: Scalars['String']['output'];
};

export enum User_Division {
  Beginner = 'beginner',
  Intermediate = 'intermediate',
  Open = 'open'
}

export type User_Division_Bool_Exp = {
  readonly _eq?: InputMaybe<User_Division>;
  readonly _in?: InputMaybe<ReadonlyArray<User_Division>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<User_Division>;
  readonly _nin?: InputMaybe<ReadonlyArray<User_Division>>;
};

export enum User_Invite_Kind {
  Platform = 'platform',
  Team = 'team'
}

export type User_Invite_Kind_Bool_Exp = {
  readonly _eq?: InputMaybe<User_Invite_Kind>;
  readonly _in?: InputMaybe<ReadonlyArray<User_Invite_Kind>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<User_Invite_Kind>;
  readonly _nin?: InputMaybe<ReadonlyArray<User_Invite_Kind>>;
};

export enum User_Invite_Status {
  Accepted = 'accepted',
  Autoaccepted = 'autoaccepted',
  Awarded = 'awarded',
  Declined = 'declined',
  Deleted = 'deleted',
  Expired = 'expired',
  Invited = 'invited',
  Joined = 'joined',
  Signedup = 'signedup'
}

export type User_Invite_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<User_Invite_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<User_Invite_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<User_Invite_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<User_Invite_Status>>;
};

export enum User_Invite_Type {
  Discord = 'discord',
  Email = 'email',
  Phone = 'phone',
  Username = 'username'
}

export type User_Invite_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<User_Invite_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<User_Invite_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<User_Invite_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<User_Invite_Type>>;
};

/** columns and relationships of "user_invites" */
export type User_Invites = {
  readonly __typename?: 'user_invites';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly channel?: Maybe<Channels>;
  readonly code: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly invited?: Maybe<Users>;
  readonly invitee: Scalars['String']['output'];
  /** An object relationship */
  readonly inviter: Users;
  readonly inviter_id: Scalars['String']['output'];
  readonly kind: User_Invite_Kind;
  readonly status: User_Invite_Status;
  readonly type: User_Invite_Type;
};

/** order by aggregate values of table "user_invites" */
export type User_Invites_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<User_Invites_Max_Order_By>;
  readonly min?: InputMaybe<User_Invites_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "user_invites". All fields are combined with a logical 'AND'. */
export type User_Invites_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<User_Invites_Bool_Exp>>;
  readonly _not?: InputMaybe<User_Invites_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<User_Invites_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly code?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly invited?: InputMaybe<Users_Bool_Exp>;
  readonly invitee?: InputMaybe<String_Comparison_Exp>;
  readonly inviter?: InputMaybe<Users_Bool_Exp>;
  readonly inviter_id?: InputMaybe<String_Comparison_Exp>;
  readonly kind?: InputMaybe<User_Invite_Kind_Bool_Exp>;
  readonly status?: InputMaybe<User_Invite_Status_Bool_Exp>;
  readonly type?: InputMaybe<User_Invite_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "user_invites" */
export enum User_Invites_Constraint {
  /** unique or primary key constraint on columns "invitee", "inviter_id", "associated_object" */
  UserInvitesInviterIdInviteeAssociatedObjectKey = 'user_invites_inviter_id_invitee_associated_object_key',
  /** unique or primary key constraint on columns "id" */
  UserInvitesPkey = 'user_invites_pkey'
}

/** input type for inserting data into table "user_invites" */
export type User_Invites_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly code?: InputMaybe<Scalars['String']['input']>;
  readonly invitee?: InputMaybe<Scalars['String']['input']>;
  readonly invitee_id?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<User_Invite_Kind>;
  readonly status?: InputMaybe<User_Invite_Status>;
  readonly type?: InputMaybe<User_Invite_Type>;
};

/** order by max() on columns of table "user_invites" */
export type User_Invites_Max_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly code?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly invitee?: InputMaybe<Order_By>;
  readonly inviter_id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user_invites" */
export type User_Invites_Min_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly code?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly invitee?: InputMaybe<Order_By>;
  readonly inviter_id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_invites" */
export type User_Invites_Mutation_Response = {
  readonly __typename?: 'user_invites_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<User_Invites>;
};

/** on_conflict condition type for table "user_invites" */
export type User_Invites_On_Conflict = {
  readonly constraint: User_Invites_Constraint;
  readonly update_columns?: ReadonlyArray<User_Invites_Update_Column>;
  readonly where?: InputMaybe<User_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "user_invites". */
export type User_Invites_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly code?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly invited?: InputMaybe<Users_Order_By>;
  readonly invitee?: InputMaybe<Order_By>;
  readonly inviter?: InputMaybe<Users_Order_By>;
  readonly inviter_id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_invites */
export type User_Invites_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "user_invites" */
export enum User_Invites_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Invitee = 'invitee',
  /** column name */
  InviterId = 'inviter_id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "user_invites" */
export type User_Invites_Set_Input = {
  readonly kind?: InputMaybe<User_Invite_Kind>;
  readonly status?: InputMaybe<User_Invite_Status>;
  readonly type?: InputMaybe<User_Invite_Type>;
};

/** Streaming cursor of the table "user_invites" */
export type User_Invites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: User_Invites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Invites_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly code?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly invitee?: InputMaybe<Scalars['String']['input']>;
  readonly inviter_id?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_invites" */
export enum User_Invites_Update_Column {
  /** column name */
  Status = 'status'
}

export type User_Invites_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<User_Invites_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: User_Invites_Bool_Exp;
};

/** columns and relationships of "user_presence" */
export type User_Presence = {
  readonly __typename?: 'user_presence';
  readonly metadata: Scalars['jsonb']['output'];
  readonly online: Scalars['Boolean']['output'];
  readonly platform: Scalars['user_presence_platform']['output'];
  readonly platform_state: Scalars['jsonb']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};


/** columns and relationships of "user_presence" */
export type User_PresenceMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "user_presence" */
export type User_PresencePlatform_StateArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "user_presence" */
export type User_Presence_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<User_Presence_Max_Order_By>;
  readonly min?: InputMaybe<User_Presence_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Presence_Append_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "user_presence". All fields are combined with a logical 'AND'. */
export type User_Presence_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<User_Presence_Bool_Exp>>;
  readonly _not?: InputMaybe<User_Presence_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<User_Presence_Bool_Exp>>;
  readonly metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly online?: InputMaybe<Boolean_Comparison_Exp>;
  readonly platform?: InputMaybe<User_Presence_Platform_Bool_Exp>;
  readonly platform_state?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_presence" */
export enum User_Presence_Constraint {
  /** unique or primary key constraint on columns "user_id", "platform" */
  UserPresencePkey = 'user_presence_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Presence_Delete_At_Path_Input = {
  readonly metadata?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly platform_state?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Presence_Delete_Elem_Input = {
  readonly metadata?: InputMaybe<Scalars['Int']['input']>;
  readonly platform_state?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Presence_Delete_Key_Input = {
  readonly metadata?: InputMaybe<Scalars['String']['input']>;
  readonly platform_state?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "user_presence" */
export type User_Presence_Insert_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly platform?: InputMaybe<Scalars['user_presence_platform']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "user_presence" */
export type User_Presence_Max_Order_By = {
  readonly platform?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "user_presence" */
export type User_Presence_Min_Order_By = {
  readonly platform?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_presence" */
export type User_Presence_Mutation_Response = {
  readonly __typename?: 'user_presence_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<User_Presence>;
};

/** on_conflict condition type for table "user_presence" */
export type User_Presence_On_Conflict = {
  readonly constraint: User_Presence_Constraint;
  readonly update_columns?: ReadonlyArray<User_Presence_Update_Column>;
  readonly where?: InputMaybe<User_Presence_Bool_Exp>;
};

/** Ordering options when selecting data from "user_presence". */
export type User_Presence_Order_By = {
  readonly metadata?: InputMaybe<Order_By>;
  readonly online?: InputMaybe<Order_By>;
  readonly platform?: InputMaybe<Order_By>;
  readonly platform_state?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_presence */
export type User_Presence_Pk_Columns_Input = {
  readonly platform: Scalars['String']['input'];
  readonly user_id: Scalars['String']['input'];
};

export type User_Presence_Platform_Bool_Exp = {
  readonly _eq?: InputMaybe<Scalars['user_presence_platform']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['user_presence_platform']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['user_presence_platform']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['user_presence_platform']['input']>>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Presence_Prepend_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "user_presence" */
export enum User_Presence_Select_Column {
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Online = 'online',
  /** column name */
  Platform = 'platform',
  /** column name */
  PlatformState = 'platform_state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_presence" */
export type User_Presence_Set_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly platform?: InputMaybe<Scalars['user_presence_platform']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_presence" */
export type User_Presence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: User_Presence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Presence_Stream_Cursor_Value_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_presence" */
export enum User_Presence_Update_Column {
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Online = 'online',
  /** column name */
  Platform = 'platform',
  /** column name */
  PlatformState = 'platform_state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Presence_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<User_Presence_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<User_Presence_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<User_Presence_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<User_Presence_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<User_Presence_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<User_Presence_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: User_Presence_Bool_Exp;
};

export enum User_Role {
  Adult = 'adult',
  Disabled = 'disabled',
  Team = 'team',
  User = 'user'
}

export type User_Role_Bool_Exp = {
  readonly _eq?: InputMaybe<User_Role>;
  readonly _in?: InputMaybe<ReadonlyArray<User_Role>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<User_Role>;
  readonly _nin?: InputMaybe<ReadonlyArray<User_Role>>;
};

/** columns and relationships of "users" */
export type Users = {
  readonly __typename?: 'users';
  /** An array relationship */
  readonly accounts: ReadonlyArray<Accounts>;
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly bio?: Maybe<Scalars['String']['output']>;
  readonly birthday?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  readonly channels_user?: Maybe<Channels_User>;
  /** An array relationship */
  readonly children: ReadonlyArray<Users>;
  /** An array relationship */
  readonly club_memberships: ReadonlyArray<Clubs_Members>;
  /** An array relationship */
  readonly competitions_hosting: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_hosting_aggregate: Competitions_Aggregate;
  /** An array relationship */
  readonly competitions_judging: ReadonlyArray<Competitions_Judges>;
  readonly created_at: Scalars['timestamptz']['output'];
  /** A computed field, executes function "is_current_user" */
  readonly current?: Maybe<Scalars['Boolean']['output']>;
  readonly discord_id?: Maybe<Scalars['String']['output']>;
  readonly discord_user?: Maybe<Scalars['jsonb']['output']>;
  readonly division: User_Division;
  readonly dream_job?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly email_verified?: Maybe<Scalars['Boolean']['output']>;
  readonly facebook_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly features: ReadonlyArray<Users_Features>;
  /** An array relationship */
  readonly feedback: ReadonlyArray<Feedback>;
  readonly first_name?: Maybe<Scalars['String']['output']>;
  readonly follower_count: Scalars['Int']['output'];
  /** An array relationship */
  readonly followers: ReadonlyArray<Follows>;
  /** An aggregate relationship */
  readonly followers_aggregate: Follows_Aggregate;
  /** An array relationship */
  readonly following: ReadonlyArray<Follows>;
  /** An aggregate relationship */
  readonly following_aggregate: Follows_Aggregate;
  readonly following_count: Scalars['Int']['output'];
  /** An array relationship */
  readonly friendships_users: ReadonlyArray<Friendships_Users>;
  readonly gender?: Maybe<Scalars['String']['output']>;
  readonly google_id?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly intercom_id?: Maybe<Scalars['String']['output']>;
  readonly interested_reason?: Maybe<Scalars['String']['output']>;
  readonly interests?: Maybe<Scalars['String']['output']>;
  readonly invite_code?: Maybe<Scalars['String']['output']>;
  readonly last_name?: Maybe<Scalars['String']['output']>;
  readonly level: Scalars['Int']['output'];
  readonly meta: Scalars['users_meta']['output'];
  readonly money: Scalars['numeric']['output'];
  /** An array relationship */
  readonly notifications: ReadonlyArray<Notifications>;
  /** An aggregate relationship */
  readonly notifications_aggregate: Notifications_Aggregate;
  readonly onboarded: Scalars['Boolean']['output'];
  /** An array relationship */
  readonly orders: ReadonlyArray<Orders>;
  /** An aggregate relationship */
  readonly orders_aggregate: Orders_Aggregate;
  /** An object relationship */
  readonly parent?: Maybe<Users>;
  readonly parent_email?: Maybe<Scalars['String']['output']>;
  readonly parent_id?: Maybe<Scalars['String']['output']>;
  readonly password_hash?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly phone_verified?: Maybe<Scalars['Boolean']['output']>;
  readonly photo: Scalars['String']['output'];
  /** An array relationship */
  readonly posts: ReadonlyArray<Posts>;
  readonly presence: Scalars['jsonb']['output'];
  /** An array relationship */
  readonly projects_collaborated: ReadonlyArray<Projects_Collaborators>;
  /** An aggregate relationship */
  readonly projects_collaborated_aggregate: Projects_Collaborators_Aggregate;
  /** An array relationship */
  readonly projects_comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly projects_comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  readonly projects_created: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_created_aggregate: Projects_Aggregate;
  /** An array relationship */
  readonly projects_votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly projects_votes_aggregate: Votes_Aggregate;
  /** An object relationship */
  readonly prospective_user?: Maybe<Prospective_Users>;
  readonly role: User_Role;
  readonly season_xp: Scalars['Int']['output'];
  /** An array relationship */
  readonly stats: ReadonlyArray<Users_Stats>;
  readonly stripe_customer_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly subscriptions: ReadonlyArray<Subscriptions>;
  readonly title?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  readonly user_invites: ReadonlyArray<User_Invites>;
  /** An array relationship */
  readonly user_invites_received: ReadonlyArray<User_Invites>;
  /** An array relationship */
  readonly user_presences: ReadonlyArray<User_Presence>;
  readonly username?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly users_badges: ReadonlyArray<Users_Badges>;
  /** An array relationship */
  readonly users_notifications: ReadonlyArray<Users_Notifications>;
  /** An aggregate relationship */
  readonly users_notifications_aggregate: Users_Notifications_Aggregate;
  /** An object relationship */
  readonly users_presence?: Maybe<Users_Presence>;
  /** An array relationship */
  readonly users_quests: ReadonlyArray<Users_Quests>;
  /** An aggregate relationship */
  readonly users_quests_aggregate: Users_Quests_Aggregate;
  readonly verified: Scalars['Boolean']['output'];
  readonly xp: Scalars['Int']['output'];
};


/** columns and relationships of "users" */
export type UsersAccountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChildrenArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersClub_MembershipsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompetitions_HostingArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompetitions_Hosting_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompetitions_JudgingArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDiscord_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users" */
export type UsersFeaturesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFeedbackArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollowersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollowers_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollowingArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollowing_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFriendships_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users" */
export type UsersNotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOrdersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOrders_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPresenceArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users" */
export type UsersProjects_CollaboratedArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_Collaborated_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_CommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_CreatedArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_Created_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_VotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stats_Order_By>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubscriptionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_InvitesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Invites_ReceivedArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_PresencesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_BadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Badges_Order_By>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_NotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_QuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Quests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};

/** columns and relationships of "users_actions" */
export type Users_Actions = {
  readonly __typename?: 'users_actions';
  readonly action: Scalars['user_action']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly executed: Scalars['Boolean']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly user_id: Scalars['String']['output'];
};


/** columns and relationships of "users_actions" */
export type Users_ActionsActionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "users_actions". All fields are combined with a logical 'AND'. */
export type Users_Actions_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Actions_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Actions_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Actions_Bool_Exp>>;
  readonly action?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly executed?: InputMaybe<Boolean_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_actions" */
export enum Users_Actions_Constraint {
  /** unique or primary key constraint on columns "issuer", "user_id" */
  UniqueActionTypeIssuerUserId = 'unique_action_type_issuer_user_id',
  /** unique or primary key constraint on columns "id" */
  UsersActionsPkey = 'users_actions_pkey'
}

/** input type for inserting data into table "users_actions" */
export type Users_Actions_Insert_Input = {
  readonly action: Scalars['user_action']['input'];
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "users_actions" */
export type Users_Actions_Mutation_Response = {
  readonly __typename?: 'users_actions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Actions>;
};

/** on_conflict condition type for table "users_actions" */
export type Users_Actions_On_Conflict = {
  readonly constraint: Users_Actions_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Actions_Update_Column>;
  readonly where?: InputMaybe<Users_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "users_actions". */
export type Users_Actions_Order_By = {
  readonly action?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly executed?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_actions */
export type Users_Actions_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "users_actions" */
export enum Users_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Executed = 'executed',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Users_Actions_Set_Input = {
  readonly action: Scalars['user_action']['input'];
};

/** Streaming cursor of the table "users_actions" */
export type Users_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Actions_Stream_Cursor_Value_Input = {
  readonly action?: InputMaybe<Scalars['jsonb']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly executed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "users_actions" (current role has no relevant permissions) */
export enum Users_Actions_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

export type Users_Actions_Updates = {
  /** filter the rows which have to be updated */
  readonly where: Users_Actions_Bool_Exp;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Users_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Max_Order_By>;
  readonly min?: InputMaybe<Users_Min_Order_By>;
  readonly stddev?: InputMaybe<Users_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Users_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Users_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  readonly discord_user?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** columns and relationships of "users_badges" */
export type Users_Badges = {
  readonly __typename?: 'users_badges';
  /** An object relationship */
  readonly badge: Badges;
  readonly badge_id: Scalars['uuid']['output'];
  readonly expiry?: Maybe<Scalars['timestamptz']['output']>;
  readonly id: Scalars['Int']['output'];
  readonly level_level?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  readonly project?: Maybe<Projects>;
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** order by aggregate values of table "users_badges" */
export type Users_Badges_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Users_Badges_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Badges_Max_Order_By>;
  readonly min?: InputMaybe<Users_Badges_Min_Order_By>;
  readonly stddev?: InputMaybe<Users_Badges_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Users_Badges_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Users_Badges_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Users_Badges_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Users_Badges_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Users_Badges_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Users_Badges_Variance_Order_By>;
};

/** order by avg() on columns of table "users_badges" */
export type Users_Badges_Avg_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_badges". All fields are combined with a logical 'AND'. */
export type Users_Badges_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Badges_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Badges_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Badges_Bool_Exp>>;
  readonly badge?: InputMaybe<Badges_Bool_Exp>;
  readonly badge_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly expiry?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Int_Comparison_Exp>;
  readonly level_level?: InputMaybe<Int_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "users_badges" */
export type Users_Badges_Max_Order_By = {
  readonly badge_id?: InputMaybe<Order_By>;
  readonly expiry?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "users_badges" */
export type Users_Badges_Min_Order_By = {
  readonly badge_id?: InputMaybe<Order_By>;
  readonly expiry?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "users_badges". */
export type Users_Badges_Order_By = {
  readonly badge?: InputMaybe<Badges_Order_By>;
  readonly badge_id?: InputMaybe<Order_By>;
  readonly expiry?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** select columns of table "users_badges" */
export enum Users_Badges_Select_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  Id = 'id',
  /** column name */
  LevelLevel = 'level_level',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "users_badges" */
export type Users_Badges_Stddev_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "users_badges" */
export type Users_Badges_Stddev_Pop_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "users_badges" */
export type Users_Badges_Stddev_Samp_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_badges" */
export type Users_Badges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Badges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Badges_Stream_Cursor_Value_Input = {
  readonly badge_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly expiry?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly level_level?: InputMaybe<Scalars['Int']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "users_badges" */
export type Users_Badges_Sum_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "users_badges" */
export type Users_Badges_Var_Pop_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "users_badges" */
export type Users_Badges_Var_Samp_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "users_badges" */
export type Users_Badges_Variance_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Bool_Exp>>;
  readonly accounts?: InputMaybe<Accounts_Bool_Exp>;
  readonly address?: InputMaybe<String_Comparison_Exp>;
  readonly age?: InputMaybe<Int_Comparison_Exp>;
  readonly bio?: InputMaybe<String_Comparison_Exp>;
  readonly birthday?: InputMaybe<Date_Comparison_Exp>;
  readonly channels_user?: InputMaybe<Channels_User_Bool_Exp>;
  readonly children?: InputMaybe<Users_Bool_Exp>;
  readonly club_memberships?: InputMaybe<Clubs_Members_Bool_Exp>;
  readonly competitions_hosting?: InputMaybe<Competitions_Bool_Exp>;
  readonly competitions_hosting_aggregate?: InputMaybe<Competitions_Aggregate_Bool_Exp>;
  readonly competitions_judging?: InputMaybe<Competitions_Judges_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly current?: InputMaybe<Boolean_Comparison_Exp>;
  readonly discord_id?: InputMaybe<String_Comparison_Exp>;
  readonly discord_user?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly division?: InputMaybe<User_Division_Bool_Exp>;
  readonly dream_job?: InputMaybe<String_Comparison_Exp>;
  readonly email?: InputMaybe<String_Comparison_Exp>;
  readonly email_verified?: InputMaybe<Boolean_Comparison_Exp>;
  readonly facebook_id?: InputMaybe<String_Comparison_Exp>;
  readonly features?: InputMaybe<Users_Features_Bool_Exp>;
  readonly feedback?: InputMaybe<Feedback_Bool_Exp>;
  readonly first_name?: InputMaybe<String_Comparison_Exp>;
  readonly follower_count?: InputMaybe<Int_Comparison_Exp>;
  readonly followers?: InputMaybe<Follows_Bool_Exp>;
  readonly followers_aggregate?: InputMaybe<Follows_Aggregate_Bool_Exp>;
  readonly following?: InputMaybe<Follows_Bool_Exp>;
  readonly following_aggregate?: InputMaybe<Follows_Aggregate_Bool_Exp>;
  readonly following_count?: InputMaybe<Int_Comparison_Exp>;
  readonly friendships_users?: InputMaybe<Friendships_Users_Bool_Exp>;
  readonly gender?: InputMaybe<String_Comparison_Exp>;
  readonly google_id?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly intercom_id?: InputMaybe<String_Comparison_Exp>;
  readonly interested_reason?: InputMaybe<String_Comparison_Exp>;
  readonly interests?: InputMaybe<String_Comparison_Exp>;
  readonly invite_code?: InputMaybe<String_Comparison_Exp>;
  readonly last_name?: InputMaybe<String_Comparison_Exp>;
  readonly level?: InputMaybe<Int_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly money?: InputMaybe<Numeric_Comparison_Exp>;
  readonly notifications?: InputMaybe<Notifications_Bool_Exp>;
  readonly notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  readonly onboarded?: InputMaybe<Boolean_Comparison_Exp>;
  readonly orders?: InputMaybe<Orders_Bool_Exp>;
  readonly orders_aggregate?: InputMaybe<Orders_Aggregate_Bool_Exp>;
  readonly parent?: InputMaybe<Users_Bool_Exp>;
  readonly parent_email?: InputMaybe<String_Comparison_Exp>;
  readonly parent_id?: InputMaybe<String_Comparison_Exp>;
  readonly password_hash?: InputMaybe<String_Comparison_Exp>;
  readonly phone?: InputMaybe<String_Comparison_Exp>;
  readonly phone_verified?: InputMaybe<Boolean_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly posts?: InputMaybe<Posts_Bool_Exp>;
  readonly presence?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly projects_collaborated?: InputMaybe<Projects_Collaborators_Bool_Exp>;
  readonly projects_collaborated_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Bool_Exp>;
  readonly projects_comments?: InputMaybe<Comments_Bool_Exp>;
  readonly projects_comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly projects_created?: InputMaybe<Projects_Bool_Exp>;
  readonly projects_created_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  readonly projects_votes?: InputMaybe<Votes_Bool_Exp>;
  readonly projects_votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
  readonly prospective_user?: InputMaybe<Prospective_Users_Bool_Exp>;
  readonly role?: InputMaybe<User_Role_Bool_Exp>;
  readonly season_xp?: InputMaybe<Int_Comparison_Exp>;
  readonly stats?: InputMaybe<Users_Stats_Bool_Exp>;
  readonly stripe_customer_id?: InputMaybe<String_Comparison_Exp>;
  readonly subscriptions?: InputMaybe<Subscriptions_Bool_Exp>;
  readonly title?: InputMaybe<String_Comparison_Exp>;
  readonly transactions?: InputMaybe<Transactions_Bool_Exp>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_invites?: InputMaybe<User_Invites_Bool_Exp>;
  readonly user_invites_received?: InputMaybe<User_Invites_Bool_Exp>;
  readonly user_presences?: InputMaybe<User_Presence_Bool_Exp>;
  readonly username?: InputMaybe<String_Comparison_Exp>;
  readonly users_badges?: InputMaybe<Users_Badges_Bool_Exp>;
  readonly users_notifications?: InputMaybe<Users_Notifications_Bool_Exp>;
  readonly users_notifications_aggregate?: InputMaybe<Users_Notifications_Aggregate_Bool_Exp>;
  readonly users_presence?: InputMaybe<Users_Presence_Bool_Exp>;
  readonly users_quests?: InputMaybe<Users_Quests_Bool_Exp>;
  readonly users_quests_aggregate?: InputMaybe<Users_Quests_Aggregate_Bool_Exp>;
  readonly verified?: InputMaybe<Boolean_Comparison_Exp>;
  readonly xp?: InputMaybe<Int_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  readonly discord_user?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly presence?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  readonly discord_user?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
  readonly presence?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  readonly discord_user?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['String']['input']>;
  readonly presence?: InputMaybe<Scalars['String']['input']>;
};

/** Association table to determine which features users are entitled to */
export type Users_Features = {
  readonly __typename?: 'users_features';
  readonly enabled: Scalars['Boolean']['output'];
  /** An object relationship */
  readonly feature: Features;
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** order by aggregate values of table "users_features" */
export type Users_Features_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Features_Max_Order_By>;
  readonly min?: InputMaybe<Users_Features_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "users_features". All fields are combined with a logical 'AND'. */
export type Users_Features_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Features_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Features_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Features_Bool_Exp>>;
  readonly enabled?: InputMaybe<Boolean_Comparison_Exp>;
  readonly feature?: InputMaybe<Features_Bool_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "users_features" */
export type Users_Features_Max_Order_By = {
  readonly user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "users_features" */
export type Users_Features_Min_Order_By = {
  readonly user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "users_features". */
export type Users_Features_Order_By = {
  readonly enabled?: InputMaybe<Order_By>;
  readonly feature?: InputMaybe<Features_Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** select columns of table "users_features" */
export enum Users_Features_Select_Column {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "users_features" */
export type Users_Features_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Features_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Features_Stream_Cursor_Value_Input = {
  readonly enabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly follower_count?: InputMaybe<Scalars['Int']['input']>;
  readonly following_count?: InputMaybe<Scalars['Int']['input']>;
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly season_xp?: InputMaybe<Scalars['Int']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

export type Users_Insert_Input = {
  readonly division?: InputMaybe<User_Division>;
  readonly meta?: InputMaybe<Scalars['users_meta']['input']>;
  readonly role?: InputMaybe<User_Role>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  readonly address?: InputMaybe<Order_By>;
  readonly age?: InputMaybe<Order_By>;
  readonly bio?: InputMaybe<Order_By>;
  readonly birthday?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly discord_id?: InputMaybe<Order_By>;
  readonly division?: InputMaybe<Order_By>;
  readonly dream_job?: InputMaybe<Order_By>;
  readonly email?: InputMaybe<Order_By>;
  readonly facebook_id?: InputMaybe<Order_By>;
  readonly first_name?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly gender?: InputMaybe<Order_By>;
  readonly google_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly intercom_id?: InputMaybe<Order_By>;
  readonly interested_reason?: InputMaybe<Order_By>;
  readonly interests?: InputMaybe<Order_By>;
  readonly invite_code?: InputMaybe<Order_By>;
  readonly last_name?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly parent_email?: InputMaybe<Order_By>;
  readonly parent_id?: InputMaybe<Order_By>;
  readonly password_hash?: InputMaybe<Order_By>;
  readonly phone?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly role?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly stripe_customer_id?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  readonly address?: InputMaybe<Order_By>;
  readonly age?: InputMaybe<Order_By>;
  readonly bio?: InputMaybe<Order_By>;
  readonly birthday?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly discord_id?: InputMaybe<Order_By>;
  readonly division?: InputMaybe<Order_By>;
  readonly dream_job?: InputMaybe<Order_By>;
  readonly email?: InputMaybe<Order_By>;
  readonly facebook_id?: InputMaybe<Order_By>;
  readonly first_name?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly gender?: InputMaybe<Order_By>;
  readonly google_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly intercom_id?: InputMaybe<Order_By>;
  readonly interested_reason?: InputMaybe<Order_By>;
  readonly interests?: InputMaybe<Order_By>;
  readonly invite_code?: InputMaybe<Order_By>;
  readonly last_name?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly parent_email?: InputMaybe<Order_By>;
  readonly parent_id?: InputMaybe<Order_By>;
  readonly password_hash?: InputMaybe<Order_By>;
  readonly phone?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly role?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly stripe_customer_id?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  readonly __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users>;
};

/** columns and relationships of "users_notifications" */
export type Users_Notifications = {
  readonly __typename?: 'users_notifications';
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly notification: Notifications;
  readonly notification_id: Scalars['uuid']['output'];
  readonly read_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "users_notifications" */
export type Users_Notifications_Aggregate = {
  readonly __typename?: 'users_notifications_aggregate';
  readonly aggregate?: Maybe<Users_Notifications_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Notifications>;
};

export type Users_Notifications_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Users_Notifications_Aggregate_Bool_Exp_Count>;
};

export type Users_Notifications_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Notifications_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_notifications" */
export type Users_Notifications_Aggregate_Fields = {
  readonly __typename?: 'users_notifications_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Notifications_Max_Fields>;
  readonly min?: Maybe<Users_Notifications_Min_Fields>;
};


/** aggregate fields of "users_notifications" */
export type Users_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_notifications" */
export type Users_Notifications_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Notifications_Max_Order_By>;
  readonly min?: InputMaybe<Users_Notifications_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "users_notifications". All fields are combined with a logical 'AND'. */
export type Users_Notifications_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Notifications_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Notifications_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Notifications_Bool_Exp>>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly notification?: InputMaybe<Notifications_Bool_Exp>;
  readonly notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_notifications" */
export enum Users_Notifications_Constraint {
  /** unique or primary key constraint on columns "user_id", "notification_id" */
  UsersNotificationsPkey = 'users_notifications_pkey'
}

/** input type for inserting data into table "users_notifications" */
export type Users_Notifications_Insert_Input = {
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly notification_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly read_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Users_Notifications_Max_Fields = {
  readonly __typename?: 'users_notifications_max_fields';
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly notification_id?: Maybe<Scalars['uuid']['output']>;
  readonly read_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users_notifications" */
export type Users_Notifications_Max_Order_By = {
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly notification_id?: InputMaybe<Order_By>;
  readonly read_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Notifications_Min_Fields = {
  readonly __typename?: 'users_notifications_min_fields';
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly notification_id?: Maybe<Scalars['uuid']['output']>;
  readonly read_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users_notifications" */
export type Users_Notifications_Min_Order_By = {
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly notification_id?: InputMaybe<Order_By>;
  readonly read_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_notifications" */
export type Users_Notifications_Mutation_Response = {
  readonly __typename?: 'users_notifications_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Notifications>;
};

/** on_conflict condition type for table "users_notifications" */
export type Users_Notifications_On_Conflict = {
  readonly constraint: Users_Notifications_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Notifications_Update_Column>;
  readonly where?: InputMaybe<Users_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "users_notifications". */
export type Users_Notifications_Order_By = {
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly notification?: InputMaybe<Notifications_Order_By>;
  readonly notification_id?: InputMaybe<Order_By>;
  readonly read_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_notifications */
export type Users_Notifications_Pk_Columns_Input = {
  readonly notification_id: Scalars['uuid']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** select columns of table "users_notifications" */
export enum Users_Notifications_Select_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "users_notifications" */
export type Users_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Notifications_Stream_Cursor_Value_Input = {
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly notification_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "users_notifications" (current role has no relevant permissions) */
export enum Users_Notifications_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

export type Users_Notifications_Updates = {
  /** filter the rows which have to be updated */
  readonly where: Users_Notifications_Bool_Exp;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  readonly accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  readonly address?: InputMaybe<Order_By>;
  readonly age?: InputMaybe<Order_By>;
  readonly bio?: InputMaybe<Order_By>;
  readonly birthday?: InputMaybe<Order_By>;
  readonly channels_user?: InputMaybe<Channels_User_Order_By>;
  readonly children_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  readonly club_memberships_aggregate?: InputMaybe<Clubs_Members_Aggregate_Order_By>;
  readonly competitions_hosting_aggregate?: InputMaybe<Competitions_Aggregate_Order_By>;
  readonly competitions_judging_aggregate?: InputMaybe<Competitions_Judges_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly current?: InputMaybe<Order_By>;
  readonly discord_id?: InputMaybe<Order_By>;
  readonly discord_user?: InputMaybe<Order_By>;
  readonly division?: InputMaybe<Order_By>;
  readonly dream_job?: InputMaybe<Order_By>;
  readonly email?: InputMaybe<Order_By>;
  readonly email_verified?: InputMaybe<Order_By>;
  readonly facebook_id?: InputMaybe<Order_By>;
  readonly features_aggregate?: InputMaybe<Users_Features_Aggregate_Order_By>;
  readonly feedback_aggregate?: InputMaybe<Feedback_Aggregate_Order_By>;
  readonly first_name?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly followers_aggregate?: InputMaybe<Follows_Aggregate_Order_By>;
  readonly following_aggregate?: InputMaybe<Follows_Aggregate_Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly friendships_users_aggregate?: InputMaybe<Friendships_Users_Aggregate_Order_By>;
  readonly gender?: InputMaybe<Order_By>;
  readonly google_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly intercom_id?: InputMaybe<Order_By>;
  readonly interested_reason?: InputMaybe<Order_By>;
  readonly interests?: InputMaybe<Order_By>;
  readonly invite_code?: InputMaybe<Order_By>;
  readonly last_name?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  readonly onboarded?: InputMaybe<Order_By>;
  readonly orders_aggregate?: InputMaybe<Orders_Aggregate_Order_By>;
  readonly parent?: InputMaybe<Users_Order_By>;
  readonly parent_email?: InputMaybe<Order_By>;
  readonly parent_id?: InputMaybe<Order_By>;
  readonly password_hash?: InputMaybe<Order_By>;
  readonly phone?: InputMaybe<Order_By>;
  readonly phone_verified?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly posts_aggregate?: InputMaybe<Posts_Aggregate_Order_By>;
  readonly presence?: InputMaybe<Order_By>;
  readonly projects_collaborated_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Order_By>;
  readonly projects_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly projects_created_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  readonly projects_votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
  readonly prospective_user?: InputMaybe<Prospective_Users_Order_By>;
  readonly role?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly stats_aggregate?: InputMaybe<Users_Stats_Aggregate_Order_By>;
  readonly stripe_customer_id?: InputMaybe<Order_By>;
  readonly subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_invites_aggregate?: InputMaybe<User_Invites_Aggregate_Order_By>;
  readonly user_invites_received_aggregate?: InputMaybe<User_Invites_Aggregate_Order_By>;
  readonly user_presences_aggregate?: InputMaybe<User_Presence_Aggregate_Order_By>;
  readonly username?: InputMaybe<Order_By>;
  readonly users_badges_aggregate?: InputMaybe<Users_Badges_Aggregate_Order_By>;
  readonly users_notifications_aggregate?: InputMaybe<Users_Notifications_Aggregate_Order_By>;
  readonly users_presence?: InputMaybe<Users_Presence_Order_By>;
  readonly users_quests_aggregate?: InputMaybe<Users_Quests_Aggregate_Order_By>;
  readonly verified?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  readonly discord_user?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "users_presence" */
export type Users_Presence = {
  readonly __typename?: 'users_presence';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly online?: Maybe<Scalars['Boolean']['output']>;
  readonly online_meta?: Maybe<Scalars['jsonb']['output']>;
};


/** columns and relationships of "users_presence" */
export type Users_PresenceOnline_MetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users_presence" */
export type Users_Presence_Aggregate = {
  readonly __typename?: 'users_presence_aggregate';
  readonly aggregate?: Maybe<Users_Presence_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Presence>;
};

/** aggregate fields of "users_presence" */
export type Users_Presence_Aggregate_Fields = {
  readonly __typename?: 'users_presence_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Presence_Max_Fields>;
  readonly min?: Maybe<Users_Presence_Min_Fields>;
};


/** aggregate fields of "users_presence" */
export type Users_Presence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Presence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "users_presence". All fields are combined with a logical 'AND'. */
export type Users_Presence_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Presence_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Presence_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Presence_Bool_Exp>>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly online?: InputMaybe<Boolean_Comparison_Exp>;
  readonly online_meta?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** aggregate max on columns */
export type Users_Presence_Max_Fields = {
  readonly __typename?: 'users_presence_max_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Presence_Min_Fields = {
  readonly __typename?: 'users_presence_min_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "users_presence". */
export type Users_Presence_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly online?: InputMaybe<Order_By>;
  readonly online_meta?: InputMaybe<Order_By>;
};

/** select columns of table "users_presence" */
export enum Users_Presence_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Online = 'online',
  /** column name */
  OnlineMeta = 'online_meta'
}

/** Streaming cursor of the table "users_presence" */
export type Users_Presence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Presence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Presence_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly online_meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "users_quests" */
export type Users_Quests = {
  readonly __typename?: 'users_quests';
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly quest: Quests;
  readonly quest_id: Scalars['uuid']['output'];
  readonly rewarded_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  readonly viewed_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "users_quests" */
export type Users_Quests_Aggregate = {
  readonly __typename?: 'users_quests_aggregate';
  readonly aggregate?: Maybe<Users_Quests_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Quests>;
};

export type Users_Quests_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Users_Quests_Aggregate_Bool_Exp_Count>;
};

export type Users_Quests_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Quests_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_quests" */
export type Users_Quests_Aggregate_Fields = {
  readonly __typename?: 'users_quests_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Quests_Max_Fields>;
  readonly min?: Maybe<Users_Quests_Min_Fields>;
};


/** aggregate fields of "users_quests" */
export type Users_Quests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_quests" */
export type Users_Quests_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Quests_Max_Order_By>;
  readonly min?: InputMaybe<Users_Quests_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "users_quests". All fields are combined with a logical 'AND'. */
export type Users_Quests_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Quests_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Quests_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Quests_Bool_Exp>>;
  readonly completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly quest?: InputMaybe<Quests_Bool_Exp>;
  readonly quest_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly rewarded_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly viewed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Users_Quests_Max_Fields = {
  readonly __typename?: 'users_quests_max_fields';
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly quest_id?: Maybe<Scalars['uuid']['output']>;
  readonly rewarded_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly viewed_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "users_quests" */
export type Users_Quests_Max_Order_By = {
  readonly completed_at?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly quest_id?: InputMaybe<Order_By>;
  readonly rewarded_at?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly viewed_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Quests_Min_Fields = {
  readonly __typename?: 'users_quests_min_fields';
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly quest_id?: Maybe<Scalars['uuid']['output']>;
  readonly rewarded_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly viewed_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "users_quests" */
export type Users_Quests_Min_Order_By = {
  readonly completed_at?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly quest_id?: InputMaybe<Order_By>;
  readonly rewarded_at?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly viewed_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_quests" */
export type Users_Quests_Mutation_Response = {
  readonly __typename?: 'users_quests_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Quests>;
};

/** Ordering options when selecting data from "users_quests". */
export type Users_Quests_Order_By = {
  readonly completed_at?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly quest?: InputMaybe<Quests_Order_By>;
  readonly quest_id?: InputMaybe<Order_By>;
  readonly rewarded_at?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly viewed_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_quests */
export type Users_Quests_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "users_quests" */
export enum Users_Quests_Select_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestId = 'quest_id',
  /** column name */
  RewardedAt = 'rewarded_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ViewedAt = 'viewed_at'
}

/** input type for updating data in table "users_quests" */
export type Users_Quests_Set_Input = {
  readonly viewed_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "users_quests" */
export type Users_Quests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Quests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Quests_Stream_Cursor_Value_Input = {
  readonly completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly quest_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rewarded_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly viewed_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type Users_Quests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Quests_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Quests_Bool_Exp;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Age = 'age',
  /** column name */
  Bio = 'bio',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscordId = 'discord_id',
  /** column name */
  DiscordUser = 'discord_user',
  /** column name */
  Division = 'division',
  /** column name */
  DreamJob = 'dream_job',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  FacebookId = 'facebook_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FollowerCount = 'follower_count',
  /** column name */
  FollowingCount = 'following_count',
  /** column name */
  Gender = 'gender',
  /** column name */
  GoogleId = 'google_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntercomId = 'intercom_id',
  /** column name */
  InterestedReason = 'interested_reason',
  /** column name */
  Interests = 'interests',
  /** column name */
  InviteCode = 'invite_code',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Level = 'level',
  /** column name */
  Meta = 'meta',
  /** column name */
  Money = 'money',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  ParentEmail = 'parent_email',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  Photo = 'photo',
  /** column name */
  Presence = 'presence',
  /** column name */
  Role = 'role',
  /** column name */
  SeasonXp = 'season_xp',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  Verified = 'verified',
  /** column name */
  Xp = 'xp'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  readonly address?: InputMaybe<Scalars['String']['input']>;
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly bio?: InputMaybe<Scalars['String']['input']>;
  readonly birthday?: InputMaybe<Scalars['date']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly discord_id?: InputMaybe<Scalars['String']['input']>;
  readonly discord_user?: InputMaybe<Scalars['jsonb']['input']>;
  readonly division?: InputMaybe<User_Division>;
  readonly dream_job?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly facebook_id?: InputMaybe<Scalars['String']['input']>;
  readonly first_name?: InputMaybe<Scalars['String']['input']>;
  readonly follower_count?: InputMaybe<Scalars['Int']['input']>;
  readonly following_count?: InputMaybe<Scalars['Int']['input']>;
  readonly gender?: InputMaybe<Scalars['String']['input']>;
  readonly google_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly intercom_id?: InputMaybe<Scalars['String']['input']>;
  readonly interested_reason?: InputMaybe<Scalars['String']['input']>;
  readonly interests?: InputMaybe<Scalars['String']['input']>;
  readonly invite_code?: InputMaybe<Scalars['String']['input']>;
  readonly last_name?: InputMaybe<Scalars['String']['input']>;
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['users_meta']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  readonly parent_email?: InputMaybe<Scalars['String']['input']>;
  readonly parent_id?: InputMaybe<Scalars['String']['input']>;
  readonly password_hash?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
  readonly role?: InputMaybe<User_Role>;
  readonly season_xp?: InputMaybe<Scalars['Int']['input']>;
  readonly stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
  readonly verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "users_stats" */
export type Users_Stats = {
  readonly __typename?: 'users_stats';
  readonly amount: Scalars['Int']['output'];
  readonly associated_object: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly last_updated: Scalars['timestamptz']['output'];
  readonly stat: Scalars['String']['output'];
  /** An object relationship */
  readonly user?: Maybe<Users>;
  readonly user_id: Scalars['String']['output'];
};

/** order by aggregate values of table "users_stats" */
export type Users_Stats_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Users_Stats_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Stats_Max_Order_By>;
  readonly min?: InputMaybe<Users_Stats_Min_Order_By>;
  readonly stddev?: InputMaybe<Users_Stats_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Users_Stats_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Users_Stats_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Users_Stats_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Users_Stats_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Users_Stats_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Users_Stats_Variance_Order_By>;
};

/** order by avg() on columns of table "users_stats" */
export type Users_Stats_Avg_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_stats". All fields are combined with a logical 'AND'. */
export type Users_Stats_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Stats_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Stats_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Stats_Bool_Exp>>;
  readonly amount?: InputMaybe<Int_Comparison_Exp>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly last_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly stat?: InputMaybe<String_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "users_stats" */
export type Users_Stats_Max_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
  readonly associated_object?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly last_updated?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "users_stats" */
export type Users_Stats_Min_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
  readonly associated_object?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly last_updated?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "users_stats". */
export type Users_Stats_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
  readonly associated_object?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly last_updated?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** select columns of table "users_stats" */
export enum Users_Stats_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdated = 'last_updated',
  /** column name */
  Stat = 'stat',
  /** column name */
  UserId = 'user_id'
}

/** order by stddev() on columns of table "users_stats" */
export type Users_Stats_Stddev_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "users_stats" */
export type Users_Stats_Stddev_Pop_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "users_stats" */
export type Users_Stats_Stddev_Samp_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_stats" */
export type Users_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stats_Stream_Cursor_Value_Input = {
  readonly amount?: InputMaybe<Scalars['Int']['input']>;
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly stat?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "users_stats" */
export type Users_Stats_Sum_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "users_stats" */
export type Users_Stats_Var_Pop_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "users_stats" */
export type Users_Stats_Var_Samp_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "users_stats" */
export type Users_Stats_Variance_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  readonly address?: InputMaybe<Scalars['String']['input']>;
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly bio?: InputMaybe<Scalars['String']['input']>;
  readonly birthday?: InputMaybe<Scalars['date']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly discord_id?: InputMaybe<Scalars['String']['input']>;
  readonly discord_user?: InputMaybe<Scalars['jsonb']['input']>;
  readonly division?: InputMaybe<Scalars['String']['input']>;
  readonly dream_job?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly facebook_id?: InputMaybe<Scalars['String']['input']>;
  readonly first_name?: InputMaybe<Scalars['String']['input']>;
  readonly follower_count?: InputMaybe<Scalars['Int']['input']>;
  readonly following_count?: InputMaybe<Scalars['Int']['input']>;
  readonly gender?: InputMaybe<Scalars['String']['input']>;
  readonly google_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly intercom_id?: InputMaybe<Scalars['String']['input']>;
  readonly interested_reason?: InputMaybe<Scalars['String']['input']>;
  readonly interests?: InputMaybe<Scalars['String']['input']>;
  readonly invite_code?: InputMaybe<Scalars['String']['input']>;
  readonly last_name?: InputMaybe<Scalars['String']['input']>;
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  readonly parent_email?: InputMaybe<Scalars['String']['input']>;
  readonly parent_id?: InputMaybe<Scalars['String']['input']>;
  readonly password_hash?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
  readonly role?: InputMaybe<Scalars['String']['input']>;
  readonly season_xp?: InputMaybe<Scalars['Int']['input']>;
  readonly stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
  readonly verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Bool_Exp;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['uuid']['input']>;
  readonly _gt?: InputMaybe<Scalars['uuid']['input']>;
  readonly _gte?: InputMaybe<Scalars['uuid']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['uuid']['input']>;
  readonly _lte?: InputMaybe<Scalars['uuid']['input']>;
  readonly _neq?: InputMaybe<Scalars['uuid']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
};

export enum Vote_Object_Type {
  Competitions = 'competitions',
  Orders = 'orders',
  Posts = 'posts',
  Projects = 'projects',
  Themes = 'themes'
}

export type Vote_Object_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Vote_Object_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Vote_Object_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Vote_Object_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Vote_Object_Type>>;
};

/** columns and relationships of "votes" */
export type Votes = {
  readonly __typename?: 'votes';
  readonly association_id: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly emote_hex: Scalars['String']['output'];
  readonly emote_name: Scalars['String']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly object_id: Scalars['String']['output'];
  readonly object_type: Vote_Object_Type;
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "votes" */
export type Votes_Aggregate = {
  readonly __typename?: 'votes_aggregate';
  readonly aggregate?: Maybe<Votes_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Votes>;
};

export type Votes_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Votes_Aggregate_Bool_Exp_Count>;
};

export type Votes_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Votes_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "votes" */
export type Votes_Aggregate_Fields = {
  readonly __typename?: 'votes_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Votes_Max_Fields>;
  readonly min?: Maybe<Votes_Min_Fields>;
};


/** aggregate fields of "votes" */
export type Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "votes" */
export type Votes_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Votes_Max_Order_By>;
  readonly min?: InputMaybe<Votes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "votes" */
export type Votes_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Votes_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Votes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "votes". All fields are combined with a logical 'AND'. */
export type Votes_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Votes_Bool_Exp>>;
  readonly _not?: InputMaybe<Votes_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Votes_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly emote_hex?: InputMaybe<String_Comparison_Exp>;
  readonly emote_name?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly object_id?: InputMaybe<String_Comparison_Exp>;
  readonly object_type?: InputMaybe<Vote_Object_Type_Bool_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "votes" */
export enum Votes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsVotesPkey = 'projects_votes_pkey',
  /** unique or primary key constraint on columns "object_id", "user_id", "emote_name", "object_type" */
  VotesObjectIdEmoteNameUserIdObjectTypeKey = 'votes_object_id_emote_name_user_id_object_type_key'
}

/** input type for inserting data into table "votes" */
export type Votes_Insert_Input = {
  readonly emote_hex?: InputMaybe<Scalars['String']['input']>;
  readonly emote_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly object_id?: InputMaybe<Scalars['String']['input']>;
  readonly object_type?: InputMaybe<Vote_Object_Type>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Votes_Max_Fields = {
  readonly __typename?: 'votes_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly emote_hex?: Maybe<Scalars['String']['output']>;
  readonly emote_name?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly object_id?: Maybe<Scalars['String']['output']>;
  readonly object_type?: Maybe<Scalars['String']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "votes" */
export type Votes_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly emote_hex?: InputMaybe<Order_By>;
  readonly emote_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Votes_Min_Fields = {
  readonly __typename?: 'votes_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly emote_hex?: Maybe<Scalars['String']['output']>;
  readonly emote_name?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly object_id?: Maybe<Scalars['String']['output']>;
  readonly object_type?: Maybe<Scalars['String']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "votes" */
export type Votes_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly emote_hex?: InputMaybe<Order_By>;
  readonly emote_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "votes" */
export type Votes_Mutation_Response = {
  readonly __typename?: 'votes_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Votes>;
};

/** on_conflict condition type for table "votes" */
export type Votes_On_Conflict = {
  readonly constraint: Votes_Constraint;
  readonly update_columns?: ReadonlyArray<Votes_Update_Column>;
  readonly where?: InputMaybe<Votes_Bool_Exp>;
};

/** Ordering options when selecting data from "votes". */
export type Votes_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly emote_hex?: InputMaybe<Order_By>;
  readonly emote_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** select columns of table "votes" */
export enum Votes_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmoteHex = 'emote_hex',
  /** column name */
  EmoteName = 'emote_name',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  ObjectType = 'object_type',
  /** column name */
  UserId = 'user_id'
}

export type Votes_Set_Input = {
  readonly object_type?: InputMaybe<Vote_Object_Type>;
};

/** Streaming cursor of the table "votes" */
export type Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Votes_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly emote_hex?: InputMaybe<Scalars['String']['input']>;
  readonly emote_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly object_id?: InputMaybe<Scalars['String']['input']>;
  readonly object_type?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "votes" (current role has no relevant permissions) */
export enum Votes_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}
